import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useCreateSupplier from "../../../hooks/app/suppliers/useCreateSupplier";
import Loader from "../../../components/common/general/Loader";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import TextareaComponent from "../../../components/FormUpdate/TextareaComponent";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import ImageCard from "../../../components/common/general/ImageCard";
import Toast from "../../../helpers/toast.js";
import { errorMessage } from "../../../helpers/errorMessage";
import { ChangePasswordClientSchema } from "../../../validation/app/users/ChangePasswordClientSchema.js";
import { yupResolver } from "@hookform/resolvers/yup";

const UpdateSupplier = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);
  const [data, setData] = useState({});
  const { UpdateSupplier, getSupplier, UpdatePassword } = useCreateSupplier();
  useEffect(() => {
    getSupplier(id, setLoadingData, setData);
  }, [id]);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    register,
  } = useForm({
    defaultValues: data,
  });
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setError: setErrorPassword,
  } = useForm({
    resolver: yupResolver(ChangePasswordClientSchema),
    mode: "onChange",
  });

  const photo = watch("image");
  const onSubmit = (data) => {
    if (
      data.first_name === undefined &&
      data.last_name === undefined &&
      data.email === undefined &&
      data.phone === undefined
    ) {
      if (data.hasOwnProperty("image")) {
        if (data.image.length != 0) {
          UpdateSupplier(id, setLoading, {
            ...data,
          },setError);
        } else {
          Toast("error", "Nothing to Update");
        }
      } else {
        Toast("error", "Nothing to Update");
      }
    } else {
      UpdateSupplier(id, setLoading, {
        ...data,
      },setError);
    }
  };
  const updatePassword = (data) => {
    UpdatePassword(id, setLoadingPass, data, setErrorPassword);
  };
  return (
    <>
      <Breadcrumb
        title="Supplier"
        primaryLink="/"
        primaryTitle="Dashboard"
        secondaryLink={"/suppliers"}
        secondarytitle="Suppliers"
        thirdTitle={
          loadingData
            ? "Update Supplier"
            : data?.first_name + " " + data?.last_name
        }
      />
      {loadingData ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <form
                    className="forms-sample"
                    onSubmit={handleSubmit(onSubmit)}
                    key={1}
                  >
                    <div className="row">
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="First Name"
                          name="first_name"
                          placeholder="First name"
                          control={control}
                          defaultValue={data?.first_name}
                          error="first_name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Last Name"
                          name="last_name"
                          placeholder="Last name"
                          control={control}
                          defaultValue={data?.last_name}
                          error="last_name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Username"
                          name="username"
                          placeholder="Username"
                          control={control}
                          defaultValue={data?.username}
                          error="username"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="email"
                          label="Email"
                          name="email"
                          placeholder="Email"
                          control={control}
                          defaultValue={data?.email}
                          error="email"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Phone"
                          name="phone"
                          placeholder="Phone"
                          control={control}
                          defaultValue={data?.phone}
                          error="phone"
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <ImageDropzone
                            placeholder="Select image"
                            photos={photo}
                            name="image"
                            setValue={setValue}
                            multiple={false}
                            previewCol={5}
                            errors={errors}
                            label="Select New Image"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-label">Supplier Image</label>
                          <ImageCard file={data?.image} />
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-primary mt-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success mt-2">
                        Update Suppliers
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Update Password</div>
                  <form
                    className="forms-sample"
                    onSubmit={handleSubmit2(updatePassword)}
                    key={2}
                  >
                    <div className="mb-3">
                      <label for="first_name" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        {...register2("password")}
                      />
                      {errorMessage(errors2, "password")}
                    </div>
                    <div className="mb-3">
                      <label for="first_name" className="form-label">
                        Password Confirmation
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password_confirmation"
                        placeholder="Password Confirmation"
                        {...register2("password_confirmation")}
                      />
                      {errorMessage(errors2, "password_confirmation")}
                    </div>
                    {loadingPass ? (
                      <button type="submit" className="btn btn-primary mt-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success mt-2">
                        Update Password
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateSupplier;
