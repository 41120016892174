import { useState, useEffect } from "react";
import Loader from "../../../components/common/general/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/errorMessage";
import * as yup from "yup";

const LeadStatus = ({ id, getUpdateStatus, getLeadStatus }) => {
  const [remarque, setRemarque] = useState("");
  const [loadineOne, setLaodingOne] = useState(false);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    if (id != undefined) {
      getLeadStatus(id, setStatus, setLoading);
    }
  }, []);
  const HandleUpdate = (e) => {
    let data = { status_id: e, remarque: remarque };
    getUpdateStatus(id, data, setLoading);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        remarque: yup.string().typeError("Error Type"),
        date: yup
          .date()
          .typeError("Date is required")
          .required("Date is required"),
      })
    ),
  });
  const HandleUpdateReporter = (data) => {
    getUpdateStatus(id, data, setLaodingOne);
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={HandleUpdate}>
                {loading == true ? (
                  <Loader />
                ) : (
                  <div className="g-3 row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        id="remarque"
                        autocomplete="off"
                        placeholder="Remarque"
                        value={remarque}
                        onChange={(e) => setRemarque(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-auto">
                      <div className="example">
                        {status.map((item, index) => {
                          if (item.name === "Reporté") {
                            return (
                              <button
                                key={index}
                                type="button"
                                className={
                                  "btn btn-outline-" + item.color + " me-1"
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#ReporterDialog"
                              >
                                {item.name}
                              </button>
                            );
                          } else {
                            return (
                              <button
                                key={index}
                                type="button"
                                className={
                                  "btn btn-outline-" + item.color + " me-1"
                                }
                                onClick={(e) => HandleUpdate(item.id)}
                              >
                                {item.name}
                              </button>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ReporterDialog"
        tabindex="-1"
        aria-labelledby="ReporterDialogLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ReporterDialogLabel">
                Update Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <form
              className="forms-sample"
              onSubmit={handleSubmit(HandleUpdateReporter)}
            >
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Date schedule</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    autocomplete="off"
                    placeholder="Date"
                    {...register("date")}
                  />
                  {errorMessage(errors, "date")}
                </div>
                <div className="mb-3">
                  <label className="form-label">Remarque</label>
                  <input
                    type="text"
                    className="form-control"
                    id="remarque"
                    autocomplete="off"
                    placeholder="Remarque"
                    {...register("remarque")}
                  />
                  {errorMessage(errors, "remarque")}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default LeadStatus;
