import React from "react";
import { useFormContext } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import { errorMessage } from "../../../helpers/errorMessage";

const NewPassword = ({ onSubmit, dataUser, loading }) => {
  const methods = useFormContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-1">
        <label htmlFor="token" className="form-label">
          Token send to email : {dataUser?.email}
        </label>
      </div>
      <div className="mb-1">
        <label htmlFor="token" className="form-label">
          Token
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="Token"
          autoComplete="off"
          {...register("token", { required: true })}
        />
        {errorMessage(errors, "token")}
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-1">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              autoComplete="off"
              {...register("password", { required: true })}
            />
            {errorMessage(errors, "password")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-1">
            <label htmlFor="password_confirmation" className="form-label">
              Password Confirmation
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="Password Confirmation"
              autoComplete="off"
              {...register("password_confirmation", { required: true })}
            />
            {errorMessage(errors, "password_confirmation")}
          </div>
        </div>
      </div>
      {loading ? (
        <button
          type="button"
          disabled
          className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
        >
          <Loader />
        </button>
      ) : (
        <button
          onClick={handleSubmit(onSubmit)}
          className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
        >
          Change Password
        </button>
      )}
    </form>
  );
};
export default NewPassword;
