import * as yup from "yup";
import { parse } from "date-fns";

export const AddFactureProductSchema = yup.object().shape({
  product_id: yup
    .string()
    .typeError("Please choose Product")
    .required("Product is required"),
  qty: yup
    .number()
    .nullable()
    .typeError("Purchase priceQuantity must be a number")
    .min(1, "Too little"),
  // date_expiration: yup
  //   .date()
  //   .nullable()
  //   .typeError("Expired date is required")
  //   .transform((value, originalValue, context) => {
  //     if (context.isType(value)) return value;
  //     return parse(originalValue, "dd/MM/yyyy", new Date());
  //   }),
});
