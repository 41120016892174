import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useGoogleSheetsFunctions from "../../../hooks/app/google_sheets/useGoogleSheetsFunctions";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Loader from "../../../components/common/general/Loader";

const ViewGoogleAccountSheets = () => {
  const { getSheetFiles } = useGoogleSheetsFunctions();

  const [Data, setData] = useState([]);
  const [LoadingData, setLoadingData] = useState(false);

  const { id, sheet_id } = useParams();

  useEffect(() => {
    getSheetFiles(id, sheet_id, setData, setLoadingData);
  }, [id, sheet_id]);

  return (
    <>
      <Breadcrumb
        title="Google Sheets"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Google Account"
        secondaryLink="/google_sheets"
        secondarytitle="Google Account"
      />
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Sheet ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {LoadingData ?  (<tr key={1}>
              <th><Loader/></th>
              <th><Loader/></th>
              <th><Loader/></th>
            </tr>):null}
            {Data?.map((item, index) => {
              return (
                <tr key={index}>
                  <th>{item.sheet_id}</th>
                  <th>{item.title}</th>
                  <th>
                    <div className="d-flex flex-row justify-between">
                      <Link
                        className="me-3"
                        to={`/google_sheets/view/${id}/sheet/${sheet_id}/sync/${item.title}`}
                      >
                        <FeatherIcon icon="eye" color="green" size={20} />
                      </Link>
                    </div>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ViewGoogleAccountSheets;
