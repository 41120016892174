import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const DeliveryTable = ({ data, name,company }) => {
  return (
    <tr>
      <th>{data.information?.name}</th>
      <th>{data.information?.phone}</th>
      <th>{data.information?.email}</th>
      <th>
        <span
          className={
            data.status === true ? "badge bg-success" : "badge bg-danger"
          }
        >
          {data.status === true ? "Active" : "Inactive"}
        </span>
      </th>
      <th>
        <span
          className={
            data.default === true ? "badge bg-success" : "badge bg-danger"
          }
        >
          {data.default === true ? "True" : "False"}
        </span>
      </th>
      <th>
        <div className="d-flex flex-row justify-between">
          <Link
            to={`/delivery/company/view/${company}/${data.id}`}
            title="View Company Details"
            className="me-3"
          >
            <FeatherIcon icon="eye" size={20} color="green" />
          </Link>
          <Link data-bs-toggle="modal" data-bs-target={name} className="me-3">
            <FeatherIcon icon="edit" size={20} />
          </Link>

          <Link>
            <FeatherIcon icon="trash" color="red" size={20} />
          </Link>
        </div>
      </th>
    </tr>
  );
};
export default DeliveryTable;
