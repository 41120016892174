import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../hooks/useSearch";
import { useFactures } from "../../../hooks/app/Factures/useFactures";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../components/table/Table";

const Factures = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useFactures(page);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.supplier?.first_name+' '+row.supplier?.last_name,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total+' Da',
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/factures/update/${row.id}`}
              title="update facture"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete facture">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Factures"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Factures"
      />
       <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        titleLabel="Facture"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default Factures;
