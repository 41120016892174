import { useState } from "react";
import DeliveryCompany from "../../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateMaystroSchema } from "../../../../../validation/app/deliveryCompany/CreateMaystroSchema";
import Loader from "../../../../../components/common/general/Loader";
import { errorMessage } from "../../../../../helpers/errorMessage";

const MaystroCompany = () => {
  const [loading, setLoading] = useState(false);
  const { CreateMaystroCompany } = DeliveryCompany();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateMaystroSchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateMaystroCompany(data, setLoading);
  };
  return (
    <>
    <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="id_store" className="form-label">
              ID Store
            </label>
            <input
              type="text"
              className="form-control"
              id="id_store"
              placeholder="ID Store"
              {...register("id_store")}
            />
            {errorMessage(errors, "id_store")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="token" className="form-label">
              Token
            </label>
            <input
              type="text"
              className="form-control"
              id="token"
              placeholder="Token"
              {...register("token")}
            />
            {errorMessage(errors, "token")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="status" className="form-label">
              Status
            </label>
            <select
              name="status"
              id="status"
              className="form-control"
              {...register("status")}
            >
              <option value="">Selectionner</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            {errorMessage(errors, "status")}
          </div>
        </div>
      </div>
      {loading ? (
        <button type="submit" className="btn btn-primary me-2">
          <Loader />
        </button>
      ) : (
        <button type="submit" className="btn btn-success me-2">
          Create
        </button>
      )}
    </form>
  </>
  );
};
export default MaystroCompany;
