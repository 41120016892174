import { useQuery } from "@tanstack/react-query";
import { DeliveryCompanyApi } from "../../../apis/app/delivery/company/DeliveryCompanyApi";

export const useGetDeliveryCompany = (page) => {
  const { data: datas, isLoading: loading } = useQuery({
    queryKey: ["delivery_companies",page],
    queryFn: () => DeliveryCompanyApi(page),
  });
  return { datas, loading };
};
