import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import { useState } from "react";
import { UpdateFactureAddPaiementSchema } from "../../../../validation/app/facture/UpdateFactureAddPaiementSchema";
import { useParams } from "react-router-dom";

const UpdateFactureAddPaiementDialog = ({
  getUpdateFactureAddPaiement,
  setData,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(UpdateFactureAddPaiementSchema),
  });
  const HandleAdd = (data) => {
    getUpdateFactureAddPaiement(id,data,setLoading,setData);
    setValue("amount",0);
    setValue("note",null);
  };
  return (
    <>
      <div
        className="modal fade"
        id="UpdataFactureAddPaiement"
        tabIndex="-1"
        aria-labelledby="UpdataFactureAddPaiementLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="UpdataFactureAddPaiementLabel">
                Add Paeiment
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-2">
                  <label className="form-label">Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    defaultValue={0}
                    {...register("amount")}
                  />
                  {errorMessage(errors, "amount")}
                </div>
                <div className="mb-2">
                  <label className="form-label">Note</label>
                  <input
                    type="text"
                    className="form-control"
                    id="note"
                    {...register("note")}
                  />
                  {errorMessage(errors, "note")}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {loading == true ? (
                    <button type="submit" className="btn btn-primary">
                      Loading
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success">
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateFactureAddPaiementDialog;
