import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/FormUpdate/InputComponent";
import { useState } from "react";
import Loader from "../../../../components/common/general/Loader";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";
import { Controller } from "react-hook-form";
import { errorMessage } from "../../../../helpers/errorMessage";
import { useParams } from "react-router-dom";

const UpdateDeliveryAddressDialog = ({ datas, wilayas }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  const { getWilayaDairas, UpdateDeliveryMan } = useDeliveryManFunctions();
  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: datas,
  });
  const wilaya_id = register("wilaya_id");
  const daira_id = register("daira_id");
  const getDairas = (e) => {
    getWilayaDairas(e.target.value, setDairas, setLoadingDairas);
  };
  const Update = (temp_data) => {
    if (temp_data.wilaya_id !== "") {
      if (temp_data.daira_id === "") {
        setError("daira_id", {
          type: "submit",
          message: "Daira is required",
        });
      } else {
        UpdateDeliveryMan(temp_data, setLoading, datas.id,id);
      }
    } else {
      UpdateDeliveryMan(temp_data, setLoading, datas.id,id);
    }
  };
  return (
    <div
      className="modal fade"
      id="UpdateDeliveryAddressModal"
      tabIndex="-1"
      aria-labelledby="UpdateDeliveryAddressLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="UpdateDeliveryAddressLabel">
              Update Deliver Man Address : {datas?.id}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(Update)}>
              <div className="form-group mt-1">
                <label className="form-label">Wilayas</label>
                <Controller
                  control={control}
                  name="wilaya_id"
                  render={({ field: { name } }) => (
                    <select
                      id={name}
                      name={name}
                      className="form-control"
                      {...wilaya_id}
                      onChange={(e) => {
                        wilaya_id.onChange(e);
                        getDairas(e);
                      }}
                      defaultValue={datas?.wilaya?.id}
                    >
                      <option value="">Selectionner</option>
                      {wilayas?.map((wilaya, index) => {
                        return (
                          <option key={index} value={wilaya.id}>
                            {wilaya.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
              </div>
              <div className="form-group mt-1">
                <label className="form-label">Dairas</label>
                <Controller
                  control={control}
                  name="daira_id"
                  render={({ field: { name, onChange } }) => (
                    <select
                      id={name}
                      name={name}
                      className="form-control"
                      {...daira_id}
                      onChange={onChange}
                      defaultValue={datas?.daira?.id}
                    >
                      <option value="">Selectionner</option>
                      {Dairas?.map((daira, index) => {
                        return (
                          <option key={index} value={daira.id}>
                            {daira.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
                {errorMessage(errors, "daira_id")}
              </div>
              <InputComponent
                errors={errors}
                inputType="text"
                label="Address"
                name="address"
                placeholder="Address"
                control={control}
                defaultValue={datas?.address}
                error="address"
              />
              <InputComponent
                errors={errors}
                inputType="text"
                label="Commune"
                name="commune"
                placeholder="Commune"
                control={control}
                defaultValue={datas?.commune}
                error="commune"
              />

              {loading ? (
                <button type="submit" className="btn btn-primary mt-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success mt-2">
                  Update
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateDeliveryAddressDialog;
