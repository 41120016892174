const OrderTypeCustom = ({ register }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="name"
          {...register("name")}
        />
        {/* {errorMessage(errors, "name")} */}
      </div>
      <div className="mb-3">
        <label for="theme_id" className="form-label">
          Status
        </label>
        <select
          name="status"
          id="status"
          className="form-control"
          {...register("status")}
        >
          <option value="">Selectionner</option>
          <option value={1}>Active</option>
          <option value={0}>Inactive</option>
        </select>
      </div>
      <div className="mb-3">
        <label for="is_lead" className="form-label">
          Type
        </label>
        <select
          name="is_lead"
          id="is_lead"
          className="form-control"
          {...register("is_lead")}
        >
          <option value={1} selected>Leads</option>
          <option value={0}>Orders</option>
        </select>
      </div>
    </>
  );
};
export default OrderTypeCustom;
