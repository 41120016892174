import axiosInstance from "../../Axios/axios";
import axiosInstancePart from "../../Axios/axios_part";

const getProductsApi = async (page) => {
    return await axiosInstance.get(`store/products?page=${page}`);
};

const getProductCategoriesApi = async () => {
    return await axiosInstance.get(`store/products/categories`);
}
const createProductApi= async (data) => {
    return await axiosInstancePart.post(`store/products`,data);
}
const getProductDetailsApi= async (id) => {
    return await axiosInstance.post(`store/products/${id}`);
}
const deletePictureProductApi = async (id,product_id) => {
    return await axiosInstance.delete(`store/products/${product_id}/picture/${id}`);
};
const updateProductApi = async (id,data) => {
    return await axiosInstance.post(`store/products/${id}`,data);
};
const getUpdateProductItemApi = async (id,item_id,data) => {
    return await axiosInstance.post(`store/products/${id}/item/${item_id}/update`,data);
};
const getCreateProductItemApi = async (id,data) => {
    return await axiosInstance.post(`store/products/${id}/item`,data);
};
const getProductPictureCreateApi = async (id,data) => {
    return await axiosInstancePart.post(`store/products/${id}/picture`,data);
};
const getUpdateProductDiscountApi = async (id,data) => {
    return await axiosInstance.post(`store/products/${id}/discount`,data);
};
export {
    getProductsApi,
    getProductCategoriesApi,
    createProductApi,
    getProductDetailsApi,
    deletePictureProductApi,
    updateProductApi,
    getUpdateProductItemApi,
    getCreateProductItemApi,
    getProductPictureCreateApi,
    getUpdateProductDiscountApi
}
