import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/auth/useAuth";
import CAN from "../../../../features/can";

const ProfileDropdown = ({ profileImage, name, email }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="profileDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          className="wd-30 ht-30 rounded-circle"
          src={profileImage || "https://via.placeholder.com/30x30"}
          alt="profile"
        />
      </a>
      <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
        <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
          <div className="mb-3">
            <img
              className="wd-80 ht-80 rounded-circle"
              src={profileImage || "https://via.placeholder.com/80x80"}
              alt="profile picture"
            />
          </div>
          <div className="text-center">
            <p className="tx-16 fw-bolder">{name}</p>
            <p className="tx-12 text-muted">{email}</p>
          </div>
        </div>
        <ul className="list-unstyled p-1">
          <li
            className="dropdown-item py-2"
            onClick={() => navigate("/profile")}
            key={0}
          >
            <Link to="/profile" className="text-body ms-0">
              <FeatherIcon
                icon="edit"
                className="me-2 icon-md"
                data-feather="edit"
              ></FeatherIcon>
              <span>My Profile</span>
            </Link>
          </li>
          {CAN("view", "subscriptions") && (
            <li
              className="dropdown-item py-2"
              onClick={() => navigate("/subscriptions")}
              key={1}
            >
              <Link to="/subscriptions" className="text-body ms-0">
                <FeatherIcon
                  icon="credit-card"
                  className="me-2 icon-md"
                  data-feather="credit-card"
                ></FeatherIcon>
                <span>Subscriptions</span>
              </Link>
            </li>
          )}
          {CAN("view", "settings") && (
            <li
              className="dropdown-item py-2"
              onClick={() => navigate("/my_store")}
              key={2}
            >
              <Link to="/my_store" className="text-body ms-0">
                <FeatherIcon
                  icon="settings"
                  className="me-2 icon-md"
                  data-feather="settings"
                ></FeatherIcon>
                <span>My Store</span>
              </Link>
            </li>
          )}
          <li className="dropdown-item py-2" onClick={(e) => logout(e)} key={3}>
            <Link to="" onClick={(e) => logout(e)} className="text-body ms-0">
              <FeatherIcon
                icon="log-out"
                className="me-2 icon-md"
                data-feather="log-out"
              ></FeatherIcon>
              <span>Log Out</span>
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default ProfileDropdown;
