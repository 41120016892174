const StockProductItems = ({ items,setProductItems}) => {
  const handleChange = (e, index) => {
    items[index].qty_fournis = e.target.value;
    setProductItems(items);
  };
  return (
    <>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4">
            <label htmlFor="name" className="form-label">
              Products
            </label>
          </div>
          <div className="col-lg-4">
            <label htmlFor="qty" className="form-label">
              Quantity Disponible
            </label>
          </div>
          <div className="col-lg-4">
            <label htmlFor="qty" className="form-label">
              Quantity for delivery man
            </label>
          </div>
        </div>
        {items.map((item, index) => {
          return (
            <div className="row" key={index}>
              <input
                type="number"
                className="form-control"
                value={item.id}
                hidden
              />
              <div className="col-lg-4 mb-1">
                <input
                  type="text"
                  className="form-control"
                  value={item.name}
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Quantity Disponible"
                  defaultValue={item.qty}
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Quantity"
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default StockProductItems;
