import axiosInstance from "../../Axios/axios";
import axiosInstancePart from "../../Axios/axios_part";

const getWilayasApi = async () => {
  return await axiosInstance.get(`store/general/get_wilayas`);
};

const getWilayaDairasApi = async (id) => {
  return await axiosInstance.get(`store/general/get_wilayas/${id}`);
};

const getCreateDeliveryManApi = async (data) => {
  return await axiosInstancePart.post(`store/delivery_mans`, data);
};

const getDeliveryManApi = async (id) => {
  return await axiosInstance.get(`store/delivery_mans/${id}`);
};
const getUpdateDeliveryMan = async (data, id) => {
  return await axiosInstancePart.post(`store/delivery_mans/update/${id}`, data);
};

const getAddDeliveryAddressApi = async (id, data) => {
  return await axiosInstance.post(
    `store/delivery_mans/${id}/address/create`,
    data
  );
};

const getDeliveryManCreateTarifApi = async (id, data) => {
  return await axiosInstance.post(
    `store/delivery_mans/${id}/tarifs/create`,
    data
  );
};

const getDeliveryManUpdateTarifApi = async (id, tarif_id, data) => {
  return await axiosInstance.post(
    `store/delivery_mans/${id}/tarifs/update/${tarif_id}`,
    data
  );
};

const getDeliveryManStockApi = async (page, id) => {
  return await axiosInstance.get(
    `store/delivery_mans/${id}/stock?page=${page}`
  );
};

const getDeliveryManProductsStockApi = async (id) => {
  return await axiosInstance.get(
    `store/delivery_mans/${id}/get_products_stock`
  );
};
const getDeliveryManAddProductsStockApi = async (id, data) => {
  return await axiosInstance.post(
    `store/delivery_mans/${id}/add_product_stock`,
    data
  );
};

const getDeliveryManStockViewApi = async (id, stock_id) => {
  return await axiosInstance.get(
    `store/delivery_mans/${id}/stock/${stock_id}`,
  );
};

const getDairasApi = async (man_id,id) => {
  return await axiosInstance.get(
    `store/delivery_mans/${man_id}/wilaya/${id}/get_wilaya_commnues`,
  );
};
export {
  getWilayasApi,
  getWilayaDairasApi,
  getCreateDeliveryManApi,
  getDeliveryManApi,
  getUpdateDeliveryMan,
  getAddDeliveryAddressApi,
  getDeliveryManCreateTarifApi,
  getDeliveryManUpdateTarifApi,
  getDeliveryManStockApi,
  getDeliveryManProductsStockApi,
  getDeliveryManAddProductsStockApi,
  getDeliveryManStockViewApi,
  getDairasApi
};
