import { useState, useEffect } from "react";
import Sidebar from "./SideBar/SideBar";
import Navbar from "./Navbar/NavBar";
import Footer from "./Footer/Footer";
import useAuth from "../../hooks/auth/useAuth";
import { Link } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom";
import CAN from "../../features/can";

const MainLayout = ({ Children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [previousWidth, setPreviousWidth] = useState(window.innerWidth);
  const { checkSubscription } = useAuth();
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const isMobileScreen = screenWidth < 768;
      setIsMobile(isMobileScreen);

      if (isMobileScreen !== isMobile) {
        setIsOpen(false);
      }

      setPreviousWidth(screenWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isOpen && previousWidth > 991) {
      document.body.classList.add("sidebar-folded");
    } else {
      document.body.classList.remove("sidebar-folded");
    }
  }, [isOpen, previousWidth]);
  const [data, setData] = useState({
    status: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    checkSubscription(setData);
  }, [navigate]);
  return (
    <div className="main-wrapper">
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} width={previousWidth} />
      <div className="page-wrapper">
        <Navbar isOpen={isOpen} toggle={toggle} />
        <div className="page-content">
          {CAN("view", "notificationsmain") && data.status === true ? (
            <div className="row">
              <div className="alert alert-danger" role="alert">
                {data.message} {"  "}
                {data.lien !== null ? (
                  <Link to={data.lien} className="alert-link">
                    Click here
                  </Link>
                ) : null}
              </div>
            </div>
          ) : null}
          {Children}
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default MainLayout;
