import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import Loader from "../../../../components/common/general/Loader";
import { useEffect, useState } from "react";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";

const ViewDeliveryManStock = () => {
  const { id } = useParams();
  const { stock_id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [datas, setData] = useState();
  const { getDeliveryManStock } = useDeliveryManFunctions();
  useEffect(() => {
    getDeliveryManStock(id, stock_id, setLoadingData, setData);
  }, [id]);
  return (
    <>
      <Breadcrumb
        title="Delivery Man"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Delivery man Stock"
        secondaryLink={"/delivery/man/" + id + "/stock"}
        secondarytitle="Delivery Man"
      />
      {loadingData == true ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="product_name" className="form-label">
                Product
              </label>
              <input
                type="text"
                className="form-control"
                id="product_name"
                readOnly
                value={datas?.product?.name}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="product_price" className="form-label">
                Product Price
              </label>
              <input
                type="text"
                className="form-control"
                id="product_name"
                readOnly
                value={datas?.product?.price + " DA"}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-4">
              <label htmlFor="product_qty" className="form-label">
                Product Quantity
              </label>
              <input
                type="text"
                className="form-control"
                id="product_qty"
                readOnly
                value={datas?.all}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="product_livred" className="form-label">
                Product Livred
              </label>
              <input
                type="text"
                className="form-control"
                id="product_livred"
                readOnly
                value={datas?.livred}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="product_reste" className="form-label">
                Product Reste
              </label>
              <input
                type="text"
                className="form-control"
                id="product_reste"
                readOnly
                value={datas?.reste}
              />
            </div>
          </div>
          {datas?.items?.length != 0 ? (
            <div className="row mt-2">
              <div className="col-lg-3">
                <label className="form-label">Item</label>
              </div>
              <div className="col-lg-3">
                <label className="form-label">All Quantity</label>
              </div>
              <div className="col-lg-3">
                <label className="form-label">Quantity Livred</label>
              </div>
              <div className="col-lg-3">
                <label className="form-label">Quantity Reste</label>
              </div>
            </div>
          ) : null}
          {datas?.items?.length !== 0
            ? datas?.items.map((item, index) => {
                return (
                  <>
                    <div className="row mt-2" key={index}>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          id="product_qty"
                          readOnly
                          value={item?.item?.name}
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          id="product_qty"
                          readOnly
                          value={item?.all}
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          id="product_qty"
                          readOnly
                          value={item?.livred}
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          id="product_qty"
                          readOnly
                          value={item?.reste}
                        />
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </>
      )}
    </>
  );
};
export default ViewDeliveryManStock;