import { useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import useSearch from "../../../hooks/useSearch";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../components/table/Table";
import { useSuppliers } from "../../../hooks/app/suppliers/useSuppliers";

const ShowSuppliers = () => { 
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useSuppliers(page);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "image",
      selector: (row) => {
        return (
          <img className="wd-30 ht-30 rounded-circle" src={row?.image}></img>
        );
      },
      sortable: false,
    },
    {
      name: "First name",
      selector: (row) => row.first_name,
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Factures",
      selector: (row) =>row.factures,
      sortable: true,
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={
                row?.is_primary !== 1 && `/suppliers/update/${row.id}`
              }
              title="update supplier"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete supplier">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Suppliers"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Suppliers"
      />
      <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        titleLabel="Supplier"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default ShowSuppliers;
