import { errorMessage } from "../../../../helpers/errorMessage";

const InputQuantity = ({ register, errors }) => {
  return (
    <>
      <div className="mb-2">
        <label className="form-label">Purchase Price</label>
        <input
          type="number"
          className="form-control"
          id="purchase_price"
          placeholder="Purchase Price"
          {...register("purchase_price")}
        />
        {errorMessage(errors, "purchase_price")}
      </div>
      <div className="mb-2">
        <label className="form-label">Quantity</label>
        <input
          type="number"
          className="form-control"
          id="qty"
          placeholder="Quantity"
          defaultValue={1}
          {...register("qty")}
        />
        {errorMessage(errors, "qty")}
      </div>
    </>
  );
};
export default InputQuantity;
