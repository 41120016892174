import { errorMessage } from "../../../helpers/errorMessage";

const AddProductQty = ({ register, errors,qty_reste }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">Qty Reste</div>
        <div className="col-lg-6">Qty</div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control"
            defaultValue={qty_reste}
            placeholder="Qty Reste"
            readOnly
          />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control"
            defaultValue="1"
            id="qty"
            autocomplete="off"
            placeholder="Qty"
            {...register("qty")}
          />
          {errorMessage(errors, "qty")}
        </div>
      </div>
    </>
  );
};
export default AddProductQty;