import { Controller } from "react-hook-form";
import { errorMessage } from "../../helpers/errorMessage";

const TextareaComponent = ({
  defaultValue,
  name,
  control,
  placeholder,
  errors,
  error,
  disabled = false,
  readOnly = false,
  label,
  rows = null
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name } }) => (
        <>
          <label for={name} className="form-label">{label}</label>
          <textarea
            name={name}
            className="form-control"
            placeholder={placeholder}
            disabled={disabled}
            rows={rows === null ? 4 : rows}
            onChange={!readOnly && onChange}
            defaultValue={defaultValue}
          />
          {errorMessage(errors, error || name)}
        </>
      )}
    />
  );
};
export default TextareaComponent;
