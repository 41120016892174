import { useForm } from "react-hook-form";
import Toast from "../../../../../helpers/toast.js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InputComponent from "../../../../../components/FormUpdate/InputComponent.jsx";
import ImageDropzone from "../../../../../components/common/general/ImageDropzone.jsx";
import Loader from "../../../../../components/common/general/Loader.jsx";

const DeliveryManMainInformations = ({ UpdateDeliveryMan,dataMan }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: dataMan,
  });
  const photo = watch("image");
  const onSubmit = (data) => {
    if (
      data.first_name == undefined &&
      data.last_name == undefined &&
      data.email == undefined &&
      data.phone == undefined &&
      data.username == undefined
    ) {
      if (data.hasOwnProperty("image")) {
        if (data.image.length === 0) {
          Toast("error", "Nothing to Update");
        } else {
          UpdateDeliveryMan(data, setLoading, id, setError);
        }
      } else {
        Toast("error", "Nothing to Update");
      }
    } else {
      UpdateDeliveryMan(data, setLoading, id, setError);
    }
  };
  return (
    <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-4">
          <InputComponent
            errors={errors}
            inputType="text"
            label="First name"
            name="first_name"
            placeholder="First name"
            control={control}
            defaultValue={dataMan?.first_name}
            error="first_name"
          />
        </div>
        <div className="col-lg-4">
          <InputComponent
            errors={errors}
            inputType="text"
            label="Last name"
            name="last_name"
            placeholder="Last name"
            control={control}
            defaultValue={dataMan?.last_name}
            error="last_name"
          />
        </div>
        <div className="col-lg-4">
          <InputComponent
            errors={errors}
            inputType="text"
            label="Username"
            name="username"
            placeholder="Username"
            control={control}
            defaultValue={dataMan?.username}
            error="username"
          />
        </div>
        <div className="col-lg-6">
          <InputComponent
            errors={errors}
            inputType="text"
            label="Email"
            name="email"
            placeholder="Email"
            control={control}
            defaultValue={dataMan?.email}
            error="email"
          />
        </div>
        <div className="col-lg-6">
          <InputComponent
            errors={errors}
            inputType="text"
            label="Phone"
            name="phone"
            placeholder="Phone"
            control={control}
            defaultValue={dataMan?.phone}
            error="phone"
          />
        </div>
        <div className="col-lg-12">
          <div className="form-group mt-1">
            <ImageDropzone
              placeholder="Drag and drop or click to select store image"
              photos={photo}
              name="image"
              setValue={setValue}
              multiple={false}
              previewCol={4}
              errors={errors}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <button type="submit" className="btn btn-primary me-2">
          <Loader />
        </button>
      ) : (
        <button type="submit" className="btn btn-success me-2">
          Update Delivery Company
        </button>
      )}
    </form>
  );
};
export default DeliveryManMainInformations;
