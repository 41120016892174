import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import useProductFunctions from "../../../hooks/app/products/useProductFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateProductSchema } from "../../../validation/app/products/CreateProductSchema";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import AllWilayas from "./CreateProduct/AllWilayas";
import SomeWilayas from "./CreateProduct/SomeWilayas";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddItemDialog from "./CreateProduct/AddItemDialog";

const CreateProduct = () => {
  const [Categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState(<></>);
  const [wilayas, setWilayas] = useState([]);
  const [loadingwilayas, setLoadingWilayas] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const { getCategories, createProduct, getWilayas } = useProductFunctions();
  useEffect(() => {
    getCategories(setCategories);
    getWilayas(setLoadingWilayas, setWilayas);
  }, []);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(CreateProductSchema),
    mode: "onBlur",
  });

  const photo = watch("image");
  const discount_delivery = register("discount_delivery");

  const onSubmit = (data) => {
    data["items"] = productItems;
    createProduct(
      {
        ...data,
      },
      setLoading
    );
  };
  const HandleDeliveryDiscount = (e) => {
    switch (e.target.value) {
      case "no_discount":
        setChildren(<></>);
        break;
      case "all_wilayas":
        setChildren(<AllWilayas register={register} errors={errors} />);
        break;
      case "some_wilayas":
        setChildren(<SomeWilayas wilayas={wilayas} register={register} />);
        break;
      default:
        setChildren(<></>);
        break;
    }
  };
  const DeleteProduct = (index) => {
    setProductItems((productItems) => {
      return productItems.filter((_, i) => i !== index);
    });
  };
  return (
    <>
      <Breadcrumb
        title="Products"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create Product"
        secondaryLink="/products"
        secondarytitle="Products"
      />
      <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-7">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div
                    className={
                      productItems.length === 0 ? "col-lg-3" : "col-lg-4"
                    }
                  >
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        autocomplete="off"
                        placeholder="Product name"
                        {...register("name")}
                      />
                      {errorMessage(errors, "name")}
                    </div>
                  </div>
                  {productItems.length === 0 ? (
                    <div className="col-lg-3">
                      <div className="mb-3">
                        <label for="price" className="form-label">
                          Product Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="price"
                          placeholder="Product Price"
                          defaultValue={0}
                          {...register("price")}
                        />
                        {errorMessage(errors, "price")}
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={
                      productItems.length === 0 ? "col-lg-3" : "col-lg-4"
                    }
                  >
                    {" "}
                    <div className="mb-3">
                      <label for="status" className="form-label">
                        Status
                      </label>
                      <select
                        name="status"
                        id="status"
                        className="form-control"
                        {...register("status")}
                      >
                        <option value="">Selectionner</option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                      {errorMessage(errors, "status")}
                    </div>
                  </div>
                  <div
                    className={
                      productItems.length === 0 ? "col-lg-3" : "col-lg-4"
                    }
                  >
                    {" "}
                    <div className="mb-3">
                      <label for="category" className="form-label">
                        Category
                      </label>
                      <select
                        name="category"
                        id="category"
                        className="form-control"
                        {...register("cat_id")}
                      >
                        <option value="">Selectionner</option>
                        {Categories?.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {errorMessage(errors, "cat_id")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        rows={5}
                        className="form-control"
                        id="description"
                        placeholder="Product Description"
                        {...register("description")}
                      />
                      {errorMessage(errors, "description")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="note" className="form-label">
                        Product note
                      </label>
                      <textarea
                        rows={5}
                        className="form-control"
                        id="note"
                        placeholder="Product note"
                        {...register("note")}
                      />
                      {errorMessage(errors, "note")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="discount_delivery" className="form-label">
                        Delivery Discount
                      </label>
                      <select
                        name="discount_delivery"
                        id="discount_delivery"
                        className="form-control"
                        {...discount_delivery}
                        onChange={(e) => {
                          discount_delivery.onChange(e);
                          HandleDeliveryDiscount(e);
                        }}
                      >
                        <option value="" selected>
                          Selectionner
                        </option>
                        <option value="no_discount">No Discount</option>
                        <option value="all_wilayas">All Wilayas</option>
                        <option value="some_wilayas">Some Wilayas</option>
                      </select>
                      {errorMessage(errors, "discount_delivery")}
                    </div>
                  </div>
                  {children}
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Create Product
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-1">
                      <ImageDropzone
                        placeholder="Click to select images"
                        photos={photo}
                        name="image"
                        setValue={setValue}
                        multiple={true}
                        previewCol={5}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-2">
                    <div className="g-3 row">
                      <div className="col-sm-5">
                        <div className="search-box me-2 mb-2 d-inline-block"></div>
                      </div>
                      <div className="col-sm-auto ms-auto">
                        <div>
                          <Link
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#AddProductItem"
                          >
                            <FeatherIcon
                              icon="plus"
                              className="ri-add-line align-bottom me-1"
                            />
                            Add Variant
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            {/* <th>Qty</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productItems?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th>{item.name}</th>
                                <th>{item.price}</th>
                                {/* <th>{item.qty}</th> */}
                                <th>
                                  <div className="d-flex flex-row justify-between">
                                    <Link onClick={(e) => DeleteProduct(index)}>
                                      <FeatherIcon
                                        icon="trash"
                                        color="red"
                                        size={20}
                                      />
                                    </Link>
                                  </div>
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {productItems?.length == 0 ? (
                      <div className="alert alert-info mt-2" role="alert">
                        No Variants
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AddItemDialog setProductItems={setProductItems} />
    </>
  );
};
export default CreateProduct;
