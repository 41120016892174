import { useQuery } from "@tanstack/react-query";
import { getAllSourceApi } from "../../../apis/app/order_source/getOrderSourceApis";

export const useGetAllSource = (page) => {
  const { data: Datas, isLoading: loadingData } = useQuery({
    queryKey: ["sources", page],
    queryFn: () => getAllSourceApi(page),
  });
  return { Datas, loadingData };
};
