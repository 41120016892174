import { useFormContext } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";

const RequestEmail = ({ onSubmit, loading }) => {
  const methods = useFormContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="username" className="form-label">
          Username
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Username"
          autoComplete="off"
          {...register("username", { required: true })}
        />
        {errorMessage(errors, "username")}
      </div>
      {loading ? (
        <button
          type="button"
          disabled
          className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
        >
          <Loader />
        </button>
      ) : (
        <button
          onClick={handleSubmit(onSubmit)}
          className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
        >
          Send Code
        </button>
      )}
    </form>
  );
};
export default RequestEmail;
