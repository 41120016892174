import { handleErrorApi } from "../../../helpers/handleErrorApi";
import {
  createYalidineCompany,
  createNoestCompany,
  createMaystroCompany,
  createNewCompany,
  createZrExpressCompany,
  getDelivery,
  createEcoTrackCompany,
  getOneDeliveryCompany,
  updateDeliveryCompany
} from "../../../apis/app/delivery/company/DeliveryCompanyApi";
import { useNavigate } from "react-router-dom";

const DeliveryCompany = () => {
  const navigate = useNavigate();

  const getDeliveryCompanies = async (
    setDeliveryCompanies,
    setDeliveryCompaniesLoading
  ) => {
    try {
      setDeliveryCompaniesLoading(true);
      const response = await getDelivery();
      setDeliveryCompanies(response.data?.data);
    } catch (error) {
      setDeliveryCompaniesLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setDeliveryCompaniesLoading(false);
    }
  };
  const getDeliveryCompany = async (id,setLoadingData,setData)=>{
    try {
      setLoadingData(true);
      const response = await getOneDeliveryCompany(id);
      setData(response.data?.data)
    } catch (error) {
      setLoadingData(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoadingData(false);
    }
  }
  const UpdateDeliveryCompany = async (data,setLoading,id)=>{
    try {
      setLoading(true);
      const response = await updateDeliveryCompany(id,data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  const CreateNewDeliveryCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createNewCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const CreateYalidineCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createYalidineCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const CreateNoestCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createNoestCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const CreateMaystroCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createMaystroCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const CreateZrExpressCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createZrExpressCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const CreateEcoTrackCompany = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await createEcoTrackCompany(data);
      navigate("/delivery/company");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  return {
    getDeliveryCompanies,
    CreateNewDeliveryCompany,
    CreateYalidineCompany,
    CreateNoestCompany,
    CreateMaystroCompany,
    CreateZrExpressCompany,
    CreateEcoTrackCompany,
    getDeliveryCompany,
    UpdateDeliveryCompany
  };
};
export default DeliveryCompany;
