import React from "react";
import DataTable from "react-data-table-component";

const TableComponent = ({
  columns,
  data,
  meta,
  setPage,
  isLoading,
  pagination = true,
}) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{
        rows: {
          style: {
            backgroundColor: "#0c1427",
            borderColor: "inherit",
            color: "white",
            fontFamily: ["Roboto", "Helvetica", "sans-serif"],
          },
        },
        headCells: {
          style: {
            backgroundColor: "#0c1427",
            borderColor: "inherit",
            borderTop: 0,
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "uppercase",
            color: "#7987a1",
          },
        },
        headRow: {
          style: {
            borderColor: "inherit",
          },
        },
        pagination: {
          style: {
            background: "transparent",
            color: "white",
          },
        },
      }}
      pagination={pagination}
      paginationServer
      paginationComponent={() =>
        pagination && (
          <ul className="pagination mb-0 justify-content-end pt-2">
            <li
              className={`page-item ${meta?.current_page === 1 && "disabled"}`}
              onClick={() => {
                if (meta?.current_page === 1) return;
                else if (meta?.current_page > 1)
                  setPage(meta?.current_page - 1);
              }}
            >
              <p className="page-link">Previous</p>
            </li>
            <li className="page-item">
              <p className="page-link active">{meta?.current_page}</p>
            </li>
            <li
              className={`page-item ${
                meta?.current_page === meta?.last_page && "disabled"
              }`}
              onClick={() => {
                if (meta?.current_page === meta?.last_page) return;
                else setPage(meta?.current_page + 1);
              }}
            >
              <p className="page-link">{meta?.current_page + 1}</p>
            </li>
            <li
              className={`page-item ${
                meta?.current_page === meta?.last_page && "disabled"
              }`}
              onClick={() => {
                if (meta?.current_page === meta?.last_page) return;
                else setPage(meta?.current_page + 2);
              }}
            >
              <p className="page-link">{meta?.current_page + 2}</p>
            </li>

            <li
              className={`page-item ${
                meta?.current_page === meta?.last_page && "disabled"
              }`}
              onClick={() => {
                if (meta?.current_page === meta?.last_page) return;
                else setPage(meta?.current_page + 1);
              }}
            >
              <p className="page-link">Next</p>
            </li>
          </ul>
        )
      }
      paginationTotalRows={meta?.total}
      progressPending={isLoading}
      responsive
    />
  );
};

export default TableComponent;
