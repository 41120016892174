import * as yup from "yup";
import { useState, useEffect } from "react";
import useAllFunctionOrderLeads from "../../../hooks/app/orderLeads/useAllFunctionOrderLeads";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/errorMessage";
import Toast from "../../../helpers/toast";

const ConfirmLeadAddProductDialog = ({ setProducts,products }) => {
  const [productDB, setProductDB] = useState([]);
  const { getStoreProducts } = useAllFunctionOrderLeads();
  useEffect(() => {
    getStoreProducts(setProductDB);
  }, []);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        product_id: yup
          .string()
          .typeError("Please choose Product")
          .required("Product is required"),
        qty: yup
          .number()
          .typeError("Qty must be a number")
          .required("Please provide quantity.")
          .min(1, "Min quantity is 1"),
      })
    ),
  });

  const HandleAdd = (data) => {
    setProducts(products=>[...products,data]);
    Toast('success',"Product was added");
  };
  return (
    <>
      <div
        className="modal fade"
        id="LeadAddProduct"
        tabIndex="-1"
        aria-labelledby="LeadAddProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="LeadAddProductLabel">
                Update Yalidine Informations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-3">
                  <select
                    name="product_id"
                    id="product_id"
                    className="form-control"
                    {...register("product_id")}
                  >
                    <option value="">Selectionner</option>
                    {productDB?.map((item, index) => {
                      return (
                        <option key={index} value={item.id+'-'+item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "product_id")}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="1"
                    id="qty"
                    autocomplete="off"
                    placeholder="Qty"
                    {...register("qty")}
                  />
                  {errorMessage(errors, "qty")}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmLeadAddProductDialog;
