import { useState } from "react";
import DeliveryCompany from "../../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateNoestSchema } from "../../../../../validation/app/deliveryCompany/CreateNoestSchema";
import Loader from "../../../../../components/common/general/Loader";
import { errorMessage } from "../../../../../helpers/errorMessage";

const NoestCompany = () => {
  const [loading, setLoading] = useState(false);
  const { CreateNoestCompany } = DeliveryCompany();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateNoestSchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateNoestCompany(data, setLoading);
  };
  return (
    <>
    <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="api_token" className="form-label">
              Api Token
            </label>
            <input
              type="text"
              className="form-control"
              id="api_token"
              placeholder="Api Token"
              {...register("api_token")}
            />
            {errorMessage(errors, "api_token")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="guid" className="form-label">
              Guid
            </label>
            <input
              type="text"
              className="form-control"
              id="guid"
              placeholder="Guid"
              {...register("guid")}
            />
            {errorMessage(errors, "guid")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="status" className="form-label">
              Status
            </label>
            <select
              name="status"
              id="status"
              className="form-control"
              {...register("status")}
            >
              <option value="">Selectionner</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            {errorMessage(errors, "status")}
          </div>
        </div>
      </div>
      {loading ? (
        <button type="submit" className="btn btn-primary me-2">
          <Loader />
        </button>
      ) : (
        <button type="submit" className="btn btn-success me-2">
          Create
        </button>
      )}
    </form>
  </>
  );
};
export default NoestCompany;
