import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateCategorySchema } from "../../../validation/app/categories/CreateCategorySchema";
import useCreateCategory from "../../../hooks/app/categories/useCreateCategory";
import { errorMessage } from "../../../helpers/errorMessage";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Loader from "../../../components/common/general/Loader";

const CreateCategory = () => {
  const [loading, setLoading] = useState(false);
  const { CreateCategory } = useCreateCategory();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(CreateCategorySchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateCategory(
      {
        ...data,
      },
      setLoading
    );
  };
  const photo = watch("image");
  return (
    <>
      <Breadcrumb
        title="Categories"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create"
        secondaryLink="/categories"
        secondarytitle="Categories"
      />
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Create Category</h6>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    autocomplete="off"
                    placeholder="Category name"
                    {...register("name")}
                  />
                  {errorMessage(errors, "name")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="status" className="form-label">
                    Status
                  </label>
                  <select
                    name="status"
                    id="status"
                    className="form-control"
                    {...register("status")}
                  >
                    <option value="">Selectionner</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                  {errorMessage(errors, "status")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    rows={7}
                    className="form-control"
                    id="name"
                    placeholder="Category Description"
                    {...register("description")}
                  />
                  {errorMessage(errors, "description")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-1">
                  <ImageDropzone
                    placeholder="Drag and drop or click to select image"
                    photos={photo}
                    name="image"
                    setValue={setValue}
                    multiple={false}
                    previewCol={5}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-primary me-2">
                <Loader />
              </button>
            ) : (
              <button type="submit" className="btn btn-success me-2">
                Create Category
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateCategory;
