import axiosInstance from "../../Axios/axios";

const getAllSourceApi = async (page) => {
  return await axiosInstance.get(`store/orders/sources?page=${page}`);
};

const getCreateSourceApi = async (data) => {
  return await axiosInstance.post(`store/orders/sources`, data);
};

const getSourceDetailsApi = async (id) => {
  return await axiosInstance.get(`store/orders/sources/${id}`);
};

const getSourceUpdateApi = async (id, data) => {
  return await axiosInstance.post(`store/orders/sources/update/${id}`, data);
};

export {
  getAllSourceApi,
  getCreateSourceApi,
  getSourceDetailsApi,
  getSourceUpdateApi,
};
