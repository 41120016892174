import * as yup from "yup";

export const CreateNoestSchema = yup.object().shape({
  api_token: yup.string().required("Api Token is required"),
  guid: yup.string().required("GUID is required"),
  status: yup
  .boolean()
  .typeError("Status is required")
  .oneOf([true, false], "Status is required"),
});
