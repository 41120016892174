import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useStatusFunctions from "../../../hooks/app/orderStatus/useStatusFunctions";
import OrderTypeCustom from "./OrderTypes/OrderTypeCustom";
import OrderTypeExists from "./OrderTypes/OrderTypeExists";
import Toast from "../../../helpers/toast";
import Loader from "../../../components/common/general/Loader";

const CreateOrderStatusDialog = () => {
  const [loading, setLoading] = useState(false);
  const [Status, setStatus] = useState([]);
  const [statusType, setStatusType] = useState(undefined);
  const [children, setChildren] = useState(<></>);

  const { getAllStatus, createStatus } = useStatusFunctions();
  useEffect(() => {
    getAllStatus(setStatus);
  }, []);
  const {
    register,
    handleSubmit,
  } = useForm({
  });
  const status_id = register("status_id");
  const onSubmitCode = (data) => {
    if (statusType == undefined) {
      Toast("error", "Please Select Status Type");
    } else {
      switch (statusType) {
        case 2:
          if (data.name === "" && data.status === "") {
            Toast("error", "Please provide name & status");
          } else {
            data["type"] = statusType;
            createStatus(data, setLoading);
          }
          break;
        case 3:
          if (data.status === "") {
            Toast("error", "Please provide status");
          } else {
            data["type"] = statusType;
            createStatus(data, setLoading);
          }
          break;
        default:
          Toast("error", "Error Status Type");
          break;
      }
    }
  };
  const HandleChange = (e) => {
    switch (e.target.value) {
      case "none":
        setStatusType(1);
        setChildren(<></>);
        break;
      case "custom":
        setStatusType(2);
        setChildren(<OrderTypeCustom register={register} />);
        break;
      default:
        setStatusType(3);
        setChildren(<OrderTypeExists register={register} />);
        break;
    }
  };
  return (
    <div
      className="modal fade"
      id="CreateStatus"
      tabindex="-1"
      aria-labelledby="CreateStatusLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="CreateStatusLabel">
              Create Order Status
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmitCode)}>
            <div className="modal-body">
              <div className="mb-3">
                <label for="Select1" className="form-label">
                  Select Status
                </label>
                <select
                  className="form-select"
                  id="Select1"
                  {...status_id}
                  onChange={(e) => {
                    status_id.onChange(e);
                    HandleChange(e);
                  }}
                >
                  <option selected value="none">
                    Select
                  </option>
                  <option value="custom">Create custom status</option>
                  {Status.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>{children}</div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {loading ? (
                <button type="submit" className="btn btn-primary me-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-primary me-2">
                  Create
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateOrderStatusDialog;
