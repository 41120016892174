import { handleErrorApi } from "../../../helpers/handleErrorApi";
import {
  UpdatePasswordApi,
  UpdateMainInformationApi,
  AddAddressProfileApi,
  DeleteAddressApi
} from "../../../apis/app/profile/getAllProfileApis";
import Toast from "../../../helpers/toast.js";
import { useNavigate } from "react-router-dom";
import { CreateDeliveryManSetErrors } from "../../../helpers/HandleSetErrors/CreateDeliveryManSetErrors.js";
import { useDispatch } from "react-redux";
import { setUser } from "../../../features/slices/authSlice";

const useProfileFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UpdatePassword = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await UpdatePasswordApi(data);
      Toast("success", "You have successfully update passowrd");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        if (data === "exists") {
          setError("password", {
            type: "submit",
            message: error?.response.data.message,
          });
        }
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const UpdateMainInformation = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await UpdateMainInformationApi(data);
      dispatch(setUser({ user: response.data.data.user }));
      Toast("success", "You have successfully update informations");
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        CreateDeliveryManSetErrors(data, setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const AddAddressProfile = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await AddAddressProfileApi(data);
      navigate(0);
    } catch (error) {
      setLoading(false);

      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const DeleteAddressFunction = async (id, setLoading) => {
    try {
      setLoading(true);
      const response = await DeleteAddressApi(id);
      navigate(0);
    } catch (error) {
      setLoading(false);

      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  return {
    UpdatePassword,
    UpdateMainInformation,
    AddAddressProfile,
    DeleteAddressFunction
  };
};
export default useProfileFunctions;
