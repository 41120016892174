import { useNavigate } from "react-router-dom";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast.js";
import { getStatisticsApi } from "../../../apis/app/main_dash/getAllLeadsFunctionsApi";
const useGetStatisticsFunctions = () => {
  const navigate = useNavigate();
  const getStatistics = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getStatisticsApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getStatistics,
  };
};
export default useGetStatisticsFunctions;
