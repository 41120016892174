import * as yup from "yup";

export const addTarifSchema = yup.object().shape({
  wilaya_id: yup
    .string()
    .typeError("Please choose Wilaya")
    .required("Wilaya is required"),
  price_home: yup
    .number()
    .typeError("Price Home must be a number")
    .required("Please provide Price Home.")
    .min(0, "Too little"),
});
