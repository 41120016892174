import { useQuery } from "@tanstack/react-query";
import { getAllOrdersApi } from "../../../apis/app/orders/getAllOrdersApis";

export const useGetAllOrders = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["orders", page],
    queryFn: () => getAllOrdersApi(page),
  });
  return { Datas, loadingDatas };
};
