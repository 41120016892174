import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/auth/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../validation/auth/loginSchema";
import { errorMessage } from "../../helpers/errorMessage";
import Loader from "../../components/common/general/Loader";

const Login = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    login(
      {
        ...data,
      },
      setLoading
    );
  };
  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-4 pe-md-0">
                    <div className="auth-side-wrapper"></div>
                  </div>
                  <div className="col-md-8 ps-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <p className="noble-ui-logo logo-light d-block mb-2">
                        Track <span>COD</span>
                      </p>
                      <h5 className="text-muted fw-normal mb-4">
                        Welcome back! Log in to your account.
                      </h5>
                      <form
                        className="forms-sample"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="mb-3">
                          <label htmlFor="userEmail" className="form-label">
                            Email address, Phone or Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email address, Phone or Username"
                            autoComplete="off"
                            {...register("username", { required: true })}
                          />
                          {errorMessage(errors, "username")}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="userPassword" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="userPassword"
                            // autoComplete="current-password"
                            placeholder="Password"
                            {...register("password", { required: true })}
                          />
                          {errorMessage(errors, "password")}
                        </div>
                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="authCheck"
                            {...register("remember_me",)}

                          />
                          <label
                            className="form-check-label"
                            htmlFor="authCheck"
                          >
                            Remember me
                          </label>
                        </div>
                        <div>
                          {loading ? (
                            <button
                              type="button"
                              disabled
                              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
                            >
                              <Loader />
                            </button>
                          ) : (
                            <button
                              onClick={handleSubmit(onSubmit)}
                              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
                            >
                              Login
                            </button>
                          )}
                        </div>
                        <Link
                          to="/forgot_password"
                          className="d-block mt-3 text-muted"
                        >
                          Forgot your password? Reset it
                        </Link>
                        <Link
                          to="/signup"
                          className="d-block mt-3 text-muted"
                        >
                          Don't have account? SignUp Now
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
