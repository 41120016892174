import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Controller } from "react-hook-form";
import { errorMessage } from "../../helpers/errorMessage";
import "./select.css"
const animatedComponents = makeAnimated();

const SelectComponent = ({
  isMulti = false,
  data,
  defaultData,
  name,
  control,
  closeMenuOnSelect = false,
  placeholder,
  disabled = false,
  withLabel = false,
  errors,
  label
}) => {
  return (
    <Controller
      name={name}
      control={control}
      key={defaultData}
      defaultValue={defaultData}
      render={({ field: { onChange } }) => (
        <>
          <div className="form-group mt-1">
            <label className="form-label">{label}</label>
            <Select
              onChange={(e) => {
                if (isMulti) {
                  onChange(e);
                } else if (withLabel) {
                  onChange(e);
                } else {
                  onChange(e.value);
                }
              }}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              options={data}
              components={animatedComponents}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#172340",
                  ":hover": {
                    borderColor: "#545c6e",
                  },
                  background: "transparent",
                  ...baseStyles,
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#172340",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#d0d6e1",
                  background: "transparent",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#d0d6e1",
                  background: state.isFocused ? "#6571ff" : "#0c1427",
                }),
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#172340",
                }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: "#d0d6e1",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "#172340",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "#6610f2",
                  ":hover": {
                    color: "#6571ff",
                  },
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  neutral50: "#172340",
                },
              })}
              defaultValue={defaultData}
              key={defaultData}
              placeholder={placeholder || "Select ..."}
              isDisabled={disabled}
            />
            {errorMessage(errors, name)}
          </div>
        </>
      )}
    />
  );
};

export default SelectComponent;
