import * as yup from "yup";

export const CreateZrExpressSchema = yup.object().shape({
  token: yup.string().required("Token is required"),
  key: yup.string().required("Key is required"),
  status: yup
  .boolean()
  .typeError("Status is required")
  .oneOf([true, false], "Status is required"),
});
