import React from "react";
import { errorMessage } from "../../helpers/errorMessage";
import { Controller } from "react-hook-form";

const InputComponent = ({
  error = null,
  label,
  inputType,
  placeholder,
  name,
  errors,
  control,
  autoComplete = "off",
  defaultValue,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue
      }
      render={({ field: { onChange, value, name } }) => (
        <div className="form-group mt-1">
          <label className="form-label">{label}</label>
          <input
            type={inputType}
            className="form-control"
            placeholder={placeholder}
            onChange={!readOnly && onChange}
            value={value}
            name={name}
            autoComplete={autoComplete && autoComplete}
            disabled={disabled}
            readOnly={readOnly}
            defaultValue={
              defaultValue
            }
          />
          {errorMessage(errors, error || name)}
        </div>
      )}
    />
  );
};

export default InputComponent;
