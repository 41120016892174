import { errorMessage } from "../../../../../helpers/errorMessage";

const AddTarifAllCommunes = ({register,errors}) => {
  return (
    <>
      <label htmlFor="price_home" className="form-label">
        Price Home
      </label>
      <input
        type="number"
        className="form-control"
        id="price_home"
        autoComplete="off"
        placeholder="Price home"
        {...register("price_home")}
      />
      {errorMessage(errors, "price_home")}
    </>
  );
};
export default AddTarifAllCommunes;
