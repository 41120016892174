import * as yup from "yup";

export const AddAddressSchema = yup.object().shape({
  wilaya_id: yup
    .string()
    .typeError("Please choose Wilaya")
    .required("Wilaya is required"),
  daira_id: yup
    .string()
    .typeError("Please choose Wilaya")
    .required("Daira is required"),
  address: yup.string().required("Please provide Address."),
  commune: yup.string().required("Please provide Commune."),
});
