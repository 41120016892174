import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useGetStatisticsFunctions from "../../../hooks/app/mainDash/useGetStatisticsFunctions";
import { useEffect, useState } from "react";
import Loader from "../../../components/common/general/Loader";
import Loading from "../../Loading";
import CAN from "../../../features/can";

const MainDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState({});
  const { getStatistics } = useGetStatisticsFunctions();

  useEffect(() => {
    getStatistics(setLoading, setData);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div>
          <h4 className="mb-3 mb-md-0">Welcome to Dashboard</h4>
        </div>
        <div className="d-flex align-items-center flex-wrap text-nowrap"></div>
      </div>
      {loading === true ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="card-title mb-0">Leads</h6>
                  <div className="dropdown mb-2">
                    <a
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FeatherIcon icon="more-horizontal" size={18} />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/leads"
                      >
                        <span className="">View</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-2">
                      {loading ? <Loader /> : Data.leads}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="card-title mb-0">Orders</h6>
                  <div className="dropdown mb-2">
                    <a
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FeatherIcon icon="more-horizontal" size={18} />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/orders"
                      >
                        <span className="">View</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-2">
                      {loading ? <Loader /> : Data.orders}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="card-title mb-0">Customers</h6>
                  <div className="dropdown mb-2">
                    <a
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FeatherIcon icon="more-horizontal" size={18} />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/customers"
                      >
                        <span className="">View</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-2">
                      {loading ? <Loader /> : Data.customers}
                    </h3>
                    {/* <div className="d-flex align-items-baseline">
                    <p className="text-success">
                      <span>+3.3%</span>
                      <i data-feather="arrow-up" className="icon-sm mb-1"></i>
                    </p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="card-title mb-0">Products</h6>
                  <div className="dropdown mb-2">
                    <a
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FeatherIcon icon="more-horizontal" size={18} />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/products"
                      >
                        <span className="">View</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-2">
                      {loading ? <Loader /> : Data.products}
                    </h3>
                    {/* <div className="d-flex align-items-baseline">
                    <p className="text-success">
                      <span>+3.3%</span>
                      <i data-feather="arrow-up" className="icon-sm mb-1"></i>
                    </p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MainDashboard;
