import { useState } from "react";

const useSearch = () => {
  const [query, setQuery] = useState("");

  const search = (data, keys) => {
    if (query?.length === 0) {
      return data;
    } else
      return data?.filter((item) =>
        keys?.some((key) => item[key]?.toLowerCase()?.includes(query))
      );
  };

  return { query, setQuery, search };
};

export default useSearch;
