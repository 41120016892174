import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useAllFunctionOrderLeads from "../../../hooks/app/orderLeads/useAllFunctionOrderLeads";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import { errorMessage } from "../../../helpers/errorMessage";
import Toast from "../../../helpers/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateLeadStatusSchema } from "../../../validation/app/leads/UpdateLeadStatusSchema";
import InputComponent from "../../../components/FormUpdate/InputComponent";

const ViewLeads = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [dataStatus, setDataStatus] = useState([]);
  
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { getOrderLeadDetails, getOrderLeadStatus, getUpdateOrderLeadStatus,getUpdateOrderLead } =
    useAllFunctionOrderLeads();

  useEffect(() => {
    getOrderLeadStatus(id, setDataStatus);
    getOrderLeadDetails(id, setLoadingData, setData);
  }, [id]);

  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const {
    register: registerStatus,
    handleSubmit: handleSubmitStatus,
    formState: { errors: errorsStatus },
  } = useForm({
    resolver: yupResolver(UpdateLeadStatusSchema),
  });

  const onSubmit = (data) => {
    if (
      data.first_name === undefined &&
      data.last_name === undefined &&
      data.email === undefined &&
      data.delivery_type === undefined &&
      data.daira === undefined &&
      data.address === undefined &&
      data.note === undefined &&
      data.phone === undefined &&
      data.phone_secondary === undefined &&
      data.source === undefined &&
      data.source_value === undefined &&
      data.wilaya === undefined
    ) {
      Toast("error", "Nothing to Updated");
    } else {
      getUpdateOrderLead(id,data,setLoadingUpdate);
    }
  };
  const SubmitUpdateStatus = (temp_data) => {
    getUpdateOrderLeadStatus(id, temp_data, setLoading);
  };
  return (
    <>
      <Breadcrumb
        title="Leads"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View & Update Lead"
        secondaryLink="/leads"
        secondarytitle="Leads"
      />
      {loadingData ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-8">
              <form
                className="forms-sample"
                key={1}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Update Informations</div>
                    <div className="row">
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="First name"
                          name="first_name"
                          placeholder="First name"
                          control={control}
                          defaultValue={data?.first_name}
                          error="first_name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Last name"
                          name="last_name"
                          placeholder="Last name"
                          control={control}
                          defaultValue={data?.last_name}
                          error="last_name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Phone"
                          name="phone"
                          placeholder="Phone"
                          control={control}
                          defaultValue={data?.phone}
                          error="phone"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Phone Secondary"
                          name="phone_secondary"
                          placeholder="Phone Secondary"
                          control={control}
                          defaultValue={data?.phone_secondary}
                          error="phone_secondary"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Email"
                          name="email"
                          placeholder="Email"
                          control={control}
                          defaultValue={data?.email}
                          error="email"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="address"
                          name="address"
                          placeholder="address"
                          control={control}
                          defaultValue={data?.address}
                          error="address"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="wilaya"
                          name="wilaya"
                          placeholder="wilaya"
                          control={control}
                          defaultValue={data?.wilaya}
                          error="wilaya"
                        />
                      </div>
                      <div className="col-lg-4">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="daira"
                          name="daira"
                          placeholder="daira"
                          control={control}
                          defaultValue={data?.daira}
                          error="daira"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="source"
                          name="source"
                          placeholder="source"
                          control={control}
                          defaultValue={data?.source}
                          error="source"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Source Value"
                          name="sourve_value"
                          placeholder="Source Value"
                          control={control}
                          defaultValue={data?.sourve_value}
                          error="sourve_value"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Remarque"
                          name="note"
                          placeholder="Remarque"
                          control={control}
                          defaultValue={data?.note}
                          error="note"
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Delivery Type"
                          name="delivery_type"
                          placeholder="Delivery Type"
                          control={control}
                          defaultValue={data?.delivery_type}
                          error="delivery_type"
                        />
                      </div>
                    </div>
                    {loadingUpdate ? (
                      <button type="submit" className="btn btn-primary me-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success me-2">
                        Update Lead
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Update Status</div>
                  <form
                    className="forms-sample"
                    key={2}
                    onSubmit={handleSubmitStatus(SubmitUpdateStatus)}
                  >
                    <div className="mb-2">
                      <label for="category" className="form-label">
                        Current Status:{" "}
                        <span className="text-success">
                          {data?.current_status?.status?.name}
                        </span>
                      </label>
                      <select
                        name="category"
                        id="category"
                        className="form-control"
                        {...registerStatus("status_id")}
                      >
                        <option value="">Selectionner</option>
                        {dataStatus?.map((item, key) => {
                          return (
                            <option value={item.id} key={key}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {errorMessage(errorsStatus, "status_id")}
                    </div>
                    <div className="mb-2">
                      <label for="remarque" className="form-label">
                        Remarque
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="remarque"
                        placeholder="Remarque"
                        {...registerStatus("remarque")}
                      />
                      {errorMessage(errorsStatus, "remarque")}
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-primary me-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success me-2">
                        Update Status
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Status History</div>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Remarque</th>
                          <th>Added by</th>
                          <th>Created at</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.status?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>{item.status?.name}</th>
                              <th>{item.remarque}</th>
                              {item.added_by != null ? (
                                <th>
                                  {item.added_by?.first_name +
                                    " " +
                                    item.added_by?.last_name}
                                </th>
                              ) : (
                                <th></th>
                              )}
                              <th>{item.created_at}</th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ViewLeads;
