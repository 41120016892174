import { useQuery } from "@tanstack/react-query";
import { getAllCustomersApi } from "../../../apis/app/customers/CustomersApis";

export const useGetAllCustomers = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["customers", page],
    queryFn: () => getAllCustomersApi(page),
  });
  return { Datas, loadingDatas };
};
