import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import * as yup from "yup";
import Toast from "../../../../helpers/toast";

const AddItemDialog = ({setProductItems}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .typeError("Please choose name")
          .required("Name is required"),
        // qty: yup
        //   .number()
        //   .typeError("Qty must be a number")
        //   .required("Please provide quantity.")
        //   .min(1, "Min quantity is 1"),
        price: yup
          .number()
          .typeError("Price must be a number")
          .required("Please provide Price."),
      })
    ),
  });
  const HandleAdd = (data) => {
    setProductItems(products=>[...products,data]);
    setValue("name",null);
    setValue("price",null);
  };
  return (
    <>
      <div
        className="modal fade"
        id="AddProductItem"
        tabIndex="-1"
        aria-labelledby="AddProductItemLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddProductItemLabel">
                Add Product Variant
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    {...register("name")}
                  />
                  {errorMessage(errors, "name")}
                </div>
                <div className="mb-3">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    defaultValue={0}
                    {...register("price")}
                  />
                  {errorMessage(errors, "price")}
                </div>
                {/* <div className="mb-3">
                  <label className="form-label">Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    id="qty"
                    placeholder="Qty"
                    {...register("qty")}
                  />
                  {errorMessage(errors, "qty")}
                </div> */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddItemDialog;
