import { handleErrorApi } from "../../../../helpers/handleErrorApi.js";
import { HandleMaystroApi } from "../../../../apis/app/delivery/company/HandleMaystroApi.js";
import Toast from "../../../../helpers/toast.js";

const useMaystroHandle = () => {

    const UpdateMaystro = async(data,setLoading,id)=>{
      try{
        setLoading(true);
        const response = await HandleMaystroApi(id,data);
        Toast("success", "You have successfully create your category");
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    return {
        UpdateMaystro
    };
}
export default useMaystroHandle;