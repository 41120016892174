import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import useGoogleSheetsFunctions from "../../../hooks/app/google_sheets/useGoogleSheetsFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import { importFromSheetSchema } from "../../../validation/app/google/importFromSheetSchema";

const ViewSheetFile = () => {
  const [Data, setData] = useState([]);
  const [LoadingData, setLoadingData] = useState(false);
  const { getSheetFileRows, CreateNewGoogleSheet } = useGoogleSheetsFunctions();

  const [loading, setLoading] = useState(false);

  const { id, sheet_id, file_id } = useParams();
  useEffect(() => {
    getSheetFileRows(id, sheet_id, file_id, setData, setLoadingData);
  }, [id, sheet_id]);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(importFromSheetSchema),
  });
  const onSubmit = (data) => {
    data["account_id"] = id;
    data["sheet_id"] = sheet_id;
    data["file_id"] = file_id;
    CreateNewGoogleSheet(data,setLoading);
  };
  return (
    <>
      <Breadcrumb
        title="Google Sheets"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Google Account"
        secondaryLink="/google_sheets"
        secondarytitle="Google Account"
      />
      {LoadingData === false ? (
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">Synchroniser Sheet File</h6>
            <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="name" className="form-label">
                      File name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      autocomplete="off"
                      placeholder="File name"
                      {...register("name")}
                    />
                    {errorMessage(errors, "name")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="start_row" className="form-label">
                      Start Row
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="start_row"
                      autocomplete="off"
                      placeholder="Start Row"
                      {...register("start_row")}
                    />
                    {errorMessage(errors, "start_row")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      id="status"
                      className="form-control"
                      {...register("status")}
                    >
                      <option value="">Selectionner</option>
                      <option value={true}>Enable</option>
                      <option value={false}>Disable</option>
                    </select>
                    {errorMessage(errors, "status")}
                  </div>
                </div>
              </div>
              <h6 className="card-title">Input Seeet File</h6>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label for="first_name" className="form-label">
                      First Name
                    </label>
                    <select
                      name="first_name"
                      id="first_name"
                      className="form-control"
                      {...register("first_name")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "first_name")}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label for="last_name" className="form-label">
                      Last Name
                    </label>
                    <select
                      name="last_name"
                      id="last_name"
                      className="form-control"
                      {...register("last_name")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "last_name")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <select
                      name="email"
                      id="email"
                      className="form-control"
                      {...register("email")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "email")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="phone" className="form-label">
                      Phone
                    </label>
                    <select
                      name="phone"
                      id="phone"
                      className="form-control"
                      {...register("phone")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "phone")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="phone_secondary" className="form-label">
                      Phone Secondary
                    </label>
                    <select
                      name="phone_secondary"
                      id="phone_secondary"
                      className="form-control"
                      {...register("phone_secondary")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "phone_secondary")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="address" className="form-label">
                      Address
                    </label>
                    <select
                      name="address"
                      id="address"
                      className="form-control"
                      {...register("address")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "address")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="wilaya" className="form-label">
                      Wilaya
                    </label>
                    <select
                      name="wilaya"
                      id="wilaya"
                      className="form-control"
                      {...register("wilaya")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "wilaya")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="daira" className="form-label">
                      Daira
                    </label>
                    <select
                      name="daira"
                      id="daira"
                      className="form-control"
                      {...register("daira")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "daira")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="source" className="form-label">
                      Source
                    </label>
                    <select
                      name="source"
                      id="source"
                      className="form-control"
                      {...register("source")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "source")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="source_value" className="form-label">
                      Source Value
                    </label>
                    <select
                      name="source_value"
                      id="source_value"
                      className="form-control"
                      {...register("source_value")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "source_value")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="note" className="form-label">
                      note
                    </label>
                    <select
                      name="note"
                      id="note"
                      className="form-control"
                      {...register("note")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "note")}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label for="delivery_type" className="form-label">
                      Delivery Type
                    </label>
                    <select
                      name="delivery_type"
                      id="delivery_type"
                      className="form-control"
                      {...register("delivery_type")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "delivery_type")}
                  </div>
                </div>
              </div>
              <div className="row">
                <h6 className="card-title">Products</h6>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="product" className="form-label">
                      Product
                    </label>
                    <select
                      name="product"
                      id="product"
                      className="form-control"
                      {...register("product")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "product")}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="product_qty" className="form-label">
                      product_qty
                    </label>
                    <select
                      name="product_qty"
                      id="product_qty"
                      className="form-control"
                      {...register("product_qty")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "product_qty")}
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <h6 className="card-title">Prices</h6>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label for="total" className="form-label">
                      total
                    </label>
                    <select
                      name="total"
                      id="total"
                      className="form-control"
                      {...register("total")}
                    >
                      <option value="">Selectionner</option>
                      {Data?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "total")}
                  </div>
                </div>
              </div> */}
              {loading ? (
                <button type="submit" className="btn btn-primary me-2 mt-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success me-2 mt-2">
                  Sync Google Sheet
                </button>
              )}
            </form>
          </div>
        </div>
      ) : <Loader/>}
    </>
  );
};
export default ViewSheetFile;
