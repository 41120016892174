import axiosInstance from "../../Axios/axios";
import axiosInstancePart from "../../Axios/axios_part";

const getAgentsApi = async (page) => {
    return await axiosInstance.get(`store/users/agents?page=${page}`);
};

const getCreateAgentsApi = async (data) => {
    return await axiosInstancePart.post(`store/users/agents`,data);
};

export {
    getAgentsApi,
    getCreateAgentsApi
}
