import { useForm } from "react-hook-form";
import InputComponent from "../../../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../../../components/FormUpdate/SelectComponent";
import { useState } from "react";
import Loader from "../../../../../components/common/general/Loader";
import Toast from "../../../../../helpers/toast";
import useMaystroHandle from "../../../../../hooks/app/DeliveryCompany/maystro/useMaystroHandle";

const DeliveryMaystroDialog = ({ datas }) => {
  const [loading, setLoading] = useState(false);
  const { UpdateMaystro } = useMaystroHandle();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: datas,
  });
  const UpdateYaldidine = (temp_data) => {
    if (
      temp_data.id_store === undefined &&
      temp_data.token === undefined &&
      temp_data.status === undefined
    ) {
      Toast("error", "Nothing to Update");
    } else {
      UpdateMaystro(temp_data, setLoading, datas.id);
    }
  };
  return (
    <div
      className="modal fade"
      id="maystroModal"
      tabIndex="-1"
      aria-labelledby="maystroModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="maystroModalLabel">
              Update Maystro Delivery Informations
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(UpdateYaldidine)}>
              <InputComponent
                errors={errors}
                inputType="text"
                label="Store ID"
                name="id_store"
                placeholder="Store ID"
                control={control}
                defaultValue={datas?.id_store}
                error="id_store"
              />
              <InputComponent
                errors={errors}
                inputType="text"
                label="Token"
                name="token"
                placeholder="Token"
                control={control}
                defaultValue={datas?.token}
                error="token"
              />
              <SelectComponent
                data={[
                  {
                    value: 1,
                    label: "Active",
                  },
                  {
                    value: 0,
                    label: "Inactive",
                  },
                ]}
                defaultData={datas?.status}
                control={control}
                name="status"
                closeMenuOnSelect
                label="Status"
              />
              {loading ? (
                <button type="submit" className="btn btn-primary mt-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success mt-2">
                  Update
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryMaystroDialog;
