import SelectComponent from "../../../../../components/FormUpdate/SelectComponent";
import { errorMessage } from "../../../../../helpers/errorMessage";

const AllTarifSelectedCommunes = ({ register, errors, Dairas, control }) => {
  return (
    <>
      <SelectComponent
        data={Dairas}
        control={control}
        name="daira_ids"
        closeMenuOnSelect
        label="Dairas"
        isMulti={true}
      />
      <label htmlFor="price_home" className="form-label">
        Price Home
      </label>
      <input
        type="number"
        className="form-control"
        id="price_home"
        autoComplete="off"
        placeholder="Price home"
        {...register("price_home")}
      />
      {errorMessage(errors, "price_home")}
    </>
  )
}
export default AllTarifSelectedCommunes;
