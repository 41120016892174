import * as yup from "yup";

export const CreateCategorySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  status: yup
    .boolean()
    .typeError("Status is required")
    .oneOf([true, false], "Status is required"),
  description: yup.string().required("Description is required"),
  image: yup.mixed().required("Image is required"),
});
