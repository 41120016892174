import Loader from "../../../components/common/general/Loader";

const LeadStatistics = ({ status, loading }) => {
  return (
    <div className="row">
      {status.map((item, index) => {
        return (
          <div className="col-lg-3 grid-margin stretch-card" key={index}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="card-title mb-0">{item.name}</h6>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-2">
                      {loading ? <Loader /> : item.count}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default LeadStatistics;
