import { useParams } from "react-router-dom";
import Loader from "../../../../components/common/general/Loader";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import InputComponent from "../../../../components/FormUpdate/InputComponent";
import { Controller } from "react-hook-form";
import { errorMessage } from "../../../../helpers/errorMessage";

const UpdateTarifDialog = ({
  datas,
  getWilayas,
  UpdateTarif,
  getWilayaDairas,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState([]);

  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  useEffect(() => {
    getWilayas(setWilayas, setWilayasLoading);
  }, [id]);

  const {
    control,
    setValue,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: datas,
  });
  const wilaya_id = register("wilaya_id");

  const Update = (data) => {
    const tarif_id = data?.id;
    UpdateTarif(id, tarif_id, data, setLoading);
  };
  const getDairas = (e) => {
    setValue("daira_id", "");
    getWilayaDairas(e.target.value, setDairas, setLoadingDairas);
  };

  return (
    <div
      className="modal fade"
      id="UpdataTarifModal"
      tabIndex="-1"
      aria-labelledby="UpdataTarifLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="UpdataTarifLabel">
              Update Tarif : {datas?.id}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(Update)}>
              <div className="form-group mt-1">
                <label className="form-label">Wilayas</label>
                <Controller
                  control={control}
                  name="wilaya_id"
                  render={({ field: { name } }) => (
                    <select
                      id={name}
                      name={name}
                      className="form-control"
                      {...wilaya_id}
                      onChange={(e) => {
                        wilaya_id.onChange(e);
                        getDairas(e);
                      }}
                      defaultValue={datas?.wilaya?.id}
                    >
                      <option value="">Selectionner</option>
                      {wilayas?.map((wilaya, index) => {
                        return (
                          <option key={index} value={wilaya.id}>
                            {wilaya.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
              </div>
              <div className="form-group mt-1">
                <label className="form-label">Daira</label>
                <Controller
                  control={control}
                  name="daira_id"
                  render={({ field: { name } }) => (
                    <select
                      id={name}
                      name={name}
                      className="form-control"
                      {...register('daira_id')}
                      defaultValue={datas?.daira?.id}
                    >
                      <option value="">Selectionner</option>
                      {Dairas?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
              </div>
              <InputComponent
                errors={errors}
                inputType="text"
                label="Price Home"
                name="price_home"
                placeholder="Price Home"
                control={control}
                defaultValue={datas?.price_home}
                error="price_home"
              />
              {/* <InputComponent
              errors={errors}
              inputType="text"
              label="Price Desk"
              name="price_desk"
              placeholder="Price Desk"
              control={control}
              defaultValue={datas?.price_desk}
              error="price_desk"
            />  */}
              {loading ? (
                <button type="submit" className="btn btn-primary mt-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success mt-2">
                  Update
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateTarifDialog;
