import Breadcrumb from "../../../../components/common/Breadcrumb";
import useSearch from "../../../../hooks/useSearch";
import { useGetDeliveryCompany } from "../../../../hooks/app/DeliveryCompany/useGetDeliveryCompany";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../../components/table/Table";
import { useState } from "react";
import DeliveryTable from "./DeliveryTable";
import DeliveryYalidineDialog from "./DeliveryDialog/DeliveryYalidineDialog";
import DeliveryNoestDialog from "./DeliveryDialog/DeliveryNoestDialog";
import DeliveryMaystroDialog from "./DeliveryDialog/DeliveryMaystroDialog";
import DeliveryEcoTrackDialog from "./DeliveryDialog/DeliveryEcoTrackDialog";
import DeliveryZrExpressDialog from "./DeliveryDialog/DeliveryZrExpressDialog";

const DeliveryCompany = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { datas, loading } = useGetDeliveryCompany(page);
  const TablecompanyColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.information.name,
      sortable: true,
    },
    {
      name: "phone",
      selector: (row) => row.information.phone,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.information.email,
      sortable: true,
    },
    {
      name: "website",
      selector: (row) => row.information.website,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Default",
      selector: (row) => {
        return (
          <span
            className={
              row?.default === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.default === true ? "True" : "False"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={row?.is_primary !== 1 && `/delivery/company/view/${row.id}`}
              title="View Company Details"
              className="me-3"
            >
              <FeatherIcon icon="eye" size={20}  color="green" />
            </Link>
            <Link
              to={row?.is_primary !== 1 && `/delivery/company/update/${row.id}`}
              title="update category"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete category">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Delivery Company"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Delivery Company"
      />
      <Table
        isLoading={loading}
        query={query}
        setQuery={setQuery}
        titleLabel="Company"
        columns={TablecompanyColumns}
        data={search(
          datas?.data?.data?.companies?.data,
          ["name", "description"],
          query
        )}
        meta={datas?.data?.data?.companies?.meta}
        setPage={setPage}
      />

      {loading === false ? (
        <>
          <div className="card mt-2">
            <div className="card-body">
              <h6 className="card-title">Delivery Companies</h6>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Default</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas?.data?.data?.yalidine ? (
                      <>
                        <DeliveryYalidineDialog
                          datas={datas?.data?.data?.yalidine}
                        />
                        <DeliveryTable
                          data={datas?.data?.data?.yalidine}
                          name="#yalidineModal"
                          company="yalidine"
                        />
                      </>
                    ) : null}
                    {datas?.data?.data?.noest ? (
                      <>
                        <DeliveryNoestDialog datas={datas?.data?.data?.noest} />
                        {/* <DeliveryTable
                          data={datas?.data?.data?.noest}
                          name="#noestModal"
                        /> */}
                      </>
                    ) : null}
                    {datas?.data?.data?.maystro ? (
                      <>
                        <DeliveryMaystroDialog
                          datas={datas?.data?.data?.maystro}
                        />
                        {/* <DeliveryTable
                          data={datas?.data?.data?.maystro}
                          name="#maystroModal"
                        /> */}
                      </>
                    ) : null}
                    {datas?.data?.data?.ecotrack ? (
                      <>
                        <DeliveryEcoTrackDialog
                          datas={datas?.data?.data?.ecotrack}
                        />
                        {/* <DeliveryTable
                          data={datas?.data?.data?.ecotrack}
                          name="#ecoTrackModal"
                        /> */}
                      </>
                    ) : null}
                    {datas?.data?.data?.zr_express ? (
                      <>
                        <DeliveryZrExpressDialog
                          datas={datas?.data?.data?.zr_express}
                        />
                        {/* <DeliveryTable
                          data={datas?.data?.data?.zr_express}
                          name="#zrexpressModal"
                        /> */}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default DeliveryCompany;
