import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "../src/features/store";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/js/bootstrap.bundle.min";

const queryClient = new QueryClient();
const Main = () => {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <Toaster
              toastOptions={{
                success: {
                  iconTheme: {
                    primary: "#6571ff",
                    secondary: "#fff",
                  },
                },
              }}
            />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    );
  };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);