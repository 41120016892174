import Breadcrumb from "../../../components/common/Breadcrumb";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { errorMessage } from "../../../helpers/errorMessage";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Loader from "../../../components/common/general/Loader";
import { CreateAgentSchema } from "../../../validation/app/agents/CreateAgentSchema";
import useAgentsFunctions from "../../../hooks/app/agents/useAgentsFunctions";

const CreateAgent = () => {
  const [loading, setLoading] = useState(false);
  const { CreateAgent } = useAgentsFunctions();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(CreateAgentSchema),
    mode: "onChange",
  });
  const photo = watch("image");
  const onSubmit = (data) => {
    CreateAgent(
      {
        ...data,
      },
      setLoading
    );
  };

  return (
    <>
      <Breadcrumb
        title="Agents"
        primaryLink="/"
        primaryTitle="Dashboard"
        secondaryLink="/users/agents"
        secondarytitle="Agents"
        thirdTitle="Create Agent"
      />
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Create Agent</h6>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="first_name" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    autocomplete="off"
                    placeholder="First Name"
                    {...register("first_name")}
                  />
                  {errorMessage(errors, "first_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="last_name" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    autocomplete="off"
                    placeholder="Last Name"
                    {...register("last_name")}
                  />
                  {errorMessage(errors, "last_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="username" className="form-label">
                    username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    autocomplete="off"
                    placeholder="username"
                    {...register("username")}
                  />
                  {errorMessage(errors, "username")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="email" className="form-label">
                    email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    autocomplete="off"
                    placeholder="email"
                    {...register("email")}
                  />
                  {errorMessage(errors, "email")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="phone" className="form-label">
                    phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    autocomplete="off"
                    placeholder="phone"
                    {...register("phone")}
                  />
                  {errorMessage(errors, "phone")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="status" className="form-label">
                    Status
                  </label>
                  <select
                    name="status"
                    id="status"
                    className="form-control"
                    {...register("status")}
                  >
                    <option value="">Selectionner</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {errorMessage(errors, "status")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="password" className="form-label">
                    password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    autocomplete="off"
                    placeholder="password"
                    {...register("password")}
                  />
                  {errorMessage(errors, "password")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="password_confirmation" className="form-label">
                    password confirmation
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_confirmation"
                    autocomplete="off"
                    placeholder="password_confirmation"
                    {...register("password_confirmation")}
                  />
                  {errorMessage(errors, "password_confirmation")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="could_process" className="form-label">
                    Could Process
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="could_process"
                    autocomplete="off"
                    placeholder="Could Process"
                    {...register("could_process")}
                    defaultValue={1}
                  />
                  {errorMessage(errors, "could_process")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="start_work" className="form-label">
                    Start Work
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="start_work"
                    autocomplete="off"
                    placeholder="Start Work"
                    {...register("start_work")}
                  />
                  {errorMessage(errors, "start_work")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="profit_confirmed" className="form-label">
                    Profit Confirme
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="profit_confirmed"
                    autocomplete="off"
                    placeholder="Profit Confirme"
                    {...register("profit_confirmed")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "profit_confirmed")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="profit_livred" className="form-label">
                    Profit livred
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="profit_livred"
                    autocomplete="off"
                    placeholder="Profit livred"
                    {...register("profit_livred")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "profit_livred")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="salary" className="form-label">
                    Salary
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="salary"
                    autocomplete="off"
                    placeholder="Salary"
                    {...register("salary")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "salary")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="profit_up_sell" className="form-label">
                    Profit up sell
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="profit_up_sell"
                    autocomplete="off"
                    placeholder="Profit up sell"
                    {...register("profit_up_sell")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "profit_up_sell")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="profit_down_sell" className="form-label">
                    Profit down sell
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="profit_down_sell"
                    autocomplete="off"
                    placeholder="Profit down sell"
                    {...register("profit_down_sell")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "profit_down_sell")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="profit_cross_sell" className="form-label">
                    Profit cross sell
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="profit_cross_sell"
                    autocomplete="off"
                    placeholder="Profit cross sell"
                    {...register("profit_cross_sell")}
                    defaultValue={0}
                  />
                  {errorMessage(errors, "profit_cross_sell")}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <ImageDropzone
                    placeholder="Drag and drop or click to select image"
                    photos={photo}
                    name="image"
                    setValue={setValue}
                    multiple={false}
                    previewCol={5}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-primary me-2">
                <Loader />
              </button>
            ) : (
              <button type="submit" className="btn btn-success me-2">
                Create Agent
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateAgent;
