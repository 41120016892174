import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import UpdateTarifDialog from "../UpdateTarifDialog";
import AddTarifDialog from "../AddTarifDialog";

const DeliveryManAddTarifs = ({
  dataMan,
  getWilayas,
  UpdateTarif,
  getWilayaDairas,
  AddTarif,
  setData,
}) => {
  const { id } = useParams();
  return (
    <>
      <div className="g-3 row">
        <div className="col-sm-5">
          <h6 className="card-title mt-2">Tarifs</h6>
        </div>
        <div className="col-sm-auto ms-auto">
          <div>
            <Link
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#AddTarifDialog"
            >
              <FeatherIcon
                icon="plus"
                className="ri-add-line align-bottom me-1"
              />
              Add Tarif
            </Link>
          </div>
        </div>
      </div>
      {dataMan?.tarifs?.length === 0 ? (
        <div className="alert alert-warning mt-2" role="alert">
          No Tarifs Found !
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>id</th>
                <th>Wilaya</th>
                <th>Daira</th>
                <th>All Communes</th>
                <th>Price Home</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataMan?.tarifs?.map((item, index) => {
                return (
                  <>
                    <UpdateTarifDialog
                      datas={item}
                      getWilayas={getWilayas}
                      UpdateTarif={UpdateTarif}
                      getWilayaDairas={getWilayaDairas}
                    />
                    <tr key={index}>
                      <th>{item.id}</th>
                      {/* <th>{item.price_desk}</th> */}
                      <th>{item.wilaya?.name}</th>
                      <th>{item.daira?.name}</th>
                      <th>
                        <span
                          className={
                            item.all_communes === true
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {item.all_communes === true ? "True" : "False"}
                        </span>
                      </th>
                      <th>{item.price_home} Da</th>
                      <th>
                        <div className="d-flex flex-row justify-between">
                          {/* <Link
                            data-bs-toggle="modal"
                            data-bs-target="#UpdataTarifModal"
                            className="me-3"
                          >
                            <FeatherIcon icon="edit" size={20} />
                          </Link> */}
                          <Link>
                            <FeatherIcon icon="trash" color="red" size={20} />
                          </Link>
                        </div>
                      </th>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <AddTarifDialog
        id={id}
        getWilayas={getWilayas}
        AddTarif={AddTarif}
        getWilayaDairas={getWilayaDairas}
        setData={setData}
      />
    </>
  );
};
export default DeliveryManAddTarifs;
