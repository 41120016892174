import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useYalidineHandle from "../../../../hooks/app/DeliveryCompany/yalidine/useYalidineHandle";
import Loader from "../../../../components/common/general/Loader";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../../components/table/Table";
import useSearch from "../../../../hooks/useSearch";

const ViewDeliveryCompanyYalidine = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState({});
  const [loadingSync, setLoadingSync] = useState(false);
  const { query, search, setQuery } = useSearch();
  const { getYalidineInformation, SyncTarifsFunction } = useYalidineHandle();
  useEffect(() => {
    getYalidineInformation(id, setLoadingData, setData);
  }, [id]);
  const SyncTafifs = (e) => {
    SyncTarifsFunction(id, setLoadingSync, setData);
  };
  return (
    <>
      <Breadcrumb
        title="Delivery Company Yalidine"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Delivery Company Yalidine"
        secondaryLink="/delivery/company"
        secondarytitle="Delivery Company"
      />
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="g-3 row">
                <div className="col-sm-5">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="card-title">Tarifs</div>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div>
                    {loadingSync ? (
                      <Link className="btn btn-primary">
                        <Loader />
                      </Link>
                    ) : (
                      <Link
                        className="btn btn-success"
                        onClick={(e) => SyncTafifs(e)}
                      >
                        <FeatherIcon
                          icon="refresh-cw"
                          className="ri-add-line align-bottom me-2"
                        />
                        Synchronize from Yalidine
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Wilaya</th>
                      <th>Is Deliverable</th>
                      <th>Price Home</th>
                      <th>Price Desk</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr>
                        <td>
                          <Loader />
                        </td>
                        <td>
                          <Loader />
                        </td>
                        <td>
                          <Loader />
                        </td>
                        <td>
                          <Loader />
                        </td>
                      </tr>
                    ) : null}
                    {data.tarifs?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>

                          <td>
                            {" "}
                            <span
                              className={
                                item.is_deliverable === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {item?.is_deliverable === true ? "True" : "False"}
                            </span>
                          </td>
                          <td>{item.price_home ?? 0} Da</td>
                          <td>{item.price_desk ?? 0} Da</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Yalidine Informations</div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title">Yalidine Datas</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewDeliveryCompanyYalidine;
