import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import { useState } from "react";
import InputQuantity from "../Components/InputQuantity";
import { AddFactureProductSchema } from "../../../../validation/app/facture/AddFactureProductSchema";
import { useParams } from "react-router-dom";

const UpdateFactureAddFactureLotDialog = ({
  productsDB,
  getUpdateFactureAddLotFunction,
  setData,
}) => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [children, setChildren] = useState(<></>);
  const [laoding, setLoading] = useState(<></>);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(AddFactureProductSchema),
  });
  const product_id = register("product_id");
  const HandleAdd = (data) => {
    getUpdateFactureAddLotFunction(id, data, setLoading, setData);
    setChildren(<></>);
    unregister("qty");
    unregister("purchase_price");
    unregister("items");
    setItems([]);
    setValue("product_id", null);
    setValue("date_expiration", null);
  };
  const HandleChangeProduct = (e) => {
    if (e.target.value == "") {
      setChildren(<></>);
    } else {
      const filteredProducts = productsDB.filter(
        (product) => product.id == e.target.value
      );
      setValue("product_name", filteredProducts[0].name);
      if (filteredProducts[0].items?.length != 0) {
        setItems(filteredProducts[0].items);
        setChildren(<></>);
        unregister("qty");
        unregister("purchase_price");
      } else {
        setChildren(<InputQuantity register={register} errors={errors} />);
        setItems([]);
        unregister("items");
      }
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="UpdateFactureAddLot"
        tabIndex="-1"
        aria-labelledby="UpdateFactureAddLotLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="UpdateFactureAddLotLabel">
                Add Product
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-2">
                  <label className="form-label">Products</label>
                  <select
                    id="product_id"
                    name="product_id"
                    className="form-control"
                    {...product_id}
                    onChange={(e) => {
                      product_id.onChange(e);
                      HandleChangeProduct(e);
                    }}
                  >
                    <option value="">Selectionner</option>
                    {productsDB?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "product_id")}
                </div>
                {items.length != 0 ? (
                  <div className="mb-2">
                    <div className="row mt-2">
                      <div className="col-lg-4">Item</div>
                      <div className="col-lg-3">Quantity</div>
                      <div className="col-lg-5">Purchase Price Per Unit</div>
                    </div>
                    {items.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <input
                            type="text"
                            value={item.id}
                            {...register(`items.${index}.id`)}
                            hidden
                          />
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              value={item.name}
                              readOnly
                              {...register(`items.${index}.name`)}
                            />
                          </div>
                          <div className="col-lg-3">
                            <input
                              type="number"
                              className="form-control"
                              id="qty"
                              placeholder="Quantity"
                              defaultValue={1}
                              {...register(`items.${index}.qty`)}
                            />
                          </div>
                          <div className="col-lg-5">
                            <input
                              type="number"
                              className="form-control"
                              id="purchase_price"
                              placeholder="Purchase price"
                              defaultValue={0}
                              step="0.01"
                              {...register(`items.${index}.purchase_price`)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {children}
                <div className="mb-2">
                  <label className="form-label">Date D'expiration</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_expiration"
                    {...register("date_expiration")}
                  />
                  {errorMessage(errors, "date_expiration")}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {laoding == true ? (
                    <button type="submit" className="btn btn-primary">
                      Loading
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success">
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateFactureAddFactureLotDialog;
