import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/auth/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../helpers/errorMessage";
import Loader from "../../components/common/general/Loader";
import { RegisterSchema } from "../../validation/auth/RegisterSchema";

const SignUp = () => {
  const { Register } = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(RegisterSchema),
  });
  const onSubmit = (data) => {
    Register(
      {
        ...data,
      },
      setLoading,
      setError
    );
  };
  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-7 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-3 pe-md-0">
                    <div className="auth-side-wrapper"></div>
                  </div>
                  <div className="col-md-9 ps-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <p className="noble-ui-logo logo-light d-block mb-2">
                        Track <span>COD</span>
                      </p>
                      <h5 className="text-muted fw-normal mb-4">
                        Welcome! Sign up now.
                      </h5>
                      <form
                        className="forms-sample"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label
                                htmlFor="first_name"
                                className="form-label"
                              >
                                First name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First name"
                                autoComplete="off"
                                {...register("first_name", { required: true })}
                              />
                              {errorMessage(errors, "first_name")}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label htmlFor="last_name" className="form-label">
                                Last name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last name"
                                autoComplete="off"
                                {...register("last_name", { required: true })}
                              />
                              {errorMessage(errors, "last_name")}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label htmlFor="username" className="form-label">
                                Username
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Username"
                                autoComplete="off"
                                {...register("username", { required: true })}
                              />
                              {errorMessage(errors, "username")}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="Email" className="form-label">
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                autoComplete="off"
                                {...register("email", { required: true })}
                              />
                              {errorMessage(errors, "email")}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="phone" className="form-label">
                                Phone number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone number"
                                autoComplete="off"
                                {...register("phone", { required: true })}
                              />
                              {errorMessage(errors, "phone")}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="password" className="form-label">
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                autoComplete="off"
                                {...register("password", { required: true })}
                              />
                              {errorMessage(errors, "password")}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label
                                htmlFor="password_confirmation"
                                className="form-label"
                              >
                                Password Confirmation
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password Confirmation"
                                autoComplete="off"
                                {...register("password_confirmation", {
                                  required: true,
                                })}
                              />
                              {errorMessage(errors, "password_confirmation")}
                            </div>
                          </div>
                        </div>

                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="authCheck"
                            {...register("accept_condition", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="authCheck"
                          >
                            I Agree to the Terms & Conditions
                          </label>
                          {errorMessage(errors, "accept_condition")}
                        </div>
                        <div>
                          {loading ? (
                            <button
                              type="button"
                              disabled
                              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
                            >
                              <Loader />
                            </button>
                          ) : (
                            <button
                              onClick={handleSubmit(onSubmit)}
                              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
                            >
                              Sign Up
                            </button>
                          )}
                        </div>
                        <Link to="/login" className="d-block mt-3 text-muted">
                          Already have account? SignIn now
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
