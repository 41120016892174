import axiosInstance from "../../Axios/axios";

const getGoogleAuthLinkApi = async () => {
  return await axiosInstance.get(`google`);
};

const getRegistreToken = async (token,state) => {
  return await axiosInstance.post(`google/login?state=${state}&code=${token}`);
};

const getGoogleSheetsApi = async (id) => {
  return await axiosInstance.get(`google/sheets/${id}`);
};

const getSheetFilesApi = async (google_account,sheet_id) => {
  return await axiosInstance.get(`google/sheets/${google_account}/files/${sheet_id}`);
};

const getSheetFileRowsApi = async (google_account,sheet_id,file_id) => {
  return await axiosInstance.get(`google/sheets/${google_account}/files/${sheet_id}/file/${file_id}/rows`);
};

const getGoogleAccountApi = async () => {
  return await axiosInstance.get(`google/account`);
};

const getAllGoogleSheetsApi = async () => {
  return await axiosInstance.get(`google/sheets/get_sheets`);
};

const getCreateGoogleSheetApi = async (data) => {
  return await axiosInstance.post(`google/sheets/set_order`,data);
};

const getGoogleSheetDetailsApi = async (id) => {
  return await axiosInstance.get(`google/sheets/get_sheets/${id}`);
};

const getUpdateGoogleSheetApi = async (id,data) => {
  return await axiosInstance.post(`google/sheets/get_sheets/${id}`,data);
};

const getGoogleLogoutApi = async (id) => {
  return await axiosInstance.post(`google/sheets/${id}/logout`);
};

export {
  getGoogleAuthLinkApi,
  getRegistreToken,
  getGoogleSheetsApi,
  getGoogleAccountApi,
  getSheetFilesApi,
  getSheetFileRowsApi,
  getAllGoogleSheetsApi,
  getCreateGoogleSheetApi,
  getGoogleSheetDetailsApi,
  getUpdateGoogleSheetApi,
  getGoogleLogoutApi
};
