const StockProductQtyInput = ({ register,qty }) => {
  return (
    <>
    <div className="row">
      <div className="col-lg-6">
        <label htmlFor="qty" className="form-label">
          Quantity Disponible
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="Quantity"
          defaultValue={qty}
          readOnly
        />
      </div>
      <div className="col-lg-6">
        <label htmlFor="qty" className="form-label">
          Quantity Fournis
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="Quantity"
          name="qty"
          defaultValue={0}
          {...register("qty")}
        />
      </div>
    </div>
    </>
  );
};
export default StockProductQtyInput;
