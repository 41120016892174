import { useQuery } from "@tanstack/react-query";
import { getAgentsApi } from "../../../apis/app/agents/getAgentsApis";

export const useAgents = (page) => {
  const { data: Datas, isLoading: loading } = useQuery({
    queryKey: ["agents", page],
    queryFn: () => getAgentsApi(page),
  });
  return { Datas, loading };
};
