import { useQuery } from "@tanstack/react-query";
import { getAllFacturesApi } from "../../../apis/app/factures/getFactureApis";

export const useFactures = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["factures", page],
    queryFn: () => getAllFacturesApi(page),
  });
  return { Datas, loadingDatas };
};
