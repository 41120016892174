import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import UpdateDeliveryAddressDialog from "./../UpdateDeliveryAddressDialog";
import AddAddressDialog from "../AddAddressDialog";

const DeliveryManAddress = ({ dataMan, wilayas,getWilayas,getWilayaDairas,AddDeliveryAddress }) => {
  const { id } = useParams();
  return (
    <>
      <div className="g-3 row">
        <div className="col-sm-5">
          <h6 className="card-title mt-2">Address</h6>
        </div>
        <div className="col-sm-auto ms-auto">
          <div>
            <Link
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#AddAddressModal"
            >
              <FeatherIcon
                icon="plus"
                className="ri-add-line align-bottom me-1"
              />
              Add Address
            </Link>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Address</th>
              <th>Commune</th>
              <th>Wilaya</th>
              <th>Daira</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataMan?.address?.map((item, index) => {
              return (
                <>
                  <UpdateDeliveryAddressDialog datas={item} wilayas={wilayas} />
                  <tr key={index}>
                    <th>{item.address}</th>
                    <th>{item.commune}</th>
                    <th>{item.wilaya.name}</th>
                    <th>{item.daira.name}</th>
                    <th>
                      <div className="d-flex flex-row justify-between">
                        <Link
                          data-bs-toggle="modal"
                          data-bs-target="#UpdateDeliveryAddressModal"
                          className="me-3"
                        >
                          <FeatherIcon icon="edit" size={20} />
                        </Link>
                        <Link>
                          <FeatherIcon icon="trash" color="red" size={20} />
                        </Link>
                      </div>
                    </th>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <AddAddressDialog
        id={id}
        getWilayas={getWilayas}
        getWilayaDairas={getWilayaDairas}
        AddDeliveryAddress={AddDeliveryAddress}
      />
    </>
  );
};

export default DeliveryManAddress;
