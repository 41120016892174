import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useState } from "react";
import Table from "../../../components/table/Table";
import { useGetAllProducts } from "../../../hooks/app/products/useGetAllProducts";

const Products = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { ProductsData, loadingProducts } = useGetAllProducts(page);
  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category?.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => {
        return (
          <span>
            {row?.items?.length === 0 ? row.price+" Da" : row?.items?.map((item,index)=>{
              return (<span key={index}> {item.name+" = "+item.price+ " Da"} <br/></span>)
            })}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => {
        return (
          <span>
            {row?.items?.length === 0 ? row.qty: row?.items?.map((item,index)=>{
              return (<span key={index}> {item.name+" = "+item.qty} <br/></span>)
            })}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={
                row?.is_primary !== 1 && `/products/update/${row.id}`
              }
              title="update product"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete product">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Products"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Products"
      />
       <Table
        isLoading={loadingProducts}
        query={query}
        setQuery={setQuery}
        titleLabel="Product"
        columns={columns}
        data={search(ProductsData?.data?.data, ["name"], query)}
        meta={ProductsData?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default Products;
