import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import "./notification.css";

const NotificationList = ({ items }) => {
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="notificationDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FeatherIcon icon="bell" />
        <div className="indicator">
          <div className="circle"></div>
        </div>
      </a>
      <div className="dropdown-menu p-0" aria-labelledby="notificationDropdown">
        <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
          <p>0 New Notifications</p>
          <a href="javascript:;" className="text-muted">
            Clear all
          </a>
        </div>
        <div className="p-1">
          {/* <a
            href="javascript:;"
            className="dropdown-item d-flex align-items-center py-2"
          >
            <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
              <i className="icon-sm text-white" data-feather="gift"></i>
            </div>
            <div className="flex-grow-1 me-2">
              <p>New Order Recieved</p>
              <p className="tx-12 text-muted">30 min ago</p>
            </div>
          </a> */}
        </div>
        <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
          <a href="javascript:;">View all</a>
        </div>
      </div>
    </li>
  );
};

export default NotificationList;
