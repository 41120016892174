import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const RegisterSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  username: yup.string().required("Username is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  password_confirmation: yup
    .string()
    .required("Confirmation password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  accept_condition: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});
