import * as yup from "yup";

export const CreateYalidineSchema = yup.object().shape({
  api_token: yup.string().required("Api Token is required"),
  api_id: yup.string().required("Api ID is required"),
  status: yup
    .boolean()
    .typeError("Status is required")
    .oneOf([true, false], "Status is required"),
  default: yup
    .boolean()
    .typeError("Default is required")
    .oneOf([true, false], "Default is required"),
});
