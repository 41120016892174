import React from "react";
import { Link } from "react-router-dom";

const Maintenance = () => {
  return (
    <div className="page-content d-flex align-items-center justify-content-center">
      <div className="row w-100 mx-0 auth-page">
        <div className="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <img
            src={require("../assets/img/others/404.svg").default}
            className="img-fluid mb-2"
            alt="404"
          />
          {/* <h1 className="fw-bolder mb-22 mt-2 tx-80 text-muted">404</h1> */}
          <h4 className="mb-2">Service en maintenance</h4>
          <Link to="/">Back to home</Link>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
