import { useEffect, useState } from "react";
import { AddAddressSchema } from "../../../../validation/app/deliveryMan/AddAddressSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";

const AddAddressDialog = ({
  id,
  getWilayas,
  getWilayaDairas,
  AddDeliveryAddress,
}) => {
  const [loading, setLoading] = useState(false);

  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState([]);

  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  useEffect(() => {
    getWilayas(setWilayas, setWilayasLoading);
  }, [id]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(AddAddressSchema),
  });
  const wilaya_id = register("wilaya_id", { required: true });
  const getDairas = (e) => {
    getWilayaDairas(e.target.value, setDairas, setLoadingDairas);
  };
  const onSubmit = (data) => {
    AddDeliveryAddress(id,data,setLoading);
  };
  return (
    <div
      className="modal fade"
      id="AddAddressModal"
      tabindex="-1"
      aria-labelledby="AddAddressModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="AddAddressModalLabel">
              Add New Address
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <label htmlFor="address" className="form-label">
                Wilayas
              </label>
              <select
                id="wilaya_id"
                name="wilaya_id"
                className="form-control"
                {...wilaya_id}
                onChange={(e) => {
                  wilaya_id.onChange(e);
                  getDairas(e);
                }}
              >
                <option value="">Selectionner</option>
                {wilayas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "wilaya_id")}

              <label htmlFor="address" className="form-label">
                Dairas
              </label>
              <select
                id="daira_id"
                name="daira_id"
                className="form-control"
                {...register("daira_id")}
              >
                <option value="">Selectionner</option>
                {Dairas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "daira_id")}
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                autoComplete="off"
                placeholder="Address"
                {...register("address")}
              />
              {errorMessage(errors, "address")}
              <label htmlFor="commune" className="form-label">
                Commune
              </label>
              <input
                type="text"
                className="form-control"
                id="commune"
                autoComplete="off"
                placeholder="Commune"
                {...register("commune")}
              />
              {errorMessage(errors, "commune")}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddAddressDialog;
