import ProtectedRoute from "./helpers/ProtectedRoute";
import CreateLeadAgent from "./pages/agents/CreateLeadAgent";
import ViewLeadAgent from "./pages/agents/ViewLeadAgent";
import MyProfile from "./pages/app/Profile/MyProfile";
import NotFound from "./pages/NotFound";

const routeAgent = (isLoggedIn) => [
  {
    path: "/",
    element: ProtectedRoute(isLoggedIn, ViewLeadAgent),
  },
  {
    path: "/create_lead",
    element: ProtectedRoute(isLoggedIn, CreateLeadAgent),
  },
  // My Profile
  {
    path: "/profile",
    element: ProtectedRoute(isLoggedIn, MyProfile),
  },
  // Not Found
  {
    path: "*",
    element: <NotFound />,
  },
];
export { routeAgent };
