import { useQuery } from "@tanstack/react-query";
import { getAllStatusApi } from "../../../apis/app/order_status/getOrderStatusApis";

export const useGetAllStatus = (page) => {
  const { data: StatusData, isLoading: loadingStatus } = useQuery({
    queryKey: ["status", page],
    queryFn: () => getAllStatusApi(page),
  });
  return { StatusData, loadingStatus };
};
