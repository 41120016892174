import { errorMessage } from "../../../../helpers/errorMessage";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const FactureProductsTable = ({ products, DeleteProduct }) => {
  return (
    <div className="row">
      {products?.length == 0 ? (
        <div className="alert alert-info mt-2" role="alert">
          No Products
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Purchase Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products?.map((item, index) => {
                return (
                  <tr>
                    <th>{item.product_name}</th>
                    {item.hasOwnProperty("items") ? (
                      <>
                      <th>
                        {item.items.map((temp_item, i) => {
                          return (
                            <>
                              <span key={i}>
                                {temp_item.name + " =" + temp_item.qty}{" "}
                              </span>
                              <br />
                            </>
                          );
                        })}
                      </th>
                       <th>
                       {item.items.map((temp_item, i) => {
                         return (
                           <>
                             <span key={i}>
                               {temp_item.purchase_price} {"DA"}
                             </span>
                             <br />
                           </>
                         );
                       })}
                     </th>
                      </>
                    ) : (
                      <>
                        <th>{item.qty}</th>
                        <th>{item.purchase_price} Da</th>
                      </>
                    )}
                    <th>
                      <div className="d-flex flex-row justify-between">
                        <Link onClick={(e) => DeleteProduct(index)}>
                          <FeatherIcon icon="trash" color="red" size={20} />
                        </Link>
                      </div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default FactureProductsTable;
