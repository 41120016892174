import { errorMessage } from "../../../helpers/errorMessage";

const AddProductItems = ({ register, errors, items }) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <>
            <div className="row">
              <div className="col-lg-4">Variant</div>
              <div className="col-lg-4">Qty Reste</div>
              <div className="col-lg-4">Qty</div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder={item.name}
                  readOnly
                />
                <input
                  type="text"
                  className="form-control"
                  value={item.id}
                  {...register(`items.${index}.id`)}
                  hidden
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={item.qty_reste}
                  id="qty"
                  autocomplete="off"
                  placeholder="Qty Reste"
                  readOnly
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="number"
                  className="form-control"
                  defaultValue="0"
                  id="qty"
                  autocomplete="off"
                  placeholder="Qty"
                  {...register(`items.${index}.qty`)}
                />
                {errorMessage(errors, "qty")}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
export default AddProductItems;