import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const importFromSheetSchema = yup.object().shape({
  name: yup.string().required("Store name is required"),
  start_row: yup
    .number()
    .typeError("Start Row must be a number")
    .required("Please provide Start Row.")
    .min(2, "Min start rows is 2"),
  status: yup
    .boolean()
    .typeError("Status is required")
    .oneOf([true, false], "Name is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  address: yup.string().required("Address is required"),
  wilaya: yup.string().required("Wilaya is required"),
  daira: yup.string().required("Daira is required"),
  source: yup.string().required("Source is required"),
  source_value: yup.string().required("Source Value is required"),
  note: yup.string().required("Note is required"),
  delivery_type: yup.string().required("Delivery Type is required"),
  // delivery_price: yup.string(),
  product: yup.string().required("Product is required"),
  product_qty: yup.string().required("Product quantity is required"),
  total: yup.string()
});
