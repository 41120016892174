import axiosInstance from "../../Axios/axios";
import axiosInstancePart from "../../Axios/axios_part";

const UpdatePasswordApi = async (data) => {
  return await axiosInstance.post(`profile/update_password`, data);
};
const UpdateMainInformationApi = async (data) => {
  return await axiosInstance.post(`profile/update_informations`, data);
};

const AddAddressProfileApi = async (data) => {
  return await axiosInstance.post(`profile/add_address`, data);
};

const DeleteAddressApi = async (id) => {
  return await axiosInstance.delete(`profile/add_address/${id}`);
};

export { UpdatePasswordApi, UpdateMainInformationApi,AddAddressProfileApi,DeleteAddressApi };
