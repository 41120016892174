import { useEffect, useState } from "react";
import { addTarifSchema } from "../../../../validation/app/deliveryMan/addTarifSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import Loader from "../../../../components/common/general/Loader";
import AddTarifAllCommunes from "./UpdateComponents/AddTarifAllCommunes";
import Toast from "../../../../helpers/toast";
import AllTarifSelectedCommunes from "./UpdateComponents/AllTarifSelectedCommunes";

const AddTarifDialog = ({ id, getWilayas, AddTarif, getWilayaDairas,setData }) => {
  const [loading, setLoading] = useState(false);

  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState([]);

  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  const [children, setChildren] = useState(<></>);

  useEffect(() => {
    getWilayas(setWilayas, setWilayasLoading);
  }, [id]);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
    unregister,
  } = useForm({
    resolver: yupResolver(addTarifSchema),
  });
  const getDairas = (e) => {
    getWilayaDairas(id,e.target.value, setDairas, setLoadingDairas);
    setValue("delivery_communes", undefined);
    setChildren(<></>);
  };
  const wilaya_id = register("wilaya_id", { required: true });
  const delivery_communes = register("delivery_communes");

  const onSubmit = (data) => {
    AddTarif(id, data, setLoading,setData);
    setValue("daira_ids", []);
    setValue("delivery_communes", undefined);
    setValue("wilaya_id", undefined);
    setValue("price_home", 0);
    setChildren(<></>);
  };

  const HandleChangeCommunes = (e) => {
    switch (e.target.value) {
      case "all":
        setChildren(
          <AddTarifAllCommunes register={register} errors={errors} />
        );
        break;
      case "part":
        if (
          getValues("wilaya_id") == undefined ||
          getValues("wilaya_id") == ""
        ) {
          Toast("error", "Please Whoose Wilaya first");
        } else {
          setChildren(
            <AllTarifSelectedCommunes
              Dairas={Dairas}
              register={register}
              errors={errors}
              control={control}
            />
          );
        }
        break;
      default:
        setChildren(<></>);
        break;
    }
  };
  return (
    <div
      className="modal fade"
      id="AddTarifDialog"
      tabindex="-1"
      aria-labelledby="AddTarifDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="AddTarifDialogLabel">
              Add New Tarif
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <label htmlFor="address" className="form-label">
                Wilayas
              </label>
              <select
                id="wilaya_id"
                name="wilaya_id"
                className="form-control"
                {...wilaya_id}
                onChange={(e) => {
                  wilaya_id.onChange(e);
                  getDairas(e);
                }}
              >
                <option value="">Selectionner</option>
                {wilayas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "wilaya_id")}
              <label className="form-label">Communes Delivery</label>
              <select
                className="form-control"
                {...delivery_communes}
                onChange={(e) => {
                  delivery_communes.onChange(e);
                  HandleChangeCommunes(e);
                }}
              >
                <option value="">Selectionner</option>
                <option value="all">All communes</option>
                <option value="part">Selected Communes</option>
              </select>
              {children}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {loading === true ? (
                <button type="submit" className="btn btn-primary">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddTarifDialog;
