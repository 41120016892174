import { errorMessage } from "../../../../helpers/errorMessage";

const AllWilayaUpdate = ({defaultValue,register}) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="mb-3">
          <label for="discount_amount" className="form-label">
            Discount Amount
          </label>
          <input
            type="number"
            className="form-control"
            id="discount_amount"
            placeholder="Discount Amount"
            {...register("discount_amount")}
            defaultValue={defaultValue ? defaultValue : 0}
          />
          {/* {errorMessage(errors, "discount_amount")} */}
        </div>
      </div>
    </>
  );
};
export default AllWilayaUpdate;
