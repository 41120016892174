import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";

const ViewDeliveryMan = () => {
  const { id } = useParams();
  return (
    <>
      <Breadcrumb
        title="Delivery Man"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Delivery man"
        secondaryLink="/delivery/man"
        secondarytitle="Delivery Man"
      />
    </>
  );
};
export default ViewDeliveryMan;
