import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/errorMessage";
import { useState } from "react";
import InputQuantity from "./Components/InputQuantity";
import { AddFactureProductSchema } from "../../../validation/app/facture/AddFactureProductSchema";

const CreateFactureAddProductDialog = ({ setProducts, productsDB,products }) => {
  const [items, setItems] = useState([]);
  const [children, setChildren] = useState(<></>);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister
  } = useForm({
    resolver: yupResolver(AddFactureProductSchema),
  });
  const product_id = register("product_id");
  const HandleChangeProduct = (e) => {
    if (e.target.value === "") {
      setChildren(<></>);
    } else {
      const filteredProducts = productsDB.filter(
        (product) => product.id == e.target.value
      );
      setValue('product_name',filteredProducts[0].name);
      if (filteredProducts[0].items?.length != 0) {
        setItems(filteredProducts[0].items);
        setChildren(<></>);
        unregister("qty");
        unregister("purchase_price");
      } else {
        setChildren(<InputQuantity register={register} errors={errors}/>);
        setItems([]);
        unregister("items");
      }
    }
  };
  const HandleAdd = (data) => {
    setProducts(products=>[...products,data]);
  };
  return (
    <>
      <div
        className="modal fade"
        id="FactureAddProduct"
        tabIndex="-1"
        aria-labelledby="FactureAddProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="FactureAddProductLabel">
                Add Product
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-2">
                  <label className="form-label">Products</label>
                  <select
                    id="product_id"
                    name="product_id"
                    className="form-control"
                    {...product_id}
                    onChange={(e) => {
                      product_id.onChange(e);
                      HandleChangeProduct(e);
                    }}
                  >
                    <option value="">Selectionner</option>
                    {productsDB?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "product_id")}
                </div>
                {items.length != 0 ? (
                  <div className="mb-2">
                    <div className="row mt-2">
                      <div className="col-lg-4">Item</div>
                      <div className="col-lg-4">Quantity</div>
                      <div className="col-lg-4">Purchase Price Unit</div>
                    </div>
                    {items.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <input
                            type="text"
                            value={item.id}
                            {...register(`items.${index}.id`)}
                            hidden
                          />
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              value={item.name}
                              readOnly
                              {...register(`items.${index}.name`)}
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="number"
                              className="form-control"
                              id="qty"
                              placeholder="Quantity"
                              defaultValue={0}
                              {...register(`items.${index}.qty`)}
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              id="purchase_price"
                              placeholder="Purchase price"
                              defaultValue={0}
                              {...register(`items.${index}.purchase_price`)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {children}
                <div className="mb-2">
                  <label className="form-label">Date D'expiration</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_expiration"
                    {...register("date_expiration")}
                  />
                  {errorMessage(errors, "date_expiration")}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateFactureAddProductDialog;
