export const CreateProductSetErrors = (data, setError) => {
  if (data.hasOwnProperty("name")) {
    data.name.map((item) => {
      setError("name", {
        type: "submit",
        message: item,
      });
    });
  }
};
