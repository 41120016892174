import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import { useEffect, useState } from "react";
import { createDeliveryManSchema } from "../../../../validation/app/deliveryMan/createDeliveryManSchema";
import { addTarifSchema } from "../../../../validation/app/deliveryMan/addTarifSchema.js";
import Loader from "../../../../components/common/general/Loader";
import ImageDropzone from "../../../../components/common/general/ImageDropzone";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";

const CreateDeliveryMan = () => {
  const [loading, setLoading] = useState(false);

  const [wilayas, setWilayas] = useState([]);
  const [loadingWilayas, setLoadingWilayas] = useState(false);

  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  const { getWilayas, getWilayaDairas, createDeliveryMan } =
    useDeliveryManFunctions();

  useEffect(() => {
    getWilayas(setWilayas, setLoadingWilayas);
  }, []);
  const getDairas = (e) => {
    getWilayaDairas(e.target.value, setDairas, setLoadingDairas);
  };
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(createDeliveryManSchema),
  });
  const wilaya_id = register("wilaya_id", { required: true });
  const photo = watch("image");
  const onSubmit = (data) => {
    createDeliveryMan(
      {
        ...data,
      },
      setLoading,
      setError
    );
  };
  return (
    <>
      <Breadcrumb
        title="Delivery Man"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create Delivery Man"
        secondaryLink="/delivery/man"
        secondarytitle="Delivery Man"
      />
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Main informations</h6>
          <form
            className="forms-sample"
            key={1}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    autoComplete="off"
                    placeholder="First name"
                    {...register("first_name")}
                  />
                  {errorMessage(errors, "first_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    autoComplete="off"
                    placeholder="Last name"
                    {...register("last_name")}
                  />
                  {errorMessage(errors, "last_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    autoComplete="off"
                    placeholder="Username"
                    {...register("username")}
                  />
                  {errorMessage(errors, "username")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    autoComplete="off"
                    placeholder="Email"
                    {...register("email")}
                  />
                  {errorMessage(errors, "email")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    autoComplete="off"
                    placeholder="Phone number"
                    {...register("phone")}
                  />
                  {errorMessage(errors, "phone")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="passowrd"
                    placeholder="Password"
                    {...register("password")}
                  />
                  {errorMessage(errors, "password")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="password_confirmation" className="form-label">
                    Password Confirmation
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_confirmation"
                    placeholder="Password Confirmation"
                    {...register("password_confirmation")}
                  />
                  {errorMessage(errors, "password_confirmation")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Wilaya</label>
                  <select
                    id="wilaya_id"
                    name="wilaya_id"
                    className="form-control"
                    {...wilaya_id}
                    onChange={(e) => {
                      wilaya_id.onChange(e);
                      getDairas(e);
                    }}
                  >
                    <option value="">Selectionner</option>
                    {wilayas?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "wilaya_id")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="daira_id" className="form-label">
                    Daira
                  </label>
                  <select
                    name="daira_id"
                    id="daira_id"
                    className="form-control"
                    {...register("daira_id")}
                  >
                    <option value="">Selectionner</option>
                    {Dairas?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "daira_id")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    autoComplete="off"
                    placeholder="Address"
                    {...register("address")}
                  />
                  {errorMessage(errors, "address")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="commune" className="form-label">
                    Commune
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="commune"
                    autoComplete="off"
                    placeholder="Commune"
                    {...register("commune")}
                  />
                  {errorMessage(errors, "commune")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-1">
                  <ImageDropzone
                    placeholder="Drag and drop or click to select image"
                    photos={photo}
                    name="image"
                    setValue={setValue}
                    multiple={false}
                    previewCol={5}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-primary me-2">
                <Loader />
              </button>
            ) : (
              <button type="submit" className="btn btn-success me-2">
                Create Delivery Man
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateDeliveryMan;
