import Breadcrumb from "../../../components/common/Breadcrumb";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAllFunctionOrderLeads from "../../../hooks/app/orderLeads/useAllFunctionOrderLeads";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/errorMessage";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ConfirmLeadAddProductDialog from "./ConfirmLeadAddProductDialog";
import Loader from "../../../components/common/general/Loader";
import { ConfirmeLeadSchema } from "../../../validation/app/leads/ConfirmeLeadSchema";
import Toast from "../../../helpers/toast";

const ConfirmeLead = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});

  const [customersData, setcustomersData] = useState([]);
  const [wilayas, setWilayas] = useState([]);
  const [dairas, setDairas] = useState([]);
  const [sources, setSources] = useState([]);
  const [deliveryDatas, setDeliveryDatas] = useState([]);
  const [products, setProducts] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [deliveryTypesLoading, setDeliveryTypesLoading] = useState([]);
  const [loading, setLoading] = useState(false);
  const [DeliveryMode, setDeliveryMode] = useState([]);
  const {
    getOrderLeadDetails,
    getStoreCustomers,
    getWilayas,
    getWilayaDairas,
    getLeadSources,
    getDeliveryTypeDatas,
    getConfirmeLead,
    getDeliveryTypes,
  } = useAllFunctionOrderLeads();

  useEffect(() => {
    getOrderLeadDetails(id, setLoadingData, setData);
    getStoreCustomers(id, setcustomersData);
    getWilayas(setWilayas);
    getLeadSources(setSources);
    getDeliveryTypes(setDeliveryTypes, setDeliveryTypesLoading);
  }, [id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ConfirmeLeadSchema),
    mode: "onBlur",
  });

  const user_id = register("user_id");
  const wilaya_id = register("wilaya_id");
  const delivery_type = register("delivery_type");
  const delivery_data = register("delivery_data");

  const HandleChangeWilaya = (e) => {
    getWilayaDairas(e.target.value, setDairas);
  };
  const HandleDeliveryType = (e) => {
    if (e.target.value !== "") {
      getDeliveryTypeDatas(e.target.value, setDeliveryDatas);
      if (e.target.value == 1) {
        setDeliveryMode([
          {
            value: 1,
            name: "Home",
          },
        ]);
      }
      if (e.target.value == 2) {
        setDeliveryMode([
          {
            value: 1,
            name: "Home",
          },
          {
            value: 2,
            name: "Desk",
          },
        ]);
      }
    } else {
      setDeliveryDatas([]);
      setDeliveryMode([]);
    }
  };
  const DeleteProduct = (index) => {
    setProducts((products) => {
      return products.filter((_, i) => i !== index);
    });
  };
  const onSubmit = (data) => {
    if (products.length === 0) {
      Toast("error", "Please add products first, min 1 product");
    } else {
      data["products"] = products;
      getConfirmeLead(id, data, setLoading);
    }
  };

  return (
    <>
      <Breadcrumb
        title="Leads"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Confirme Lead"
        secondaryLink="/leads"
        secondarytitle="Leads"
      />
      {loadingData ? (
        <></>
      ) : (
        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="card-title">Order Information</div>
                <form
                  className="forms-sample"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="user_id" className="form-label">
                          Utilisateur
                        </label>
                        <select
                          name="user_id"
                          id="user_id"
                          className="form-control"
                          // {...register("user_id")}
                          {...user_id}
                          onChange={(e) => {
                            user_id.onChange(e);
                          }}
                        >
                          <option value="">Selectionner</option>
                          <option value="new">Create New</option>
                          {customersData?.map((customer, index) => {
                            return (
                              <option key={index} value={customer.id}>
                                {customer.first_name +
                                  " " +
                                  customer.last_name +
                                  " (" +
                                  customer.phone +
                                  ")"}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "user_id")}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={data.address}
                          id="address"
                          autocomplete="off"
                          placeholder="Address"
                          {...register("address")}
                        />
                        {errorMessage(errors, "address")}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="wilaya_id" className="form-label">
                          Wilaya
                        </label>
                        <select
                          name="wilaya_id"
                          id="wilaya_id"
                          className="form-control"
                          // {...register("wilaya_id")}
                          {...wilaya_id}
                          onChange={(e) => {
                            wilaya_id.onChange(e);
                            HandleChangeWilaya(e);
                          }}
                        >
                          <option value="">Selectionner</option>
                          {wilayas?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "wilaya_id")}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="daira_id" className="form-label">
                          Daira
                        </label>
                        <select
                          name="daira_id"
                          id="daira_id"
                          className="form-control"
                          {...register("daira_id")}
                        >
                          <option value="">Selectionner</option>
                          {dairas?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "daira_id")}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="source_id" className="form-label">
                          Source
                        </label>
                        <select
                          name="source_id"
                          id="source_id"
                          className="form-control"
                          {...register("source_id")}
                        >
                          <option value="">Selectionner</option>
                          {sources?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "source_id")}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label for="Source value" className="form-label">
                          Source value
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={data.source_value}
                          id="source_value"
                          autocomplete="off"
                          placeholder="source_value"
                          {...register("source_value")}
                        />
                        {errorMessage(errors, "source_value")}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label for="note" className="form-label">
                          Note
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={data.note}
                          id="note"
                          autocomplete="off"
                          placeholder="note"
                          {...register("note")}
                        />
                        {errorMessage(errors, "note")}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label for="delivery_type" className="form-label">
                          Delivery Type
                        </label>
                        <select
                          name="delivery_type"
                          id="delivery_type"
                          className="form-control"
                          {...delivery_type}
                          onChange={(e) => {
                            delivery_type.onChange(e);
                            HandleDeliveryType(e);
                          }}
                        >
                          <option value="">
                            {deliveryTypesLoading
                              ? "Loading...."
                              : "Selectionner"}
                          </option>
                          {deliveryTypes?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "delivery_type")}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label for="delivery_data" className="form-label">
                          Delivery Company / Man
                        </label>
                        <select
                          name="delivery_data"
                          id="delivery_data"
                          className="form-control"
                          {...register("delivery_data")}
                        >
                          <option value="">Selectionner</option>
                          {deliveryDatas?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorMessage(errors, "delivery_data")}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label for="delivery_mode" className="form-label">
                          Delivery To:
                        </label>
                        <select
                          name="delivery_mode"
                          id="delivery_mode"
                          className="form-control"
                          {...register("delivery_mode")}
                        >
                          <option value="">Selectionner</option>
                          {DeliveryMode?.map((item, index) => {
                            return (
                              <option value={item.value}>{item.name}</option>
                            );
                          })}
                          {/* <option value={1}>Home</option>
                          <option value={2}>Stop Desk</option> */}
                        </select>
                        {errorMessage(errors, "delivery_mode")}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <button type="submit" className="btn btn-success me-2">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success me-2">
                      Confirme Lead
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <div className="col-lg-12">
                  <button
                    className="btn btn-success me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#LeadAddProduct"
                  >
                    Add Product
                  </button>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((item, index) => {
                          return (
                            <tr>
                              <th>{item.product_id}</th>
                              <th>{item.qty}</th>
                              <th>
                                <div className="d-flex flex-row justify-between">
                                  <Link onClick={(e) => DeleteProduct(index)}>
                                    <FeatherIcon
                                      icon="trash"
                                      color="red"
                                      size={20}
                                    />
                                  </Link>
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="card-title">Lead Information</div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">First name & Last name</p>
                      <p className="tx-18">
                        {data.first_name + " " + data.last_name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">
                        Phone & Phone secondary
                      </p>
                      <p className="tx-18">
                        {data.phone + " / " + data.phone_secondary}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">Email</p>
                      <p className="tx-18">{data.email}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">
                        Address - Wilaya - Daira
                      </p>
                      <p className="tx-18">
                        {data.address +
                          " - " +
                          data.wilaya +
                          " - " +
                          data.daira}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">Source - Source value</p>
                      <p className="tx-18">
                        {data.source + " - " + data.source_value}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">Delivery type</p>
                      <p className="tx-18">{data.delivery_type}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">Note</p>
                      <p className="tx-18">{data.note}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                  <div className="d-flex align-items-center hover-pointer">
                    <div className="ms-2">
                      <p className="tx-16 text-muted">Product & Qty</p>
                      {data.items?.map((item, index) => {
                        return (
                          <div key={index}>
                            <span className="text-muted">Product: </span>{" "}
                            <span className="tx-">{item.product}</span>{" "}
                            <span className="text-muted">Qty: </span>{" "}
                            <span className="tx-">{item.qty}</span>{" "}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmLeadAddProductDialog
            setProducts={setProducts}
            products={products}
          />
        </div>
      )}
    </>
  );
};
export default ConfirmeLead;
