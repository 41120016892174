import { useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import useCreateSupplier from "../../../hooks/app/suppliers/useCreateSupplier";
import { CreateSupplierSchema } from "../../../validation/app/suppliers/CreateSupplierSchema";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Loader from "../../../components/common/general/Loader";

const CreateSupplier = () => {
  const [loading, setLoading] = useState(false);
  const { CreateSupplier } = useCreateSupplier();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(CreateSupplierSchema),
    mode:"all",
  });
  const photo = watch("image");
  const onSubmit = (data) => {
    CreateSupplier(
      {
        ...data,
      },
      setLoading,
      setError
    );
  };
  return (
    <>
      <Breadcrumb
        title="Suppliers"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create"
        secondaryLink="/suppliers"
        secondarytitle="Suppliers"
      />
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Create Supplier</h6>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="first_name" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="First name"
                    {...register("first_name")}
                  />
                  {errorMessage(errors, "first_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="last_name" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Last name"
                    {...register("last_name")}
                  />
                  {errorMessage(errors, "last_name")}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label for="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    {...register("username")}
                  />
                  {errorMessage(errors, "username")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="email" className="form-label">
                    Address Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Address Email"
                    {...register("email")}
                  />
                  {errorMessage(errors, "email")}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Phone"
                    {...register("phone")}
                  />
                  {errorMessage(errors, "phone")}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <ImageDropzone
                    placeholder="Drag and drop or click to select project image"
                    photos={photo}
                    name="image"
                    setValue={setValue}
                    multiple={false}
                    previewCol={5}
                    errors={errors}
                    label="Supplier Avatar"
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-primary me-2">
                <Loader />
              </button>
            ) : (
              <button type="submit" className="btn btn-success me-2">
                Create Supplier
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateSupplier;
