import * as yup from "yup";

export const ResetPasswordSchema = yup.object().shape({
  username: yup.string().required("Username, Email or Phone is required"),
  token: yup.string().min(6).max(6).required("Token Phone is required"),
  password: yup
  .string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters"),
  password_confirmation: yup
  .string()
  .required("Confirmation password is required")
  .oneOf([yup.ref("password"), null], "Passwords must match"),
});
