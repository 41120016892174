import { useQuery } from "@tanstack/react-query";
import { getDeliveryManStockApi } from "../../../apis/app/delivery_mans/useDeliveryManApis";

export const useDeliveryManStock = (page,id) => {
  const { data: Datas, isLoading: loading } = useQuery({
    queryKey: ["delivery_man_stock", page],
    queryFn: () => getDeliveryManStockApi(page,id),
  });
  return { Datas, loading };
};
