import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../../helpers/errorMessage";
import Loader from "../../../../components/common/general/Loader";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";
import StockProductItems from "./Components/StockProductItems";
import StockProductQtyInput from "./Components/StockProductQtyInput";
import * as yup from "yup";

const DeliveryManAddStockDialog = ({ id }) => {

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [children, setChildren] = useState(<></>);

  const { getProductsStock,addProductStock } = useDeliveryManFunctions();
  useEffect(() => {
    getProductsStock(id, setLoading, setProducts);
  }, []);
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    product_id: yup
      .string()
      .typeError("Please choose Product")
      .required("Product is required"),
  });
  const product_id = register("product_id");

  const onSubmit = (data) => {
    addProductStock(setLoading,id,data);
  };

  const handleChangeProduct = (e) => {
    if (e.target.value != "") {
      const filteredProducts = products.filter(
        (product) => product.id == e.target.value
      );
      if (filteredProducts[0].items.length != 0) {
        setChildren(
          <StockProductItems
            items={filteredProducts[0].items}
            register={register}
            setValue={setValue}
          />
        );
      } else {
        setChildren(<StockProductQtyInput register={register} />);
      }
    } else {
      setChildren(<></>);
    }
  };
  return (
    <div
      className="modal fade"
      id="DeliveryManAddStock"
      tabindex="-1"
      aria-labelledby="DeliveryManAddStockLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="DeliveryManAddStockLabel">
              Add Stock
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <label htmlFor="products" className="form-label">
                Products
              </label>
              <select
                id="product_id"
                name="product_id"
                className="form-control"
                {...product_id}
                onChange={(e) => {
                  product_id.onChange(e);
                  handleChangeProduct(e);
                }}
              >
                <option value="">Selectionner</option>
                {products?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "product_id")}
              {children}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {loading === true ? (
                <button type="submit" className="btn btn-primary">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default DeliveryManAddStockDialog;
