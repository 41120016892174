import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import TableComponent from "./TableComponent";
import "./table.css";

const Table = ({
  isLoading,
  query,
  setQuery,
  columns,
  data,
  titleLabel,
  meta,
  setPage,
  pagination,
  labelLink,
  btnFunction = false,
  btnData = null
}) => {
  return (
    <div className="card">
      {isLoading ? (
        <div className="card-body d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="card-body">
          <div className="g-3 row">
            <div className="col-sm-5">
              <div className="search-box me-2 mb-2 d-inline-block">
                <input
                  id="search-bar-0"
                  type="text"
                  className="form-control search /"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            {titleLabel !== null && (
              <div className="col-sm-auto ms-auto">
                <div>
                  {btnFunction === true ? (
                    <Link
                    className="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target={btnData}
                  >
                    <FeatherIcon
                      icon="plus"
                      className="ri-add-line align-bottom me-1"
                    />
                    Add {titleLabel}
                  </Link>
                  ) : (
                    <Link
                      className="btn btn-primary"
                      to={labelLink ? labelLink : `create`}
                    >
                      <FeatherIcon
                        icon="plus"
                        className="ri-add-line align-bottom me-1"
                      />
                      Add {titleLabel}
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>

          <TableComponent
            columns={columns}
            data={data}
            key={columns?.length}
            meta={meta}
            setPage={setPage}
            isLoading={isLoading}
            pagination={pagination}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
