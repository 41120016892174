import React from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import SidebarLink from "./SidebarLink";
import CAN from "../../../features/can";

const Sidebar = ({ isOpen, setIsOpen, width }) => {
  return (
    <>
      <nav
        className={`${
          isOpen ? "sidebar visible m-0 overflow-hidden" : "sidebar"
        }`}
      >
        <div className="sidebar-header">
          <Link to="/" className="sidebar-brand">
            Track<span>COD</span>
          </Link>
          <button
            className="bg-transparent text-white border-0"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FeatherIcon
                icon={width >= 991 ? "x" : "menu"}
                className="sidebar-toggler not-active text-white"
              />
            ) : (
              <FeatherIcon
                icon={width <= 991 ? "x" : "menu"}
                className="sidebar-toggler not-active text-white"
              />
            )}
          </button>
        </div>
        <div className="sidebar-body">
          <ul className="nav">
            {/* Agent */}
            {CAN("view", "leads") && (
              <SidebarLink label="Leads" route="/" icon="file" />
            )}
            {/* Admin */}
            {CAN("view", "statistics") && (
              <SidebarLink label="Dashboard" route="/" icon="home" />
            )}
            {CAN("view", "leadsadmin") && (
              <SidebarLink label="Leads" route="/leads" icon="file" />
            )}
            {CAN("view", "ordersadmin") && (
              <SidebarLink label="Orders" route="/orders" icon="file-text" />
            )}
            {CAN("view", "manageproducts") && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#manage_products"
                  role="button"
                  aria-expanded="false"
                  aria-controls="manage_products"
                >
                  <FeatherIcon className="link-icon" icon="layers" />
                  <span className="link-title">Manage Products</span>
                  <FeatherIcon className="link-arrow" icon="chevron-down" />
                </a>
                <div className="collapse" id="manage_products">
                  <ul className="nav sub-menu">
                    {CAN("view", "categories") && (
                      <li className="nav-item" key={0}>
                        <Link to="/categories" className="nav-link">
                          Categories
                        </Link>
                      </li>
                    )}
                    {CAN("view", "products") && (
                      <li className="nav-item" key={0}>
                        <Link to="/products" className="nav-link">
                          Products
                        </Link>
                      </li>
                    )}
                    {CAN("view", "factures") && (
                      <li className="nav-item" key={0}>
                        <Link to="/factures" className="nav-link">
                          Factures
                        </Link>
                      </li>
                    )}
                    {CAN("view", "suppliers") && (
                      <li className="nav-item" key={0}>
                        <Link to="/suppliers" className="nav-link">
                          Suppliers
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}

            {/* {CAN("view", "categories") && (
              <SidebarLink label="Categories" route="/categories" icon="grid" />
            )}
            {CAN("view", "products") && (
              <SidebarLink label="Products" route="/products" icon="layers" />
            )} */}
            {/* {CAN("view", "factures") && (
              <SidebarLink label="Facture" route="/factures" icon="list" />
            )} */}
            {/* {CAN("view", "suppliers") && (
              <SidebarLink label="Suppliers" route="/suppliers" icon="truck" />
            )} */}
            {CAN("view", "manageproducts") && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#manage_orders"
                  role="button"
                  aria-expanded="false"
                  aria-controls="manage_orders"
                >
                  <FeatherIcon className="link-icon" icon="twitch" />
                  <span className="link-title">Manage Orders</span>
                  <FeatherIcon className="link-arrow" icon="chevron-down" />
                </a>
                <div className="collapse" id="manage_orders">
                  <ul className="nav sub-menu">
                    {CAN("view", "ordersource") && (
                      <li className="nav-item" key={0}>
                        <Link to="/orders/sources" className="nav-link">
                          Order Source
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {CAN("view", "manageproducts") && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#manage_delivery"
                  role="button"
                  aria-expanded="false"
                  aria-controls="manage_delivery"
                >
                  <FeatherIcon className="link-icon" icon="truck" />
                  <span className="link-title">Manage Delivery</span>
                  <FeatherIcon className="link-arrow" icon="chevron-down" />
                </a>
                <div className="collapse" id="manage_delivery">
                  <ul className="nav sub-menu">
                    {CAN("view", "deliveryman") && (
                      <li className="nav-item" key={0}>
                        <Link to="/delivery/man" className="nav-link">
                          Delivery Man
                        </Link>
                      </li>
                    )}
                    {CAN("view", "deliverycompany") && (
                      <li className="nav-item" key={0}>
                        <Link to="/delivery/company" className="nav-link">
                          Delivery Companies
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {CAN("view", "manageusers") && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#manage_users"
                  role="button"
                  aria-expanded="false"
                  aria-controls="manage_users"
                >
                  <FeatherIcon className="link-icon" icon="user" />
                  <span className="link-title">Manage Users</span>
                  <FeatherIcon className="link-arrow" icon="chevron-down" />
                </a>
                <div className="collapse" id="manage_users">
                  <ul className="nav sub-menu">
                    <li className="nav-item" key={0}>
                      <Link to="/users/agents" className="nav-link">
                        Agents
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              // <SidebarLink
              //   label="Manage Agents"
              //   route="/users/agents"
              //   icon="user"
              // />
            )}
            {CAN("view", "integrations") && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#integrations"
                  role="button"
                  aria-expanded="false"
                  aria-controls="integrations"
                >
                  <FeatherIcon className="link-icon" icon="tool" />
                  <span className="link-title">Integrations</span>
                  <FeatherIcon className="link-arrow" icon="chevron-down" />
                </a>
                <div className="collapse" id="integrations">
                  <ul className="nav sub-menu">
                    <li className="nav-item" key={0}>
                      <Link to="/google_sheets" className="nav-link">
                        Google Sheet
                      </Link>
                    </li>
                    {/* <li className="nav-item" key={0}>
                      <Link to="/shopify" className="nav-link">
                        Shopify
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>
            )}
            {CAN("view", "customers") && (
              <SidebarLink label="Customers" route="/customers" icon="user" />
            )}
            {/* {CAN("view", "subscriptions") && (
              <SidebarLink
                label="Subscriptions"
                route="/subscriptions"
                icon="archive"
              />
            )}
            {CAN("view", "paiements") && (
              <SidebarLink
                label="Paiements"
                route="/subscriptions/paiement"
                icon="credit-card"
              />
            )} */}
            {CAN("view", "settings") && (
              <SidebarLink label="Settings" route="/settings" icon="settings" />
            )}
            {/* {sidebarRoutes?.map(
              (
                { label, route, isMultiple, parent, children, icon },
                index
              ) => (
                <>
                  {label && (
                    <SidebarLink
                      label={label}
                      route={route}
                      key={index + label}
                      icon={icon}
                    />
                  )}
                  {isMultiple && (
                    <AccordionComponent
                      key={index + isMultiple}
                      isOpen={isOpen}
                      width={width}
                      index={1}
                      title={parent}
                      items={children}
                      icon={icon}
                    />
                  )}
                </>
              )
            )} */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
