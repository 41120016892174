import { handleErrorApi } from "../../../../helpers/handleErrorApi";
import { UpdateYalidineApi,getYalidineApi,getSyncTarifsApi } from "../../../../apis/app/delivery/company/HandleYalidineApi";
import Toast from "../../../../helpers/toast.js";

const useYalidineHandle = () => {

    const UpdateYalidine = async(data,setLoading,id)=>{
      try{
        setLoading(true);
        const response = await UpdateYalidineApi(id,data);
        Toast("success", "You have successfully create your category");
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    const getYalidineInformation = async(id, setLoading,setData)=>{
      try{
        setLoading(true);
        const response = await getYalidineApi(id);
        setData(response.data?.data);
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    const SyncTarifsFunction = async(id,setLoading,setData)=>{
      try{
        setLoading(true);
        const response = await getSyncTarifsApi(id);
        setData(response.data?.data);
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    return {
        UpdateYalidine,
        getYalidineInformation,
        SyncTarifsFunction
    };
}
export default useYalidineHandle;