import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { CreateSupplierSetErrors } from "../../../helpers/HandleSetErrors/CreateSupplierSetErrors.js";
import { getCreateSupplierApi } from "../../../apis/app/suppliers/getCreateSupplierApi";
import { getSupplierDetailsApi } from "../../../apis/app/suppliers/getSupplierDetailsApi";
import { getUpdateSupplier } from "../../../apis/app/suppliers/getUpdateSupplier";
import { getUpdatePasswordClientApi } from "../../../apis/app/user/getUpdatePasswordClientApi";
import { useNavigate } from "react-router-dom";
import Toast from "../../../helpers/toast.js";
import { UpdateSupplierSetErrors } from "../../../helpers/HandleSetErrors/UpdateSupplierSetErrors";

const useCreateSupplier = () => {
  const navigate = useNavigate();

  const CreateSupplier = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await getCreateSupplierApi(data);
      navigate("/suppliers");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        CreateSupplierSetErrors(data, setError);
      }
      // handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getSupplier = async (id, setLoadingData, setData) => {
    try {
      setLoadingData(true);
      const response = await getSupplierDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoadingData(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoadingData(false);
    }
  };
  const UpdateSupplier = async (id, setLoading, data, setError) => {
    try {
      setLoading(true);
      const response = await getUpdateSupplier(id, data);
      Toast("success", "You have successfully update supplier");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        UpdateSupplierSetErrors(data,setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const UpdatePassword = async (id, setLoadingPass, data, setErrorPassword) => {
    try {
      setLoadingPass(true);
      const response = await getUpdatePasswordClientApi(id, data);
      Toast("success", "You have successfully update password");
    } catch (error) {
      setLoadingPass(false);
      if (error?.response.status == 400) {
        if (error?.response.data.error == "password_exist") {
          setErrorPassword("password", {
            type: "submit",
            message: error?.response.data.message,
          });
        }
      }
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoadingPass(false);
    }
  };
  return {
    CreateSupplier,
    getSupplier,
    UpdateSupplier,
    UpdatePassword,
  };
};
export default useCreateSupplier;
