import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import { errorMessage } from "../../../helpers/errorMessage";
import useDeliveryManFunctions from "../../../hooks/app/DeliveryMan/useDeliveryManFunctions";
import { AddAddressSchema } from "../../../validation/app/deliveryMan/AddAddressSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const AddAddressDialog = ({AddAddressProfile}) => {
  const [loading, setLoading] = useState(false);

  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState(false);

  const [Dairas, setDairas] = useState([]);
  const [loadingDairas, setLoadingDairas] = useState(false);

  const { getWilayas, getWilayaDairas } = useDeliveryManFunctions();
  useEffect(() => {
    getWilayas(setWilayas, setWilayasLoading);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(AddAddressSchema),
  });
  const wilaya_id = register("wilaya_id", { required: true });
  const getDairas = (e) => {
    getWilayaDairas(e.target.value, setDairas, setLoadingDairas);
  };
  const AddAddress = (data) => {
    AddAddressProfile(data,setLoading);
  };
  return (
    <div
      className="modal fade"
      id="AddAddressProfile"
      tabIndex="-1"
      aria-labelledby="AddAddressProfileLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="AddAddressProfileLabel">
              Add Address
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(AddAddress)}>
              <label for="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Address"
                {...register("address")}
              />
              {errorMessage(errors, "address")}
              <label htmlFor="address" className="form-label">
                Wilayas
              </label>
              <select
                id="wilaya_id"
                name="wilaya_id"
                className="form-control"
                {...wilaya_id}
                onChange={(e) => {
                  wilaya_id.onChange(e);
                  getDairas(e);
                }}
              >
                <option value="">{wilayasLoading?"Loading....":"Selectionner"}</option>
                {wilayas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "wilaya_id")}
              <label htmlFor="address" className="form-label">
                Dairas
              </label>
              <select
                id="daira_id"
                name="daira_id"
                className="form-control"
                {...register("daira_id")}
              >
                <option value="">{loadingDairas?"Loading....":"Selectionner"}</option>
                {Dairas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "daira_id")}
              <label for="commune" className="form-label">
                commune
              </label>
              <input
                type="text"
                className="form-control"
                id="commune"
                placeholder="commune"
                {...register("commune")}
              />
              {errorMessage(errors, "commune")}
              {loading ? (
                <button type="submit" className="btn btn-primary mt-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success mt-2">
                  Add
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAddressDialog;
