import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useFactureFunctions from "../../../hooks/app/Factures/useFactureFunctions";
import Loading from "../../Loading";
import { Link } from "react-router-dom/dist";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Loader from "../../../components/common/general/Loader";
import UpdateFactureAddFactureLotDialog from "./UpdateFacture/UpdateFactureAddFactureLotDialog";
import { useForm } from "react-hook-form";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import Toast from "../../../helpers/toast";
import UpdateFactureAddPaiementDialog from "./UpdateFacture/UpdateFactureAddPaiementDialog";

const UpdateFacture = () => {
  const { id } = useParams();
  const {
    getFactureDetails,
    getDeleteFactureLotFunction,
    getStoreProducts,
    getUpdateFactureAddLotFunction,
    getSuppliers,
    getUpdateFactureAddPaiement,
  } = useFactureFunctions();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [loadingDeleteLot, setLoadingDeleteLot] = useState(false);
  const [productsDB, setProductsDB] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loadingSupplier, setLoadingSupplier] = useState([]);
  useEffect(() => {
    getFactureDetails(id, setLoadingData, setData);
    getStoreProducts(setProductsDB);
    getSuppliers(setLoadingSupplier, setSuppliers);
  }, [id]);
  const DeleteProduct = (lot_id) => {
    getDeleteFactureLotFunction(id, lot_id, setLoadingDeleteLot, setData);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (data) => {
    if (data.supplier_id == undefined && data.note == undefined) {
      Toast("success", "Nothing to update");
    } else {
      console.log(data);
    }
  };
  return (
    <>
      <Breadcrumb
        title="Factures"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update Facture"
        secondaryLink="/factures"
        secondarytitle="Factures"
      />
      {loadingData ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <h6 className="card-title">Facture Products</h6>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div>
                        <Link
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#UpdateFactureAddLot"
                        >
                          <FeatherIcon
                            icon="plus"
                            className="ri-add-line align-bottom me-1"
                          />
                          Add Lot
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Product</th>
                          <th>Purshase Price</th>
                          <th>Qty</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.lots?.map((lot, index) => {
                          return (
                            <tr key={index}>
                              <th>{lot.id}</th>
                              <th>{lot.product?.name}</th>
                              {lot.items?.length === 0 ? (
                                <th>{lot.purshase_price} Da</th>
                              ) : (
                                <th>
                                  {lot?.items?.map((item, index) => {
                                    return (
                                      <>
                                        <span>
                                          {item?.item?.name} :{" "}
                                          {item?.purshase_price} Da
                                        </span>
                                        <br />
                                      </>
                                    );
                                  })}
                                </th>
                              )}
                              {lot.items?.length === 0 ? (
                                <th>{lot.qty}</th>
                              ) : (
                                <th>
                                  {lot?.items?.map((item, index2) => {
                                    return (
                                      <>
                                        <span key={index + index2}>
                                          {item?.item?.name} : {item?.qty}
                                        </span>
                                        <br />
                                      </>
                                    );
                                  })}
                                </th>
                              )}
                              <th>
                                <div className="d-flex flex-row justify-between">
                                  {loadingDeleteLot === true ? (
                                    <Link>
                                      <Loader />
                                      <FeatherIcon
                                        icon="loading"
                                        color="red"
                                        size={20}
                                      />
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={(e) => DeleteProduct(lot?.id, e)}
                                    >
                                      <FeatherIcon
                                        icon="trash"
                                        color="red"
                                        size={20}
                                      />
                                    </Link>
                                  )}
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                        {/* <tr>
                          <td></td>
                          <td></td>
                          <td>Total Price</td>
                          <td>4000 Da</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Origin Delivery Price</td>
                          <td>1175 Da</td>
                          <td>Delivery Price annouce</td>
                          <td>650 Da</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Final Price</td>
                          <td>4650 Da</td>
                          <td></td>
                          <td></td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <h6 className="card-title">Facture Paiements</h6>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div>
                        <Link
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#UpdataFactureAddPaiement"
                        >
                          <FeatherIcon
                            icon="plus"
                            className="ri-add-line align-bottom me-1"
                          />
                          Add Paiements
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {data?.paiements?.length === 0 ? (
                    <div className="alert alert-warning" role="alert">
                      No paiements found
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Created At</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.paiements?.map((paiement, index) => {
                            return (
                              <tr key={index}>
                                <th>{paiement.id}</th>
                                <th>{paiement.amount} Da</th>
                                <th>{paiement.note}</th>
                                <th>{paiement.created_at}</th>
                                {/* <th>
                                  <div className="d-flex flex-row justify-between">
                                    {loadingDeleteLot === true ? (
                                      <Link>
                                        <Loader />
                                        <FeatherIcon
                                          icon="loading"
                                          color="red"
                                          size={20}
                                        />
                                      </Link>
                                    ) : (
                                      <Link
                                      // onClick={(e) => DeleteProduct(lot?.id, e)}
                                      >
                                        <FeatherIcon
                                          icon="trash"
                                          color="red"
                                          size={20}
                                        />
                                      </Link>
                                    )}
                                  </div>
                                </th> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-header">Détails</div>
                <div className="card-body">
                  <form
                    className="forms-sample"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between border-bottom mb-2">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-12 text-muted">Code</p>
                              <p className="tx-14">{data.code}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between border-bottom mb-2">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-12 text-muted">Total</p>
                              <p className="tx-14">{data.total} Da</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-2">
                        <label htmlFor="supplier_id" className="form-label">
                          Supplier:{" "}
                          <span className="text-success">
                            {data.supplier?.first_name +
                              " " +
                              data.supplier?.last_name}
                          </span>
                        </label>
                        <select
                          id="supplier_id"
                          name="supplier_id"
                          className="form-control"
                          {...register("supplier_id")}
                        >
                          <option value="">
                            {loadingSupplier === true ? "Loading..." : "Select"}
                          </option>
                          {suppliers?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.first_name + " " + item.last_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Note"
                          name="note"
                          placeholder="Note"
                          control={control}
                          defaultValue={data?.note}
                          error="note"
                        />
                        {loading ? (
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            <Loader />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-success me-2"
                          >
                            Update Facture
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <UpdateFactureAddFactureLotDialog
            productsDB={productsDB}
            getUpdateFactureAddLotFunction={getUpdateFactureAddLotFunction}
            setData={setData}
          />
          <UpdateFactureAddPaiementDialog
            getUpdateFactureAddPaiement={getUpdateFactureAddPaiement}
            setData={setData}
          />
        </>
      )}
    </>
  );
};
export default UpdateFacture;
