import { useState, useEffect, Children } from "react";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import DeliveryCompany from "../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import NewDeliveryCompany from "./CreateDeliveryCompany/NewDeliveryCompany";
import YalidineCompany from "./CreateDeliveryCompany/YalidineCompany";
import NoestCompany from "./CreateDeliveryCompany/NoestCompany";
import MaystroCompany from "./CreateDeliveryCompany/MaystroCompany";
import ZrExpressCompany from "./CreateDeliveryCompany/ZrExpressCompany";
import EcoTrackCompany from "./CreateDeliveryCompany/EcoTrackCompany";

const CreateDeliveryCompany = () => {
  const [deliveryCompanies, setDeliveryCompanies] = useState([]);
  const [deliveryCompaniesLoading, setDeliveryCompaniesLoading] = useState([]);
  const [children, setChildren] = useState(<></>);
  const { getDeliveryCompanies } = DeliveryCompany();
  useEffect(() => {
    getDeliveryCompanies(setDeliveryCompanies, setDeliveryCompaniesLoading);
  }, []);
  const HandleChange = (e) => {
    switch (e.target.value) {
      case "0":
        setChildren(<NewDeliveryCompany />);
        break;
      case "1":
        setChildren(<YalidineCompany />);
        break;
      case "2":
        setChildren(<NoestCompany />);
        break;
      case "3":
        setChildren(<MaystroCompany />);
        break;
        case "4":
        setChildren(<ZrExpressCompany />);
        break;
        case "5":
        setChildren(<EcoTrackCompany />);
        break;
      default:
        setChildren(<></>);
        break;
    }
  };
  return (
    <>
      <Breadcrumb
        title="Delivery Company"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create Delivery Company"
        secondaryLink="/delivery/company"
        secondarytitle="Delivery Company"
      />
      <div className="card mt-2">
        <div className="card-body">
          <h6 className="card-title">Create Delivery Company</h6>
          <div className="alert alert-warning" role="alert">
            Disable Option in select mean that you have already add this delivery company, <br/>
            Or Currenntly disable for maintenance
          </div>
          <div className="mb-3">
            <label for="Select1" className="form-label">
              {deliveryCompaniesLoading ? "Loading...." : "Select Delivery Company"}
            </label>
            <select
              className="form-select"
              id="Select1"
              onChange={(e) => HandleChange(e)}
            >
              <option selected value="">
                Select
              </option>
              {deliveryCompanies.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.value}
                    disabled={item.disable}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};
export default CreateDeliveryCompany;
