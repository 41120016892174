import Toast from "./toast";

export const handleErrorApi = (status, message, data) => {
  switch (status) {
    case 400:
      switch (message) {
        // Sign up Form
        case "error_signup":
          if (data.hasOwnProperty("first_name")) {
            data.first_name.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("last_name")) {
            data.last_name.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("username")) {
            data.username.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("email")) {
            data.email.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("phone")) {
            data.phone.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("password")) {
            data.password.map((item) => {
              Toast("error", `${item}`);
            });
          }
          break;
        case "create_store":
          if (data.hasOwnProperty("name")) {
            data.name.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("theme_id")) {
            data.theme_id.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("url")) {
            data.url.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("email")) {
            data.email.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("phone")) {
            data.phone.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("image")) {
            data.image.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("days")) {
            data.days.map((item) => {
              Toast("error", `${item}`);
            });
          }
          for (var i = 0; i < 7; i++) {
            if (data.hasOwnProperty(`days.${i}.from`)) {
              Toast("error", "The Time From field must match the format H:i");
            }
            if (data.hasOwnProperty(`days.${i}.to`)) {
              Toast("error", "The Time To field must match the format H:i");
            }
          }
          break;
        case "create_supplier":
          if (data.hasOwnProperty("first_name")) {
            data.first_name.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("username")) {
            data.username.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("last_name")) {
            data.last_name.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("email")) {
            data.email.map((item) => {
              Toast("error", `${item}`);
            });
          }
          if (data.hasOwnProperty("phone")) {
            data.phone.map((item) => {
              Toast("error", `${item}`);
            });
          }
          break;
      }
      break;
    case 401:
      Toast("error", `${message}`);
      break;
    case 404:
      Toast("error", `${message}`);
      break;
    case 405:
      Toast("error", "Error Server, Try again...");
      break;
    default:
      Toast("error", "Error Server, Try again...");
      break;
  }
};
