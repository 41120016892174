import React from "react";
import { Link } from "react-router-dom/dist";

const Breadcrumb = ({
  primaryLink,
  primaryTitle,
  secondaryLink,
  secondarytitle,
  thirdTitle,
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={primaryLink}>{primaryTitle}</Link>
        </li>
        {secondaryLink ? (
          <li className="breadcrumb-item">
            <Link to={secondaryLink}>{secondarytitle}</Link>
          </li>
        ) : null}
        <li className="breadcrumb-item active">{thirdTitle}</li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
