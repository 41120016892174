import * as yup from "yup";

export const CreateProductSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  cat_id: yup
    .number()
    .typeError("Please choose Category")
    .required("Category is required"),
  status: yup
    .boolean()
    .typeError("Status is required")
    .oneOf([true, false], "Name is required"),
  // price: yup
  //   .number()
  //   .typeError("price must be a number")
  //   .required("Please provide price.")
  //   .min(0, "Too little"),
  description: yup.string().required("Description is required"),
  discount_delivery: yup
    .string()
    .typeError("Discount is required")
    .oneOf(['no_discount', "all_wilayas","some_wilayas"], "Discount is required"),
  image: yup.mixed().required("Image is required"),
});
