import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useGoogleSheetsFunctions from "../../../hooks/app/google_sheets/useGoogleSheetsFunctions";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import Toast from "../../../helpers/toast";

const UpdateGoogleSheet = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { getGoogleSheetDetails,getUpdateGoogleSheet } = useGoogleSheetsFunctions();
  useEffect(() => {
    getGoogleSheetDetails(id, setLoadingData, setData);
  }, [id]);
  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (temp_data) => {
    if (
      temp_data.status === undefined &&
      temp_data.sheet_name === undefined &&
      temp_data.start_row === undefined
    ) {
      Toast("error", "Nothing to be updated");
    } else {
      if (temp_data.start_row < 2) {
        setError("start_row", {
          type: "submit",
          message: "Min Start row is 2",
        });
      } else {
        getUpdateGoogleSheet(id,temp_data,setLoading);
      }
    }
  };
  return (
    <>
      <Breadcrumb
        title="Google Sheets"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update"
        secondaryLink="/google_sheets"
        secondarytitle="Google Sheets"
      />
      {loadingData ? (
        <Loader />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Update Google Sheet</h6>
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="text"
                      label="Sheet name"
                      name="sheet_name"
                      placeholder="Sheet name"
                      control={control}
                      defaultValue={data?.sheet_name}
                      error="sheet_name"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="number"
                      label="Start Row"
                      name="start_row"
                      placeholder="Start Row"
                      control={control}
                      defaultValue={data?.start_row}
                      error="start_row"
                    />
                  </div>
                  <div className="col-lg-4">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.status}
                      control={control}
                      name="status"
                      closeMenuOnSelect
                      label="Status"
                    />
                  </div>
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary mt-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mt-2">
                    Save
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateGoogleSheet;
