import { useQuery } from "@tanstack/react-query";
import { CategoriesApi } from "../../../apis/app/categories/CategoriesApi";
import { useNavigate } from "react-router-dom";

export const useGetAllCategories = (page) => {
  const navigate = useNavigate();

  const { data: CategoriesData, isLoading: loadingCategories } = useQuery({
    queryKey: ["categories", page],
    queryFn: () => CategoriesApi(page),
    onError: (err) => {
      if(err.response?.status === 401){
        if(err.response.data?.error === "no_store"){
          navigate('/create_store');
        }
        if(err.response.data?.error === "not_activated"){
          navigate('/confirme_account')
        }
      }
    },
  });
  return { CategoriesData, loadingCategories };
};
