import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const SettingsDropdown = ({ items }) => {
  const navigate = useNavigate();
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="profileDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FeatherIcon icon="list" />
      </a>
      <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
        <ul className="list-unstyled p-1">
          {items.map((item, index) => (
            <li
              key={index}
              className="dropdown-item py-2"
              role="button"
              onClick={() => item?.route && navigate(item?.route)}
            >
              <Link
                to={item.route || ""}
                className="text-body ms-0"
                onClick={() => {
                  if (item.onclick) item.onclick();
                }}
              >
                <FeatherIcon
                  icon={item?.icon}
                  className="me-2 icon-md"
                  data-feather="edit"
                  size={20}
                ></FeatherIcon>
                <span>{item?.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default SettingsDropdown;
