import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import DeliveryCompany from "../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import { useForm } from "react-hook-form";
import Toast from "../../../../helpers/toast.js";
import Loader from "../../../../components/common/general/Loader";
import InputComponent from "../../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../../components/FormUpdate/SelectComponent";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const UpdateDeliveryCompany = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { getDeliveryCompany, UpdateDeliveryCompany } = DeliveryCompany();
  useEffect(() => {
    getDeliveryCompany(id, setLoadingData, setData);
  }, [id]);
  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (data) => {
    if (
      data.name == undefined &&
      data.phone == undefined &&
      data.email == undefined &&
      data.website == undefined &&
      data.status == undefined &&
      data.default == undefined
    ) {
      Toast("error", "Nothing to Update");
    } else {
      UpdateDeliveryCompany(
        {
          ...data,
        },
        setLoading,
        id
      );
    }
  };
  return (
    <>
      <Breadcrumb
        title="Delivery Company"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update Delivery Company"
        secondaryLink="/delivery/company"
        secondarytitle="Delivery Company"
      />
      {loadingData ? (
        <Loader />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="text"
                      label="Company Name"
                      name="name"
                      placeholder="Company name"
                      control={control}
                      defaultValue={data?.information?.name}
                      error="name"
                    />
                  </div>
                  <div className="col-lg-4">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.status}
                      control={control}
                      name="status"
                      closeMenuOnSelect
                      label="Status"
                    />
                  </div>
                  <div className="col-lg-4">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "True",
                        },
                        {
                          value: 0,
                          label: "False",
                        },
                      ]}
                      defaultData={data?.default}
                      control={control}
                      name="default"
                      closeMenuOnSelect
                      label="Default"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="text"
                      label="Company phone"
                      name="phone"
                      placeholder="Company phone"
                      control={control}
                      defaultValue={data?.information?.phone}
                      error="phone"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="email"
                      label="Company email"
                      name="email"
                      placeholder="Company email"
                      control={control}
                      defaultValue={data?.information?.email}
                      error="email"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="text"
                      label="Company Website"
                      name="website"
                      placeholder="Company Website"
                      control={control}
                      defaultValue={data?.information?.website}
                      error="website"
                    />
                  </div>
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Update Delivery Company
                  </button>
                )}
              </form>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <h6 className="card-title">Delivery Tarifs</h6>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>wilaya</th>
                      <th>is deliverable</th>
                      <th>has stop desk</th>
                      <th>Price Desk</th>
                      <th>Price Home</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.information?.tarifs?.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.wilaya_id}</td>
                          <td>{item.name}</td>
                          <td>
                            <span
                              className={
                                item?.is_deliverable === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {item?.is_deliverable === true ? "True" : "False"}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                item?.has_stop_desk === true
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {item?.has_stop_desk === true ? "True" : "False"}
                            </span>
                          </td>
                          <td>{item.price_desk} Da</td>
                          <td>{item.price_home} Da</td>
                          <td>
                            <div className="d-flex flex-row justify-between">
                              <Link
                                to={`/delivery/company/view/${item.id}`}
                                title="View Company Details"
                                className="me-3"
                              >
                                <FeatherIcon
                                  icon="edit"
                                  size={20}
                                />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateDeliveryCompany;
