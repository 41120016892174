import axios from "axios";

const axiosInstancePart = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-type": "multipart/form-data",
    Accept: "multipart/form-data"
  }
});

axiosInstancePart.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("cod-dash-token");
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstancePart;
