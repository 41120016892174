import { useQuery } from "@tanstack/react-query";
import { getAllLeadsApis } from "../../../apis/app/order_leads/getAllLeadsFunctions";

export const useGetAllOrderLeads = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["leads", page],
    queryFn: () => getAllLeadsApis(page),
  });
  return { Datas, loadingDatas };
};
