export const errorMessage = (errors, error) => {
    if (errors) {
      if (error) {
        return (
          <p className="text-danger mt-2 d-flex flex-row align-items-center">
            {errors[error]?.message}
          </p>
        );
      }
    }
  };
  