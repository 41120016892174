import { handleErrorApi } from "../../../../helpers/handleErrorApi.js";
import { HandleNoestApi } from "../../../../apis/app/delivery/company/HandleNoestApi.js";
import Toast from "../../../../helpers/toast.js";

const useNoestHandle = () => {

    const UpdateNoest = async(data,setLoading,id)=>{
      try{
        setLoading(true);
        const response = await HandleNoestApi(id,data);
        Toast("success", "You have successfully create your category");
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    return {
        UpdateNoest
    };
}
export default useNoestHandle;