import * as yup from "yup";
const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;

export const CreateNewDeliveryCompanySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  email: yup.string().email().required("Email is required"),
  website: yup.string().required("Website is required"),
  status: yup
    .boolean()
    .typeError("Status is required")
    .oneOf([true, false], "Status is required"),
  default: yup
    .boolean()
    .typeError("Default is required")
    .oneOf([true, false], "Default is required"),
});
