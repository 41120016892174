import { useNavigate } from "react-router-dom";
import {
  getAllSuppliersApi,
  getStoreProductsApi,
  getCreateFactureApi,
  getFactureDetailsApi,
  getDeleteFactureLotApi,
  getUpdateFactureAddLotApi,
  getUpdateFactureAddPaiementApi
} from "../../../apis/app/factures/getFactureApis";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast.js";

const useFactureFunctions = () => {
  const navigate = useNavigate();
  const getSuppliers = async (setLoading, setData) =>{
    try {
      setLoading(true);
      const response = await getAllSuppliersApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getStoreProducts = async (setData) =>{
    try {
      const response = await getStoreProductsApi();
      setData(response.data?.data);
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  }
  const CreateFactureFunction = async (data,setLoading,setError)=>{
    try {
      setLoading(true);
      const response = await getCreateFactureApi(data);
      navigate('/factures')
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getFactureDetails = async (id,setLoading,setData)=>{
    try {
      setLoading(true);
      const response = await getFactureDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getDeleteFactureLotFunction = async (facture_id,lot_id,setLoading,setData)=>{
    try {
      setLoading(true);
      const response = await getDeleteFactureLotApi(facture_id,lot_id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getUpdateFactureAddLotFunction = async (facture_id,data,setLoading,setData)=>{
    try {
      setLoading(true);
      const response = await getUpdateFactureAddLotApi(facture_id,data);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getUpdateFactureAddPaiement = async (facture_id,data,setLoading,setData)=>{
    try {
      setLoading(true);
      const response = await getUpdateFactureAddPaiementApi(facture_id,data);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  return {
    getSuppliers,
    getStoreProducts,
    CreateFactureFunction,
    getFactureDetails,
    getDeleteFactureLotFunction,
    getUpdateFactureAddLotFunction,
    getUpdateFactureAddPaiement
  };
};
export default useFactureFunctions;
