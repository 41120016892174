import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../../hooks/useSearch";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDeliveryMan } from "../../../../hooks/app/DeliveryMan/useDeliveryMan";
import Table from "../../../../components/table/Table";

const DeliveryMans = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { DeliveryManDatas, loadingDeliveryMan } = useDeliveryMan(page);
  
  const columns = [
    {
        name: "First name",
        selector: (row) => row.first_name,
        sortable: true,
      },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
      },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              title="Delivery Man Stock"
              className="me-3"
              to={`/delivery/man/${row.id}/stock`}
            >
              <FeatherIcon icon="file-text" color="blue" size={20} />
            </Link>
            <Link
              title="Delivery Man Orders"
              className="me-3"
              to={`/delivery/man/${row.id}/orders`}
            >
              <FeatherIcon icon="layers" color="green" size={20} />
            </Link>
            <Link
              to={`/delivery/man/update/${row.id}`}
              title="update delivery man"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete role">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb
        title="Delivery Man"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Delivery Man"
      />
       <Table
        isLoading={loadingDeliveryMan}
        query={query}
        setQuery={setQuery}
        titleLabel="Delivery Man"
        columns={columns}
        data={search(DeliveryManDatas?.data?.data, ["name"], query)}
        meta={DeliveryManDatas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default DeliveryMans;
