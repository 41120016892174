import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateStoreSchema } from "../../../validation/app/store/CreateStoreSchema";
import StoreApis from "../../../hooks/app/store/store";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import ImageDropzone from "../../../components/common/general/ImageDropzone";

const CreateStore = () => {
  const [themes, setThemes] = useState([]);
  const [loadingThemes, setLodingThemes] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getThemes, createStore, getDays } = StoreApis();
  const [days, setDays] = useState([]);
  const [loadingDays, setLodingDays] = useState(false);

  useEffect(() => {
    getThemes(setLodingThemes, setThemes);
    getDays(setLodingDays, setDays);
  }, []);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(CreateStoreSchema),
  });

  const photo = watch("image");

  const onSubmit = (data) => {
    createStore(
      {
        ...data,
      },
      setLoading
    );
  };
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">Create Store</h6>
        <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label for="name" className="form-label">
                  Store name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  autocomplete="off"
                  placeholder="Store name"
                  {...register("name")}
                />
                {errorMessage(errors, "name")}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label for="theme_id" className="form-label">
                  Themes
                </label>
                <select
                  name="theme_id"
                  id="theme_id"
                  className="form-control"
                  {...register("theme_id")}
                >
                  <option value="">Selectionner</option>
                  {themes?.map((item, index) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
                </select>
                {errorMessage(errors, "theme_id")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="url" className="form-label">
                  Store url
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  autocomplete="off"
                  placeholder="Store Url"
                  {...register("url")}
                />
                {errorMessage(errors, "url")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="phone" className="form-label">
                  Store Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  autocomplete="off"
                  placeholder="Store Phone"
                  {...register("phone")}
                />
                {errorMessage(errors, "phone")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="email" className="form-label">
                  Store Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autocomplete="off"
                  placeholder="Store Email"
                  {...register("email")}
                />
                {errorMessage(errors, "email")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="facebook" className="form-label">
                  Store Facebook
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="facebook"
                  autocomplete="off"
                  placeholder="Store Facebook"
                  {...register("facebook")}
                />
                {errorMessage(errors, "facebook")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="instagram" className="form-label">
                  Store Instagram
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="instagram"
                  autocomplete="off"
                  placeholder="Store Instagram"
                  {...register("instagram")}
                />
                {errorMessage(errors, "instagram")}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="youtube" className="form-label">
                  Store Youtube
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="youtube"
                  autocomplete="off"
                  placeholder="Store Youtube"
                  {...register("youtube")}
                />
                {errorMessage(errors, "youtube")}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group mt-1">
                <ImageDropzone
                  placeholder="Drag and drop or click to select store image"
                  photos={photo}
                  name="image"
                  setValue={setValue}
                  multiple={false}
                  previewCol={5}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="day" className="form-label">
                  Day
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="from" className="form-label">
                  From
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label for="to" className="form-label">
                  To
                </label>
              </div>
            </div>
          </div> */}
          {/* {loadingDays == false
            ? days.map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-4">
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          value={item.name}
                          readOnly
                        />
                        <input
                          type="text"
                          className="form-control"
                          value={item.id}
                          hidden
                          {...register(`days.${index}.day_id`, {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="08:00"
                          {...register(`days.${index}.from`, {
                            required: true,
                          })}
                        />
                        {errorMessage(errors?.days?.[index], `from`)}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="22:00"
                          {...register(`days.${index}.to`, {
                            required: true,
                          })}
                        />
                        {errorMessage(errors?.days?.[index], `to`)}
                      </div>
                    </div>
                  </div>
                );
              })
            : null} */}
          {loading ? (
            <button type="submit" className="btn btn-primary me-2 mt-2">
              <Loader />
            </button>
          ) : (
            <button type="submit" className="btn btn-success me-2 mt-2">
              Create Store
            </button>
          )}
        </form>
      </div>
    </div>
  );
};
export default CreateStore;
