import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import Toast from "../../../helpers/toast.js";
import { yupResolver } from "@hookform/resolvers/yup";
import useSourceFunctions from "../../../hooks/app/orderSource/useSourceFunctions";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";

const UpdateOrderSource = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { getSourceDetails, getUpdateSource } = useSourceFunctions();
  useEffect(() => {
    getSourceDetails(id, setLoadingData, setData);
  }, [id]);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (data) => {
    if (data.name === undefined && data.status === undefined) {
      Toast("error", "Nothing to Update");
    } else {
      getUpdateSource(id, data, setLoading);
    }
  };
  return (
    <>
      <Breadcrumb
        title="Sources"
        primaryLink="/"
        primaryTitle="Dashboard"
        secondaryLink={"/orders/sources"}
        secondarytitle="Sources"
        thirdTitle="Update Order Source"
      />
      {loadingData ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6">
                    <InputComponent
                      errors={errors}
                      inputType="text"
                      label="Name"
                      name="name"
                      placeholder="First name"
                      control={control}
                      defaultValue={data?.name}
                      error="name"
                    />
                  </div>
                  <div className="col-lg-6">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.status}
                      control={control}
                      name="status"
                      closeMenuOnSelect
                      label="Status"
                    />
                  </div>
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary mt-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mt-2">
                    Update Source
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateOrderSource;
