import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useState } from "react";
import Table from "../../../components/table/Table";
import { useGetAllOrders } from "../../../hooks/app/orders/useGetAllOrders";

const Orders = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useGetAllOrders(page);
  const columns = [
    {
      name: "code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={
              row.customer?.first_name +
              " " +
              row.customer?.last_name +
              " " +
              row.customer?.phone
            }
          >
            {row.customer?.first_name + " " + row.customer?.last_name}
          </div>
        );
      },
      sortable: false,
    },
    {
      name: "Address",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={
              row.CustomerAddress?.address +
              "-" +
              row.CustomerAddress?.wilaya?.name +
              "-" +
              row.CustomerAddress?.daira?.name
            }
          >
            {row.CustomerAddress?.address}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Delivery Type",
      selector: (row) => row.DeliveryType?.name,
    },
    {
      name: "Current status",
      selector: (row) => {
        return (
          <span className="badge bg-success">{row?.CurrentStatus?.name}</span>
        );
      },
      sortable: true,
    },

    {
      name: "Count items",
      selector: (row) => row.OrderItems.length,
      sortable: true,
    },
    {
      name: "Created at",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/leads/view/${row.id}`}
              title="View Lead"
              className="me-3"
            >
              <FeatherIcon icon="eye" size={20} color="red" />
            </Link>
            <Link
              to={`/leads/confirme/${row.id}`}
              title="View Lead"
              className="me-3"
            >
              <FeatherIcon icon="check-circle" size={20} color="green" />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Orders"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Orders"
      />
      <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        titleLabel={null}
        columns={columns}
        data={search(Datas?.data?.data, ["code"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
        btnFunction={true}
        btnData="#CreateStatus"
      />
    </>
  );
};
export default Orders;
