import { Navigate, useLocation } from "react-router-dom";
import MainLayout from "../components/Layouts/MainLayout";

const ProtectedRoute = (
  isLoggedIn,
  Component
) => {
  const location = useLocation();

  return isLoggedIn ? (
    <MainLayout Children={<Component />} />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
