import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useState } from "react";
import Table from "../../../components/table/Table";
import { useGetGoogleSheets } from "../../../hooks/app/google_sheets/useGetGoogleSheets";
const ListGoogleSheets = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingData } = useGetGoogleSheets(page);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Google Account",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Sheet name",
      selector: (row) => row.sheet_name,
      sortable: true,
    },
    {
      name: "Sheet file",
      selector: (row) => row.file_id,
      sortable: true,
    },
    {
      name: "Start Rows",
      selector: (row) => row.start_row,
      sortable: true,
    },
    {
      name: "Count Leads",
      selector: (row) => row.leads,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/google_sheets/update/${row.id}`}
              title="update source"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete source">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      {Datas?.data?.data.length === 0 ? (
        <div className="alert alert-warning" role="alert">
          You have no sheet sync with you account, Please add sheet first
        </div>
      ) : null}
      <Breadcrumb
        title="Google Sheets"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Google Sheets"
      />
      <Table
        isLoading={loadingData}
        query={query}
        setQuery={setQuery}
        titleLabel="Sheet"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default ListGoogleSheets;
