import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useSurceFunctions from "../../../hooks/app/orderSource/useSourceFunctions";
import Toast from "../../../helpers/toast";
import Loader from "../../../components/common/general/Loader";
import { errorMessage } from "../../../helpers/errorMessage";
import {CreateOrderSourceSchema} from "../../../validation/app/order_source/CreateOrderSourceSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const CreateOrderSourceDialog = () => {
  const [loading, setLoading] = useState(false);
  const {CreateSource} = useSurceFunctions();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateOrderSourceSchema),

  });
  const onSubmitCode = (data) => {
    CreateSource(data,setLoading);
  };
  return (
    <div
      className="modal fade"
      id="CreateSource"
      tabindex="-1"
      aria-labelledby="CreateSourceLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="CreateSourceLabel">
              Create Order Source
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <form className="forms-sample" onSubmit={handleSubmit(onSubmitCode)}>
            <div className="modal-body">
              <div className="mb-3">
                <label for="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  autocomplete="off"
                  placeholder="Name"
                  {...register("name")}
                />
                {errorMessage(errors, "name")}
              </div>
              <div className="mb-3">
                <label for="status" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  id="status"
                  className="form-control"
                  {...register("status")}
                >
                  <option value="">Selectionner</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
                {errorMessage(errors, "status")}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {loading ? (
                <button type="submit" className="btn btn-primary me-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-primary me-2">
                  Create
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateOrderSourceDialog;
