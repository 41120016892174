import * as yup from "yup";

export const ChangePasswordClientSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  password_confirmation: yup
    .string()
    .required("Confirmation password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
