import * as yup from "yup";

export const ConfirmeLeadSchema = yup.object().shape({
  source_id: yup
    .number()
    .typeError("Please choose source")
    .required("source is required"),
  delivery_type: yup
    .number()
    .typeError("Delivery Type is required")
    .oneOf([1, 2], "Delivery Type is required"),
  wilaya_id: yup
    .string()
    .typeError("Please choose Wilaya")
    .required("Wilaya is required"),
  daira_id: yup
    .string()
    .typeError("Please choose Daira")
    .required("Daira is required"),
});
