import ProfileDropdown from "../../elements/navbar/profile/ProfileDropdown";
import { useSelector } from "react-redux";
import useAuth from "../../../hooks/auth/useAuth";
import NotificationList from "../../elements/navbar/notifications/NotificationList";
import "./iconfont.css";
import FeatherIcon from "feather-icons-react";
import SettingsDropdown from "../../elements/navbar/SettingsDropdown";

const Navbar = ({ isOpen, toggle }) => {
  const { user } = useSelector((state) => state.auth);

  const { logout } = useAuth();

  return (
    <nav className="navbar ">
      <span className="sidebar-toggler" onClick={toggle}>
        <FeatherIcon icon="menu" />
      </span>
      <div className="navbar-content">
        <ul className="navbar-nav">
          <NotificationList
            items={[
              {
                icon: "gift",
                label: "New Order Recieved",
                time: "30 min ago",
              },
            ]}
          />

          {/* <SettingsDropdown
            items={[
              {
                icon: "dollar-sign",
                label: "Invoices",
                route: "/invoices",
              },
              {
                icon: "settings",
                label: "Settings",
                route: "/settings",
              },
              {
                icon: "bar-chart",
                label: "Support",
                route: "/support",
              },
              {
                icon: "settings",
                label: "Statistics",
                route: "/statistics",
              },
            ]}
          /> */}

          <ProfileDropdown
            name={user?.first_name + user?.last_name}
            email={user?.email}
            profileImage={user?.image}
          />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
