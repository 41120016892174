import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../../hooks/useSearch";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../../components/table/Table";
import { useDeliveryManStock } from "../../../../hooks/app/DeliveryMan/useDeliveryManStock";
import DeliveryManAddStockDialog from "./DeliveryManAddStockDialog";

const DeliveryManStock = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loading } = useDeliveryManStock(page, id);
  console.log(Datas);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product?.name,
      sortable: true,
    },
    {
      name: "Total Quantity",
      selector: (row) => row.all,
      sortable: true,
    },
    {
      name: "Total Delivred",
      selector: (row) => row.livred,
      sortable: true,
    },
    {
      name: "Total Reste",
      selector: (row) => row.reste,
      sortable: true,
    },
    {
      name: "Items",
      selector: (row) =>
        row?.items.length !== 0 ? (
          <>
            {row?.items?.map((item, index) => {
              return (<>{item.item?.name+" : "+item.reste}<br/></>);
            })}
          </>
        ) : (
          <span className="badge bg-danger">No Items</span>
        ),
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              title="View Stock"
              className="me-3"
              to={`/delivery/man/${id}/stock/view/${row.id}`}
            >
              <FeatherIcon icon="eye" color="green" size={20} />
            </Link>
            <Link
              to={`/delivery/man/${id}/stock/update/${row.id}`}
              title="Update stock"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete Stock">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Delivery Man Stock"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Stock"
        secondaryLink="/delivery/man"
        secondarytitle="Delivery Man's"
      />
      <Table
        isLoading={loading}
        query={query}
        setQuery={setQuery}
        titleLabel="Stock"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
        // btnFunction={true}
        // btnData = "#DeliveryManAddStock"
      />
      <DeliveryManAddStockDialog id={id} />
    </>
  );
};
export default DeliveryManStock;
