import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const UpdateMainInformationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    username: yup.string().required("Username is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});
