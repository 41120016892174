import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  user: {},
  token: null,
  error:null,
  isLoggedIn: false,
  success: false,
  permissions: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { token, user, permissions } = action.payload;
      state.token = token;
      state.permissions = permissions;
      localStorage.setItem("cod-dash-token", token);
      state.user = user;
      state.isLoggedIn = true;
    },
    setUser : (state,action)=>{
      const { user } = action.payload;
      state.user = user;
    },
     logoutSlice: (state) => {
      state.token = null;
      state.user = null;
      state.isLoggedIn = false;
      state.permissions = [];
      localStorage.removeItem("cod-dash-token");
    },
    setPermissions: (state) => {
      const Permissions = state.user.permissions
        ?.map((role) => role?.permissions)
        ?.flat()
        ?.map((permission) => permission?.name);
      state.permissions = [...new Set(Permissions)];
      // state.permissions = ["Role-View"];
    },
  }
});

export const { setCredentials, logoutSlice, setPermissions,setUser } =
  authSlice.actions;

export default authSlice.reducer;
