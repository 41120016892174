import * as yup from "yup";
import { useState, useEffect } from "react";
import useAgentLeads from "../../../../hooks/agent/Leads/useAgentLeads";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../helpers/errorMessage";
import Toast from "../../../../helpers/toast";
import useProductFunctions from "../../../../hooks/app/products/useProductFunctions";
import Loader from "../../../../components/common/general/Loader";

const AddProductItemDialog = ({ id, setData }) => {
  const [loading, setLoading] = useState(false);
  const { CreateProductItemFunction } = useProductFunctions();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .typeError("Please choose name")
          .required("Name is required"),
        price: yup
          .number()
          .typeError("Price must be a number")
          .required("Please provide Price."),
      })
    ),
  });

  const HandleAdd = (data) => {
    CreateProductItemFunction(setLoading, id, data, setData, setValue);
  };
  return (
    <>
      <div
        className="modal fade"
        id="AddProductItem"
        tabIndex="-1"
        aria-labelledby="AddProductItemLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddProductItemLabel">
                Add New Variant
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    {...register("name")}
                  />
                  {errorMessage(errors, "name")}
                </div>
                <div className="mb-3">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    defaultValue={0}
                    {...register("price")}
                  />
                  {errorMessage(errors, "price")}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {loading === true ? (
                    <button type="submit" className="btn btn-primary">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success">
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProductItemDialog;
