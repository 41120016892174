import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";

const ViewProduct = () => {
  const { id } = useParams();
  return (
    <>
      <Breadcrumb
        title="Products"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Product"
        secondaryLink="/products"
        secondarytitle="Products"
      />
    </>
  );
};
export default ViewProduct;
