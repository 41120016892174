import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdatePasswordSchema } from "../../../validation/app/profile/UpdatePasswordSchema";
import useProfileFunctions from "../../../hooks/app/profile/useProfileFunctions";
import { useSelector } from "react-redux";
import { UpdateMainInformationSchema } from "../../../validation/app/profile/UpdateMainInformationSchema";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddAddressDialog from "./AddAddressDialog";

const MyProfile = () => {
  const {
    UpdatePassword,
    UpdateMainInformation,
    AddAddressProfile,
    DeleteAddressFunction,
  } = useProfileFunctions();
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  // Main info
  const {
    register: registerOne,
    handleSubmit: handleOne,
    formState: { errors: errorsOne },
    setError: setErrorOne,
  } = useForm({
    resolver: yupResolver(UpdateMainInformationSchema),
  });
  // Update Password
  const {
    setValue: setValueTwo,
    register: registerTwo,
    handleSubmit: handleTwo,
    formState: { errors: errorsTwo },
    setError: setErrorTwo,
  } = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
  });
  const {
    setValue: setValueFor,
    register: registerFor,
    handleSubmit: handleFor,
    formState: { errorsFor },
    setError: setErrorFor,
  } = useForm({});
  const SubmitMainInfos = (data) => {
    UpdateMainInformation(data, setLoadingOne, setErrorOne);
  };
  const SubmitPassword = (data) => {
    UpdatePassword(data, setLoadingTwo, setErrorTwo);
  };
  const SubmitAvatar = (data) => {};

  const DeleteAddress = (id) => {
    DeleteAddressFunction(id, setLoading);
  };
  return (
    <>
      <Breadcrumb
        title="My Profile"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="My Profile"
      />
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Main Informations</div>
              <form
                key={1}
                className="forms-sample"
                onSubmit={handleOne(SubmitMainInfos)}
              >
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="first_name" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        placeholder="First Name"
                        {...registerOne("first_name")}
                        defaultValue={user?.first_name}
                      />
                      {errorMessage(errorsOne, "first_name")}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="last_name" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        placeholder="Last Name"
                        {...registerOne("last_name")}
                        defaultValue={user?.last_name}
                      />
                      {errorMessage(errorsOne, "last_name")}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Username"
                        {...registerOne("username")}
                        defaultValue={user?.username}
                      />
                      {errorMessage(errorsOne, "username")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="phone" className="form-label">
                        phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="phone"
                        {...registerOne("phone")}
                        defaultValue={user?.phone}
                      />
                      {errorMessage(errorsOne, "phone")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="email" className="form-label">
                        email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="email"
                        {...registerOne("email")}
                        defaultValue={user?.email}
                      />
                      {errorMessage(errorsOne, "email")}
                    </div>
                  </div>
                </div>
                {loadingOne ? (
                  <button type="submit" className="btn btn-primary me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Update Password</div>
              <form
                key={2}
                className="forms-sample"
                onSubmit={handleTwo(SubmitPassword)}
              >
                <div className="mb-3">
                  <label for="password" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    {...registerTwo("password")}
                  />
                  {errorMessage(errorsTwo, "password")}
                </div>
                <div className="mb-3">
                  <label for="password_confirmation" className="form-label">
                    Password Confirmation
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_confirmation"
                    placeholder="Password Confirmation"
                    {...registerTwo("password_confirmation")}
                  />
                  {errorMessage(errorsTwo, "password_confirmation")}
                </div>
                {loadingTwo ? (
                  <button type="submit" className="btn btn-primary me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="g-3 row">
                <div className="col-sm-5">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="card-title">Address</div>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div>
                    <Link
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#AddAddressProfile"
                    >
                      <FeatherIcon
                        icon="plus"
                        className="ri-add-line align-bottom me-1"
                      />
                      Add Address
                    </Link>
                  </div>
                </div>
              </div>
              {user.address?.length !== 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Address</th>
                        <th>commune</th>
                        <th>daira</th>
                        <th>wilaya</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.address?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th>{item.id}</th>
                            <th>{item.address}</th>
                            <th>{item.commune}</th>
                            <th>{item.daira?.name}</th>
                            <th>{item.wilaya?.name}</th>
                            <th>
                              <div className="d-flex flex-row justify-between">
                                {loading ? (
                                  <Loader />
                                ) : (
                                  <Link onClick={(e) => DeleteAddress(item.id)}>
                                    <FeatherIcon
                                      icon="trash"
                                      color="red"
                                      size={20}
                                    />
                                  </Link>
                                )}
                              </div>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Update Avatar</div>
              <form
                key={4}
                className="forms-sample"
                onSubmit={handleFor(SubmitAvatar)}
              ></form>
            </div>
          </div>
        </div>
      </div>
      <AddAddressDialog AddAddressProfile={AddAddressProfile} />
    </>
  );
};
export default MyProfile;
