import { useState } from "react";
import DeliveryCompany from "../../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateEcoTrackSchema } from "../../../../../validation/app/deliveryCompany/CreateEcoTrackSchema";
import Loader from "../../../../../components/common/general/Loader";
import { errorMessage } from "../../../../../helpers/errorMessage";

const EcoTrackCompany = () => {
  const [loading, setLoading] = useState(false);
  const { CreateEcoTrackCompany } = DeliveryCompany();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateEcoTrackSchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateEcoTrackCompany(data, setLoading);
  };
  return (
    <>
    <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="base_url" className="form-label">
              Base Url
            </label>
            <input
              type="text"
              className="form-control"
              id="base_url"
              placeholder="Base Url"
              {...register("base_url")}
            />
            {errorMessage(errors, "base_url")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="api_token" className="form-label">
              Api Token
            </label>
            <input
              type="text"
              className="form-control"
              id="api_token"
              placeholder="Api Token"
              {...register("api_token")}
            />
            {errorMessage(errors, "api_token")}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="status" className="form-label">
              Status
            </label>
            <select
              name="status"
              id="status"
              className="form-control"
              {...register("status")}
            >
              <option value="">Selectionner</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            {errorMessage(errors, "status")}
          </div>
        </div>
      </div>
      {loading ? (
        <button type="submit" className="btn btn-primary me-2">
          <Loader />
        </button>
      ) : (
        <button type="submit" className="btn btn-success me-2">
          Create
        </button>
      )}
    </form>
  </>
  );
};
export default EcoTrackCompany;
