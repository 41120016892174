import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useForm } from "react-hook-form";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorMessage } from "../../../../helpers/errorMessage";
import StockProductItems from "./Components/StockProductItems";
import StockProductQtyInput from "./Components/StockProductQtyInput";
import Loader from "../../../../components/common/general/Loader";

const CreateDeliveryManStock = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [products, setProducts] = useState([]);
  const [children, setChildren] = useState(<></>);
  const [productItems, setProductItems] = useState([]);
  const { getProductsStock, addProductStock } = useDeliveryManFunctions();
  useEffect(() => {
    getProductsStock(id, setLoading, setProducts);
  }, []);
  const {
    register,
    unregister,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(createDeliveryManSchema),
  });
  const product_id = register("product_id");

  const onSubmit = (data) => {
    addProductStock(setLoadingData, id, data);
  };
  const handleChangeProduct = (e) => {
    unregister("items");
    unregister("qty");
    let product_id = e.target.value;
    if (e.target.value === "") {
      setProductItems([]);
      setChildren(<></>);
    } else {
      const filteredProducts = products.filter(
        (product) => product.id == product_id
      );
      if (filteredProducts[0].items.length != 0) {
        setChildren(<></>);
        setProductItems(filteredProducts[0].items);
      } else {
        setProductItems([]);
        setChildren(
          <StockProductQtyInput
            register={register}
            qty={filteredProducts[0].qty}
          />
        );
      }
    }
  };
  return (
    <>
      <Breadcrumb
        title="Create Delivery Man Stock"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create Stock"
        secondaryLink="/delivery/man"
        secondarytitle="Delivery Man"
      />
      <div className="card">
        <div className="card-body">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="products" className="form-label">
                  Products
                </label>
                <select
                  id="product_id"
                  name="product_id"
                  className="form-control"
                  {...product_id}
                  onChange={(e) => {
                    product_id.onChange(e);
                    handleChangeProduct(e);
                  }}
                >
                  <option value="">
                    {loading === true ? "Loading...." : "Selectionner"}
                  </option>
                  {products?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorMessage(errors, "product_id")}
              </div>
              {productItems.length != 0 ? (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="name" className="form-label">
                        Products
                      </label>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="qty" className="form-label">
                        Quantity Disponible
                      </label>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="qty" className="form-label">
                        Quantity for delivery man
                      </label>
                    </div>
                  </div>
                  {productItems.map((item, index) => {
                    return (
                      <div className="row" key={index}>
                        <input
                          type="number"
                          className="form-control"
                          value={item.id}
                          {...register(`items.${index}.id`)}
                          hidden
                        />
                        <div className="col-lg-4 mb-1">
                          <input
                            type="text"
                            className="form-control"
                            value={item.name}
                            {...register(`items.${index}.name`)}
                            readOnly
                          />
                        </div>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity Disponible"
                            value={item.qty}
                            {...register(`items.${index}.qty`)}
                            readOnly
                          />
                        </div>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            defaultValue={0}
                            {...register(`items.${index}.qty_fournis`)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {children}
            </div>
            {loadingData ? (
              <button type="submit" className="btn btn-primary mt-2">
                <Loader />
              </button>
            ) : (
              <button type="submit" className="btn btn-success mt-2">
                Save
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateDeliveryManStock;
