import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";

const ViewFacture = () => {
  const { id } = useParams();
  return (
    <>
      <Breadcrumb
        title="Factures"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Facture"
        secondaryLink="/factures"
        secondarytitle="Factures"
      />
    </>
  );
};
export default ViewFacture;
