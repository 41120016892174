import { useQuery } from "@tanstack/react-query";
import { getDeliveryMansApi } from "../../../apis/app/delivery_mans/getDeliveryMansApi";

export const useDeliveryMan = (page) => {
  const { data: DeliveryManDatas, isLoading: loadingDeliveryMan } = useQuery({
    queryKey: ["delivery_man", page],
    queryFn: () => getDeliveryMansApi(page),
  });
  return { DeliveryManDatas, loadingDeliveryMan };
};
