import { useNavigate } from "react-router-dom";
import {
  getAllStatusPrimaryApi,
  getCreateStatusApi,
  getStatusDetailsApi,
  getUpdateStatusApi
} from "../../../apis/app/order_status/getOrderStatusApis.js";
import { handleErrorApi } from "../../../helpers/handleErrorApi";

const useStatusFunctions = () => {
  const navigate = useNavigate();
  const getAllStatus = async (setStatus) => {
    try {
      const response = await getAllStatusPrimaryApi();
      setStatus(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  };
  const createStatus = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await getCreateStatusApi(data);
      navigate('/orders/status');
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getStatusDetails = async (id, setLoadingData, setData)=>{
    try {
      setLoadingData(true);
      const response = await getStatusDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoadingData(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoadingData(false);
    }
  }
  const updateStatus = async (id,temp_data,setLoading) =>{
    try {
      setLoading(true);
      const response = await getUpdateStatusApi(id,temp_data);
      navigate("/orders/status");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  return {
    getAllStatus,
    createStatus,
    getStatusDetails,
    updateStatus
  };
};
export default useStatusFunctions;
