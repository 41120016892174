import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useState } from "react";
import Table from "../../../components/table/Table";
import { useGetAllStatus } from "../../../hooks/app/orderStatus/useGetAllStatus";
import CreateOrderStatusDialog from "./CreateOrderStatusDialog";

const OrderStatus = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { StatusData, loadingStatus } = useGetAllStatus(page);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      name: "Type",
      selector: (row) => {
        return (
          <span
            className={
              row?.is_lead === true ? "badge bg-primary" : "badge bg-secondary"
            }
          >
            {row?.is_lead === true ? "Leads" : "Orders"}
          </span>
        );
      },
    },
    {
      name: "Is Primary",
      selector: (row) => {
        return (
          <span
            className={
              row?.is_primary === true
                ? "badge bg-primary"
                : "badge bg-secondary"
            }
          >
            {row?.is_primary === true ? "Primary" : "Secondary"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/orders/status/update/${row.id}`}
              title="update status"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete status">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Order Status"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Order Status"
      />
      <Table
        isLoading={loadingStatus}
        query={query}
        setQuery={setQuery}
        titleLabel="Status"
        columns={columns}
        data={search(StatusData?.data?.data, ["name"], query)}
        meta={StatusData?.data?.meta}
        setPage={setPage}
        btnFunction={true}
        btnData="#CreateStatus"
      />
      <CreateOrderStatusDialog />
    </>
  );
};
export default OrderStatus;
