
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import { ConfirmeAccountSchema } from "../../../validation/app/profile/ConfirmeAccountSchema";
import useConfirmeAccount from "../../../hooks/app/confirmeAccount/useConfirmeAccount";
import Loader from "../../../components/common/general/Loader";
import { Link } from "react-router-dom/dist";

const ConfirmeAccount = () => {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { ConfirmeAccount, ResendCode } = useConfirmeAccount();
  const [counter, setCounter] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ConfirmeAccountSchema),
    mode: "onBlur",
  });
  const onSubmitCode = (data) => {
    ConfirmeAccount(
      {
        ...data,
      },
      setLoading
    );
  };
  const resendCode = (e) => {
    e.preventDefault();
    if (counter === 0) {
      ResendCode(setLoadingResend, setCounter);
    }
  };
  useEffect(() => {
    if (counter === 0) {
      setCounter(0);
    }
    if (counter > 0) {
      const intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [counter]);

  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">Confirme Account</h6>
        <div className="alert alert-info" role="alert">
          Token was sended to this email: {user?.email}
          <hr />
          <p className="mb-0">
            <Link to="/profile" className="alert-link">
              Wrong Email! Change it now
            </Link>
          </p>
        </div>
        {counter !== 0 ? (
          <div className="alert alert-warning" role="alert">
            Please wait {counter} second until you can send token again
          </div>
        ) : null}
        <form className="forms-sample" onSubmit={handleSubmit(onSubmitCode)}>
          <div className="mb-3">
            <label for="token" className="form-label">
              Token
            </label>
            <input
              type="number"
              className="form-control"
              id="token"
              autocomplete="off"
              placeholder="Token"
              {...register("token")}
            />
            {errorMessage(errors, "token")}
          </div>
          {loading ? (
            <button type="submit" className="btn btn-primary me-2">
              <Loader />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary me-2">
              Confirme Account
            </button>
          )}
          {loadingResend ? (
            <button className="btn btn-secondary">
              <Loader />
            </button>
          ) : counter === 0 ? (
            <button className="btn btn-success" onClick={ (e) =>resendCode(e)}>
              Resend Code
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
};
export default ConfirmeAccount;
