import * as yup from "yup";
import { parse } from "date-fns";

export const CreateFactureSchema = yup.object().shape({
  supplier_id: yup
    .string()
    .typeError("Please choose Supplier")
    .required("Supplier is required"),
  image: yup.mixed().typeError("Please choose Supplier"),
});
