import { useQuery } from "@tanstack/react-query";
import { getSuppliersApi } from "../../../apis/app/suppliers/getSuppliersApi";
import { useNavigate } from "react-router-dom";

export const useSuppliers = (page) => {
  const navigate = useNavigate();
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["suplliers", page],
    queryFn: () => getSuppliersApi(page),
    onError: (err) => {
      if(err.response?.status === 401){
        if(err.response.data?.error === "no_store"){
          navigate('/create_store');
        }
        if(err.response.data?.error === "not_activated"){
          navigate('/confirme_account')
        }
      }
    },
  });
  return { Datas, loadingDatas };
};
