import { useQuery } from "@tanstack/react-query";
import { getProductsApi } from "../../../apis/app/products/getProductsApi";
import { useNavigate } from "react-router-dom";

export const useGetAllProducts = (page) => {
  const navigate = useNavigate();
  const { data: ProductsData, isLoading: loadingProducts } = useQuery({
    queryKey: ["products", page],
    queryFn: () => getProductsApi(page),
    onError: (err) => {
      if(err.response?.status === 401){
        if(err.response.data?.error === "no_store"){
          navigate('/create_store');
        }
        if(err.response.data?.error === "not_activated"){
          navigate('/confirme_account')
        }
      }
    },
  });
  return { ProductsData, loadingProducts };
};
