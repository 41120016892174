import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ label, route,icon }) => {
  return (
    <li className="nav-item">
      <NavLink
        to={route}
        className="nav-link"
        style={({ isActive }) => ({
          color: isActive && "#515acc",
        })}
      >
        <FeatherIcon icon={icon || "box"} className="link-icon" />
        <span className="link-title">{label}</span>
      </NavLink>
    </li>
  );
};

export default SidebarLink;
