import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { Button } from "react-bootstrap";

const ImageCard = ({ file, onCLick }) => {
  return (
    <div
      className="position-relative"
      style={{
        height: "100px",
        width: "100px",
        border: "1px solid white",
      }}
    >
      <img
        src={
          typeof file === "string"
            ? file
            : file?.path
            ? URL.createObjectURL(file)
            : null
        }
        className="h-100 w-100"
        alt=""
      />
      <Button
        onClick={() =>onCLick(file?.id)}
        variant="danger"
        className="position-absolute p-0"
        style={{
          top: "0.1rem",
          right: "0.21rem",
        }}
      >
        <FeatherIcon icon="x" className="" />
      </Button>
    </div>
  );
};

export default ImageCard;
