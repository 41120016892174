import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const WaitListSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  stores: yup
    .array()
    .min(1, "Pick at least one store")
    .typeError("Pick at least one store")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  delivery_company: yup
    .array()
    .min(1, "Pick at least one delivery company")
    .typeError("Pick at least one delivery company")
    .of(
      yup.object().shape({
        value: yup.string().required(),
      })
    ),
});
