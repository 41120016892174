import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateStoreSchema } from "../../../validation/app/store/CreateStoreSchema";
import StoreApis from "../../../hooks/app/store/store";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Breadcrumb from "../../../components/common/Breadcrumb";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";

const MyStore = () => {
  const { getThemes, createStore, getDays, getStore } = StoreApis();

  const [themes, setThemes] = useState([]);
  const [loadingThemes, setLodingThemes] = useState(false);

  const [loading, setLoading] = useState(false);

  const [days, setDays] = useState([]);
  const [loadingDays, setLodingDays] = useState(false);
  const [Data, setData] = useState({});

  useEffect(() => {
    getThemes(setLodingThemes, setThemes);
    // getDays(setLodingDays, setDays);
    getStore(setLoading, setData);
  }, []);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: Data,
  });
  const onSubmit = (data) => {
    
  };
  return (
    <>
      <Breadcrumb
        title="My Store"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="My Store"
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="forms-sample"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="Store Name"
                        name="name"
                        placeholder="Store name"
                        control={control}
                        defaultValue={Data?.name}
                        error="name"
                      />
                    </div>
                    <div className="col-lg-4">
                      <div>
                        <label for="theme_id" className="form-label">
                          Themes
                        </label>
                        <select
                          name="theme_id"
                          id="theme_id"
                          className="form-control mt-1"
                          {...register("theme_id")}
                        >
                          {/* <option value="">Selectionner</option> */}
                          <option value={Data.Theme?.id} selected>
                            {Data.Theme?.name}
                          </option>
                          {themes?.map((item, index) => {
                            return (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <SelectComponent
                        data={[
                          {
                            value: 1,
                            label: "Active",
                          },
                          {
                            value: 0,
                            label: "Inactive",
                          },
                        ]}
                        defaultData={Data?.status}
                        control={control}
                        name="status"
                        closeMenuOnSelect
                        label="Status"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        control={control}
                        defaultValue={Data?.email}
                        error="email"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="phone"
                        name="phone"
                        placeholder="phone"
                        control={control}
                        defaultValue={Data?.phone}
                        error="phone"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="url"
                        name="url"
                        placeholder="url"
                        control={control}
                        defaultValue={Data?.url}
                        error="url"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="facebook"
                        name="facebook"
                        placeholder="facebook"
                        control={control}
                        defaultValue={Data?.facebook}
                        error="facebook"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="instagram"
                        name="instagram"
                        placeholder="instagram"
                        control={control}
                        defaultValue={Data?.instagram}
                        error="instagram"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="youtube"
                        name="youtube"
                        placeholder="youtube"
                        control={control}
                        defaultValue={Data?.youtube}
                        error="youtube"
                      />
                    </div>
                  </div>
                  {loading ? (
                    <button type="submit" className="btn btn-primary me-2 mt-2">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success me-2 mt-2">
                      Update Store
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MyStore;
