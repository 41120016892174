import { useQuery } from "@tanstack/react-query";
import { getAllGoogleSheetsApi } from "../../../apis/app/google_sheets/getAllGoogleSheetsApis";

export const useGetGoogleSheets = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["google_sheets", page],
    queryFn: () => getAllGoogleSheetsApi(page),
  });
  return { Datas, loadingDatas };
};
