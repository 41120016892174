import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../hooks/useSearch";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useAgents } from "../../../hooks/app/agents/useAgents";
import Table from "../../../components/table/Table";

const Agents = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loading } = useAgents(page);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Fullname",
      selector: (row) => row.first_name+" "+row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Leads",
      selector: (row) => row.leads_count,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              title="View Delivery Man"
              className="me-3"
              to={`/delivery/man/view/${row.id}`}
            >
              <FeatherIcon icon="eye" color="green" size={20} />
            </Link>
            <Link
              to={`/delivery/man/update/${row.id}`}
              title="update delivery man"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete role">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Agents"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Agents"
      />
      <Table
        isLoading={loading}
        query={query}
        setQuery={setQuery}
        titleLabel="Agent"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default Agents;
