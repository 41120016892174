import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../../../helpers/errorMessage";
import Loader from "../../../../../components/common/general/Loader";
import { useState } from "react";
import { CreateNewDeliveryCompanySchema } from "../../../../../validation/app/deliveryCompany/CreateNewDeliveryCompanySchema";
import DeliveryCompany from "../../../../../hooks/app/DeliveryCompany/DeliveryCompany";

const NewDeliveryCompany = () => {
  const [loading, setLoading] = useState(false);
  const { CreateNewDeliveryCompany } = DeliveryCompany();
 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateNewDeliveryCompanySchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateNewDeliveryCompany(
      data,
      setLoading
    );
  };
  return (
    <>
      <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                autoComplete="off"
                placeholder="Delivery Company name"
                {...register("name")}
              />
              {errorMessage(errors, "name")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="status" className="form-label">
                Status
              </label>
              <select
                name="status"
                id="status"
                className="form-control"
                {...register("status")}
              >
                <option value="">Selectionner</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
              {errorMessage(errors, "status")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="default" className="form-label">
                Default
              </label>
              <select
                name="default"
                id="default"
                className="form-control"
                {...register("default")}
              >
                <option value="">Selectionner</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
              {errorMessage(errors, "default")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                autoComplete="off"
                placeholder="Delivery Company Phone"
                {...register("phone")}
              />
              {errorMessage(errors, "phone")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                autoComplete="off"
                placeholder="Delivery Company Email"
                {...register("email")}
              />
              {errorMessage(errors, "email")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="website" className="form-label">
                Website
              </label>
              <input
                type="text"
                className="form-control"
                id="website"
                autoComplete="off"
                placeholder="Delivery Company Website"
                {...register("website")}
              />
              {errorMessage(errors, "website")}
            </div>
          </div>
        </div>
        {loading ? (
          <button type="submit" className="btn btn-primary me-2">
            <Loader />
          </button>
        ) : (
          <button type="submit" className="btn btn-success me-2">
            Create
          </button>
        )}
      </form>
    </>
  );
};
export default NewDeliveryCompany;
