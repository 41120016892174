import { useNavigate } from "react-router-dom";
import {
  getWilayasApi,
  getWilayaDairasApi,
  getCreateDeliveryManApi,
  getDeliveryManApi,
  getUpdateDeliveryMan,
  getAddDeliveryAddressApi,
  getDeliveryManCreateTarifApi,
  getDeliveryManUpdateTarifApi,
  getDeliveryManProductsStockApi,
  getDeliveryManAddProductsStockApi,
  getDeliveryManStockViewApi,
  getDairasApi
} from "../../../apis/app/delivery_mans/useDeliveryManApis";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { CreateDeliveryManSetErrors } from "../../../helpers/HandleSetErrors/CreateDeliveryManSetErrors";
import Toast from "../../../helpers/toast.js";

const useDeliveryManFunctions = () => {
  const navigate = useNavigate();
  const getWilayas = async (setWilayas, setLoading) => {
    try {
      setLoading(true);
      const response = await getWilayasApi();
      setWilayas(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getWilayaDairas = async (id, setDairas, setLoading) => {
    try {
      setLoading(true);
      const response = await getWilayaDairasApi(id);
      setDairas(response?.data?.data?.daira);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const createDeliveryMan = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await getCreateDeliveryManApi(data);
      navigate("/delivery/man");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        CreateDeliveryManSetErrors(data, setError);
      }
    } finally {
      setLoading(false);
    }
  };
  const UpdateDeliveryMan = async (data, setLoading, id, setError) => {
    try {
      setLoading(true);
      const response = await getUpdateDeliveryMan(data, id);
      Toast("success", "You have successfully update address");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        CreateDeliveryManSetErrors(data, setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const getDeliveryMan = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getDeliveryManApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const AddDeliveryAddress = async (id, data, setLoading) => {
    try {
      setLoading(true);
      const response = await getAddDeliveryAddressApi(id, data);
      Toast("success", "You have successfully create address");
      navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const AddTarif = async (id, data, setLoading,setData) => {
    try {
      setLoading(true);
      const response = await getDeliveryManCreateTarifApi(id, data);
      setData(response?.data?.data);
      Toast("success", "You have successfully create tarif");
      navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const UpdateTarif = async (id, tarif_id, data, setLoading) => {
    try {
      setLoading(true);
      const response = await getDeliveryManUpdateTarifApi(id, tarif_id, data);
      Toast("success", "You have successfully create tarif");
      navigate(0);

    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getProductsStock = async (id, setLoading, SetData) => {
    try {
      setLoading(true);
      const response = await getDeliveryManProductsStockApi(id);
      SetData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const addProductStock = async (setLoading, id, data) => {
    try {
      setLoading(true);
      const response = await getDeliveryManAddProductsStockApi(id, data);
      navigate(`/delivery/man/${id}/stock`);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getDeliveryManStock = async (id,stock_id, setLoading, setData) =>{
    try {
      setLoading(true);
      const response = await getDeliveryManStockViewApi(id, stock_id);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getDairas = async (man_id,id, setDairas, setLoading) => {
    try {
      setLoading(true);
      const response = await getDairasApi(man_id,id);
      setDairas(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  return {
    getWilayas,
    getWilayaDairas,
    createDeliveryMan,
    UpdateDeliveryMan,
    getDeliveryMan,
    AddDeliveryAddress,
    AddTarif,
    UpdateTarif,
    getProductsStock,
    addProductStock,
    getDeliveryManStock,
    getDairas
  };
};
export default useDeliveryManFunctions;
