import { useNavigate } from "react-router-dom";
import {
  getCreateSourceApi,
  getSourceDetailsApi,
  getSourceUpdateApi
} from "../../../apis/app/order_source/getOrderSourceApis";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast";

const useSourceFunctions = () => {
  const navigate = useNavigate();
  const CreateSource = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await getCreateSourceApi(data);
      Toast("success", "Source was created successfully");
      navigate("/orders/sources");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getSourceDetails = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getSourceDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getUpdateSource = async (id, data, setLoading) => {
    try {
      setLoading(true);
      const response = await getSourceUpdateApi(id,data);
      Toast("success", "Source was updated successfully");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    CreateSource,
    getSourceDetails,
    getUpdateSource,
  };
};
export default useSourceFunctions;
