import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import useFactureFunctions from "../../../hooks/app/Factures/useFactureFunctions";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CreateFactureAddProductDialog from "./CreateFactureAddProductDialog";
import FactureProductsTable from "./Components/FactureProductsTable";
import Loader from "../../../components/common/general/Loader";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import { CreateFactureSchema } from "../../../validation/app/facture/CreateFactureSchema";

const CreateFacture = () => {
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [loadingSuppliers, setLoadingSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsDB, setProductsDB] = useState([]);

  const { getSuppliers, getStoreProducts,CreateFactureFunction } = useFactureFunctions();
  useEffect(() => {
    getSuppliers(setLoadingSuppliers, setSuppliers);
    getStoreProducts(setProductsDB);
  }, []);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(CreateFactureSchema),
  });
  const photo = watch("image");

  const DeleteProduct = (index) => {
    setProducts((products) => {
      return products.filter((_, i) => i !== index);
    });
  };
  const onSubmit = (data) => {
    data['products'] = products;
    CreateFactureFunction(
      data,
      setLoading,
      setError
    );
  };

  return (
    <>
      <Breadcrumb
        title="Factures"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Create Facture"
        secondaryLink="/factures"
        secondarytitle="Factures"
      />
      <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Main Informations</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="supplier_id" className="form-label">
                      Suppliers
                    </label>
                    <select
                      id="supplier_id"
                      name="supplier_id"
                      className="form-control"
                      {...register("supplier_id")}
                    >
                      <option value="">
                        {loadingSuppliers === true ?"Loading...":"Select"}
                      </option>
                      {suppliers?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.first_name + " " + item.last_name}
                          </option>
                        );
                      })}
                    </select>
                    {errorMessage(errors, "supplier_id")}
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="note" className="form-label">
                        Note
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="note"
                        placeholder="Note"
                        {...register("note")}
                      />
                      {errorMessage(errors, "note")}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mt-1">
                      <ImageDropzone
                        placeholder="Click to select images"
                        photos={photo}
                        name="image"
                        setValue={setValue}
                        multiple={true}
                        previewCol={5}
                        errors={errors}
                      />
                    </div>
                  </div>
                  {loading ? (
                    <button type="submit" className="btn btn-primary me-2">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success me-2">
                      Create Facture
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="g-3 row">
                  <div className="col-sm-6">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <h6 className="card-title">Products</h6>
                    </div>
                  </div>
                  <div className="col-sm-auto ms-auto">
                    <div>
                      <Link
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#FactureAddProduct"
                      >
                        <FeatherIcon
                          icon="plus"
                          className="ri-add-line align-bottom me-1"
                        />
                        Add Product
                      </Link>
                    </div>
                  </div>
                </div>
                <FactureProductsTable
                  products={products}
                  DeleteProduct={DeleteProduct}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <CreateFactureAddProductDialog
        setProducts={setProducts}
        productsDB={productsDB}
        products={products}
      />
    </>
  );
};
export default CreateFacture;
