import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useParams } from "react-router-dom";
import useStatusFunctions from "../../../hooks/app/orderStatus/useStatusFunctions";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import Toast from "../../../helpers/toast";
 
const UpdateOrderStatus = () => {
  const { id } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState(false);

  const [loading, setLoading] = useState(false);

  const { getStatusDetails,updateStatus } = useStatusFunctions();
  useEffect(() => {
    getStatusDetails(id, setLoadingData, setData);
  }, [id]);
  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (temp_data) => {
    if (data.is_primary == true) {
      if (temp_data.status === undefined) {
        Toast("error", "Nothing to Updated");
      } else {
        updateStatus(id,temp_data,setLoading);
      }
    } else {
      if (temp_data.status === undefined && temp_data.name === undefined) {
        Toast("error", "Nothing to Updated");
      } else {
        updateStatus(id,temp_data,setLoading);
      }
    }
  };
  return (
    <>
      <Breadcrumb
        title="Status"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update Order Status"
        secondaryLink="/orders/status"
        secondarytitle="Status"
      />
      {loadingData ? (
        <Loader />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {data?.is_primary != true ? (
                    <div className="col-lg-6">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="Status Name"
                        name="name"
                        placeholder="Status name"
                        control={control}
                        defaultValue={data?.name}
                        error="name"
                      />
                    </div>
                  ) : null}
                  <div className="col-lg-6">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.status}
                      control={control}
                      name="status"
                      closeMenuOnSelect
                      label="Status"
                    />
                  </div>
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary mt-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mt-2">
                    Update Status
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateOrderStatus;
