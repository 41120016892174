import axiosInstance from "../../Axios/axios";

const getAllStatusPrimaryApi = async () => {
  return await axiosInstance.get(`store/orders/status/get_status`);
};

const getAllStatusApi = async (page) => {
  return await axiosInstance.get(`store/orders/status?page=${page}`);
};

const getCreateStatusApi = async (data) => {
  return await axiosInstance.post(`store/orders/status`, data);
};

const getStatusDetailsApi = async (id) => {
  return await axiosInstance.get(`store/orders/status/${id}`);
};

const getUpdateStatusApi = async (id,data) => {
  return await axiosInstance.post(`store/orders/status/update/${id}`,data);
};


export { getAllStatusPrimaryApi, getAllStatusApi, getCreateStatusApi,getStatusDetailsApi,getUpdateStatusApi };
