import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import { useGetAllOrderLeads } from "../../../hooks/app/orderLeads/useGetAllOrderLeads";
import useAllFunctionOrderLeads from "../../../hooks/app/orderLeads/useAllFunctionOrderLeads";
import LeadStatistics from "./LeadStatistics";

const Leads = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useGetAllOrderLeads(page);
  const [loadingStatistics,setLoadingStatistics] = useState(false);
  const [Status,setStatus] = useState([]);
  const { getLeadStatistics } = useAllFunctionOrderLeads();
  useEffect(() => {
    getLeadStatistics(setStatus,setLoadingStatistics);
  }, []);
  const columns = [
    {
      name: "Full name",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row.first_name + " " + row.last_name}
          >
            {row.first_name + " " + row.last_name}
          </div>
        );
      },
      sortable: false,
    },
    {
      name: "Phone",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row.phone}
          >
            {row.phone}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Wilaya / Daira",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row.wilaya + " / " + row.daira}
          >
            {row.wilaya + " / " + row.daira}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Agent",
      selector:(row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row.agent?.first_name + " " + row.agent?.last_name}
          >
            {row.agent?.first_name + " " + row.agent?.last_name}
          </div>
        )
      },
    },
    {
      name: "Delivery Type",
      selector: (row) => row.delivery_type,
    },
    {
      name: "Current status",
      selector: (row) => {
        switch (row?.current_status?.status?.name) {
          case "En Attente":
            return (
              <span className="badge bg-light text-dark">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Echec 01":
            return (
              <span className="badge bg-warning">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Echec 02":
            return (
              <span className="badge bg-warning">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Echec 03":
            return (
              <span className="badge bg-warning">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Annulée":
            return (
              <span className="badge bg-danger">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Reporté":
            return (
              <span className="badge bg-primary">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Dubliqué":
            return (
              <span className="badge bg-secondary">
                {row?.current_status?.status?.name}
              </span>
            );
          case "Confirmée":
            return (
              <span className="badge bg-success">
                {row?.current_status?.status?.name}
              </span>
            );
        }
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
      sortable: true,

    },
    {
      name: "Created at",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/leads/view/${row.id}`}
              title="View Lead"
              className="me-3"
            >
              <FeatherIcon icon="eye" size={20} color="red" />
            </Link>
            <Link
              to={`/leads/confirme/${row.id}`}
              title="View Lead"
              className="me-3"
            >
              <FeatherIcon icon="check-circle" size={20} color="green" />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Leads"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Leads"
      />
      <LeadStatistics status={Status} loading={loadingStatistics} />
      <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        titleLabel={null}
        columns={columns}
        data={search(Datas?.data?.data, ["first_name","last_name","phone","address"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default Leads;
