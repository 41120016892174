import axiosInstance from "../../../Axios/axios";

const DeliveryCompanyApi = async (page) => {
    return await axiosInstance.get(`store/delivery_company?page=${page}`);
};

const getDelivery = async () => {
    return await axiosInstance.get(`store/delivery_company/check_delivery`);
};
const getOneDeliveryCompany = async (id) => {
    return await axiosInstance.get(`store/delivery_company/${id}`);
};

const createNewCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/store`,data);
};

const createYalidineCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/yalidine/store`,data);
};

const createNoestCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/noest/store`,data);
};

const createMaystroCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/maystro/store`,data);
};

const createZrExpressCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/zrexpress/store`,data);
};

const createEcoTrackCompany = async (data) => {
    return await axiosInstance.post(`store/delivery_company/ecotrack/store`,data);
};
const updateDeliveryCompany = async (id,data) => {
    return await axiosInstance.post(`store/delivery_company/update/${id}`,data);
}
export {
    DeliveryCompanyApi,
    getDelivery,
    createNewCompany,
    createYalidineCompany,
    createNoestCompany,
    createMaystroCompany,
    createZrExpressCompany,
    createEcoTrackCompany,
    getOneDeliveryCompany,
    updateDeliveryCompany
}
