import { useQuery } from "@tanstack/react-query";
import { getGoogleAccountApi } from "../../../apis/app/google_sheets/getAllGoogleSheetsApis";

export const useGetGoogleAccount = (page) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["google_account", page],
    queryFn: () => getGoogleAccountApi(page),
  });
  return { Datas, loadingDatas };
};
