import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../hooks/useSearch";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useGetAllCustomers } from "../../../hooks/app/customers/useGetAllCustomers";
import Table from "../../../components/table/Table";

const Customers = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useGetAllCustomers(page);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "First name",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.first_name}
          >
            {row?.first_name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.last_name}
          >
            {row?.last_name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.email}
          >
            {row?.email}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.phone}
          >
            {row?.phone}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address?.length,
      sortable: true,
    },
    {
      name: "Orders",
      selector: (row) => row.orders,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={row?.is_primary !== 1 && `/customers/view/${row.id}`}
              title="update customers"
              className="me-3"
            >
              <FeatherIcon icon="eye" size={20} />
            </Link>
            <Link title="delete customers">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Customers"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Customers"
      />
      <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        titleLabel={null}
        columns={columns}
        data={search(
          Datas?.data?.data,
          ["first_name", "last_name", "phone", "phone_secondary", "email"],
          query
        )}
        meta={Datas?.data?.meta}
        setPage={setPage}
      />
    </>
  );
};
export default Customers;
