import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const CreateStoreSchema = yup.object().shape({
  name: yup.string().required("Store name is required"),
  theme_id: yup.string().required("Theme is required"),
  url: yup.string().required("Url is required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  email: yup.string().email().required("Email is required"),
  facebook: yup.string(),
  instagram: yup.string(),
  youtube: yup.string(),
  // days: yup.array().of(
  //   yup.object().shape({
  //     from: yup
  //       .string()
  //       .matches(/(\d){2}:(\d){2}/, 'Hour must have this pattern "00:00"'),
  //     to: yup
  //       .string()
  //       .matches(/(\d){2}:(\d){2}/, 'Hour must have this pattern "00:00"'),
  //   })
  // ),
  image: yup.mixed().required("Image is required"),
});
